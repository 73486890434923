import React, { useState, useEffect, memo } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Typography, Box, Checkbox, IconButton, Link, Stack, SvgIcon, TableCell, TableRow, Tooltip, Button } from "@mui/material";
import ReportIcon from "@mui/icons-material/Report";
import { RouterLink } from "src/components/router-link";
import { SeverityPill } from "src/components/severity-pill";
import { paths } from "src/paths";
import { userBlocked } from "../../../redux/actions/User";
import { userRestored } from "../../../redux/actions/User";
import { useDispatch } from "react-redux";
import UserAvatar from "src/components/avatar/UserAvatar";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Edit02Icon from "@untitled-ui/icons-react/build/esm/Edit02";
import formatDate from "src/utils/format-date";
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { useNavigate } from "react-router-dom";

const RedesignUserTableRow = memo(({ user, isSelected = false, onUserRemove}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isUserBlocked, setIsUserBlocked] = useState(user?.isActive);
  const navigate = useNavigate()

  useEffect(() => {
    setIsUserBlocked(user?.isActive);
  }, [user]);

  const onBlockedUser = async (id) => {
    try {
      await dispatch(userBlocked(id, !user?.isActive));
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) toast.error(t("views.redesignUserTableRow.blockError", { message: error.response.data.message }));
    }
  };

  const onRestoredUser = async (id) => {
    try {
      await dispatch(userRestored(id));
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message) toast.error(t("views.redesignUserTableRow.restoredError", { message: error.response.data.message }));
    }
  }

  return (
    <TableRow hover data-user={user._id}>
      <TableCell padding="checkbox">
        <Checkbox checked={isSelected} data-user-checkbox />
      </TableCell>
      <TableCell>
        <Stack alignItems="center" direction="row" spacing={1}>
          <UserAvatar
            user={user}
            sx={{
              height: 42,
              width: 42,
            }}
          />
          <div>
            <Link color="inherit" component={RouterLink} href={paths.users.details.replace(":id", user?._id)} variant="subtitle2">
              {user?.fullname}
            </Link>
            <Typography color="text.secondary" variant="body2">
              {user?.email}
            </Typography>
          </div>
        </Stack>
      </TableCell>
      <TableCell>{user?.platform.name}</TableCell>
      <TableCell>
        <span style={{ cursor: "pointer" }}>
          <span style={{ color: "#296fff" }} title={t("views.redesignUserTableRow.totalScreens")}>
            {user?.screenCount}
          </span>
        </span>
      </TableCell>

      <TableCell>{formatDate(new Date(user?.createdAt))}</TableCell>
      <TableCell>{user?.country ? user?.country : t("views.redesignUserTableRow.notAvailable")}</TableCell>
      <TableCell>{user.groups && user.groups.length > 0 ? user.groups.map((group) => group.name).join(", ") : "n/a"}</TableCell>
      <TableCell>{user?.role}</TableCell>
      <TableCell>
        <Button onClick={() => {
          if(user?.plan.name !== 'Administrator'){
            navigate(`/user-subscription/${user?._id}`)
          }
        }} color={user?.plan.name == 'Premium' ? 'secondary' : user?.plan.name == 'Basic' ? 'primary' : user?.plan.name == 'Free' ? 'success' : 'info'}>{user?.plan.name}</Button>
      </TableCell>
      <TableCell>
        <SeverityPill color={user?.isActive ? (user?.isVerified ? "success" : "warning") : "error"}>
          {user?.isActive ? t("views.redesignUserTableRow.active") : user?.isDeleted ? t("views.redesignUserTableRow.deleted") : t("views.redesignUserTableRow.blocked")}
        </SeverityPill>
        {user?.isActive && !user?.isVerified && (
          <Tooltip title={t("views.redesignUserTableRow.unverified")}>
            <ReportIcon color="error" sx={{ verticalAlign: "middle", marginLeft: "0.5rem" }} />
          </Tooltip>
        )}
      </TableCell>
      <TableCell align="right">
        {!user?.isDeleted ? (
          <Tooltip title={t('views.redesignUserTableRow.edit')} placement="top">
            <IconButton component={user?.isDeleted ? "div" : RouterLink} href={paths.users.details.replace(":id", user?._id)}>
              <SvgIcon>
                <Edit02Icon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        ) : null}
        {user?.isDeleted && !user?.isActive ? (
          <Tooltip title={t("views.redesignUserTableRow.restore")} placement="top">
            <IconButton onClick={() => onRestoredUser(user._id)}>
              <SvgIcon>
                <SettingsBackupRestoreIcon />
              </SvgIcon>
            </IconButton>
          </Tooltip>
        ) : null}
        <Tooltip title={t("views.redesignUserTableRow.lockUnblockUser")} placement="top">
          <IconButton onClick={() => onBlockedUser(user._id)}>
            <SvgIcon>{isUserBlocked ? <LockOpenIcon /> : <LockIcon />}</SvgIcon>
          </IconButton>
        </Tooltip>
        <Tooltip title={t("views.redesignUserTableRow.removeUserCompletely")} placement="top">
          <IconButton onClick={() => onUserRemove(user._id)}>
            <SvgIcon>
              <DeleteForeverIcon/>
            </SvgIcon>
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
});

export default RedesignUserTableRow;
