import { Box, Container, Stack, Card, Grid, SvgIcon, TextField, Button, Typography, FormControl, InputLabel, FormGroup, FormControlLabel, Checkbox, IconButton, Link } from "@mui/material";
import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Layout from "../../components/layout/Layout";
import Edit02Icon from "@untitled-ui/icons-react/build/esm/Edit02";
import { emailTempSave, getEmailTemp, editEmailTemp, emailFlagOff } from "../../redux/actions/EmailAction";
import { useSettings } from "src/hooks/use-settings";
import { Autocomplete } from "@react-google-maps/api";
import { FormContainer, TextFieldElement, useForm, useFormContext } from "react-hook-form-mui";
import UserAddressInput from "../userdetails/components/UserAddressInput";
import LockOutlineIcon from '@mui/icons-material/LockOutlined';
import PersonIcon from '@mui/icons-material/Person';
import { api } from "src/api/endpoints";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ArrowLeftIcon from "@untitled-ui/icons-react/build/esm/ArrowLeft";
import { RouterLink } from "src/components/router-link";
import { paths } from "src/paths";

const parsePlaceObject = (placeObject) => {
    const country = placeObject.address_components.find((c) => c.types.includes("country"))?.long_name ?? "";
  
    const street = placeObject.address_components.find((c) => c.types.includes("route"))?.long_name ?? "";
    const street_number = placeObject.address_components.find((c) => c.types.includes("street_number"))?.long_name ?? "";
    const postal_code = placeObject.address_components.find((c) => c.types.includes("postal_code"))?.long_name ?? "";
    const city = placeObject.address_components.find((c) => c.types.includes("locality"))?.long_name ?? "";
  
    const state = placeObject.address_components.find((c) => c.types.includes("administrative_area_level_1"))?.long_name ?? "";
  
    const address1 = street_number && street ? `${street} ${street_number}` : "";
    const address2 = postal_code && city ? `${postal_code} ${city}` : "";
  
    const formattedAddress = placeObject.formatted_address;
  
    return {
      country,
      address1,
      address2,
      state,
      formattedAddress,
    };
};

function CreateUser({ user }) {
  const { t } = useTranslation();
  const settings = useSettings();
  const locationRef = useRef(null);
  const navigate = useNavigate()

  const [location, setLocation] = useState("");
  const [locationDetails, setLocationDetails] = useState(null)

  const [showPass, setShowPass] = useState(false)
    
  const onGetLatLonByLocatioChange = () => {
    const placeObject = locationRef.current.getPlace();
    // console.log(placeObject)
    const lat = placeObject.geometry.location.lat();
    const lng = placeObject.geometry.location.lng();
    setLocation(placeObject?.name);
    setLocationDetails(parsePlaceObject(placeObject))
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm()

  

  const handleCreateUser = async (data) => {
    if(data.authEmail && data.authPass && data.personalName){
        try {
            const reqOb = {
                fullName: data.personalName,
                email: data.authEmail,
                pass: data.authPass,
                setAsAdmin: data.setAsAdmin,
                sendMail: data.sendMail
            }
            if(locationDetails){
                if(locationDetails.country){
                    reqOb.country = locationDetails.country
                }
                if(locationDetails.state){
                    reqOb.state = locationDetails.state
                }
                if(locationDetails.address2){
                    reqOb.address2 = locationDetails.address2
                }
                if(locationDetails.formattedAddress){
                    reqOb.formattedAddress = locationDetails.formattedAddress
                }
            }
            if(data.personalPhone){
                reqOb.phone = data.personalPhone
            }
            await api.post('/user/createUser', reqOb)
            window.location.replace('/users')
        }catch (error) {
            toast.error(error.response.data.message)
        }
    }else{
        toast.error('Complete all required fields')
    }
  }

  return (
    <>
      <Layout title={t("views.redesignEmail.title")} user={user}>
      <Link
                    color="text.primary"
                    component={RouterLink}
                    to={paths.users.index}
                    sx={{
                        alignItems: "center",
                        display: "inline-flex",
                        p: 2
                    }}
                    underline="hover">
                    <SvgIcon sx={{ mr: 1 }}>
                        <ArrowLeftIcon />
                    </SvgIcon>
                    <Typography variant="subtitle2">{t("views.userDetails.users")}</Typography>
        </Link>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 2,
            maxWidth: '1500px',
            mx: 'auto'
          }}>
          <Container maxWidth={settings.stretch ? false : "xl"}>
            <form onSubmit={handleSubmit(handleCreateUser)}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Card variant="outlined" sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                gap: '10px'
                            }}>
                                <Typography variant="subtitle1">Auth data</Typography>
                                <LockOutlineIcon />
                            </Box>
                            <Box autoComplete="off" noValidate sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px'
                            }}>
                                <TextField 
                                    required
                                    id="authEmail"
                                    label="Email address"
                                    variant="outlined"
                                    fullWidth
                                    type="email"
                                    error={errors.authEmail ? true : false}
                                    helperText={errors.authEmail ? 'Email is required' : ''}
                                    {...register("authEmail", {required: true})}
                                />
                                <Box sx={{
                                    display: 'flex',
                                    gap: '10px'
                                }}>
                                    <TextField 
                                        required
                                        id="authPass"
                                        label="Password"
                                        variant="outlined"
                                        type={showPass ? 'text' : 'password'}
                                        fullWidth
                                        error={errors.authPass ? true : false}
                                        helperText={errors.authPass ? 'Password is required' : ''}
                                        {...register("authPass", {required: true})}
                                    />
                                    <IconButton onClick={() => setShowPass(!showPass)}>
                                        {showPass ? (
                                            <VisibilityIcon />
                                        ) : (
                                            <VisibilityOffIcon />
                                        )}
                                    </IconButton>
                                </Box>
                            </Box>
                        </Card>
                    </Grid>
                    <Grid item xs={6}>
                        <Card variant="outlined" sx={{
                            p: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '20px'
                        }}>
                            <Box sx={{
                                display: 'flex',
                                gap: '10px'
                            }}>
                                <Typography variant="subtitle1">Personal data</Typography>
                                <PersonIcon />
                            </Box> 
                            <TextField 
                                required
                                id="personalName"
                                label="Full name"
                                variant="outlined"
                                fullWidth
                                {...register("personalName")}
                            />
                            <FormGroup>
                                <FormControlLabel control={<Checkbox/>} label="Set user as admin" {...register("setAsAdmin")}/>
                            </FormGroup>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox/>} label="Send verification email" {...register("sendMail")}/>
                            </FormGroup>
                            <Box sx={{width: '100%', marginTop: '10px'}}>
                                <Autocomplete onLoad={(autocomplete) => (locationRef.current = autocomplete)} onPlaceChanged={onGetLatLonByLocatioChange}>
                                <TextField
                                    type={"text"}
                                    label={t("views.redesignScreens.location")}
                                    variant={"outlined"}
                                    value={location}
                                    onChange={(e) => setLocation(e.target.value)}
                                    fullWidth
                                />
                                </Autocomplete>
                                <Grid container spacing={2} mt={1}>
                                    <Grid item xs={6}>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '10px'
                                        }}>
                                            <TextField disabled value={locationDetails ? locationDetails.country : ''} variant="outlined" fullWidth placeholder="Country" {...register("personalCountry")}/>
                                            <TextField disabled value={locationDetails ? locationDetails.address1 : ''} variant="outlined" fullWidth placeholder="Address" {...register("personalAddress")}/>
                                            <TextField 
                                                id="personalPhone"
                                                label="Phone"
                                                variant="outlined"
                                                placeholder="+00123123123"
                                                fullWidth
                                                {...register("personalPhone")}
                                            />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Box sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            gap: '10px'
                                        }}>
                                            <TextField disabled value={locationDetails ? locationDetails.state : ''} variant="outlined" fullWidth placeholder="State" {...register("personalState")}/>
                                            <TextField disabled value={locationDetails ? locationDetails.address2 : ''} variant="outlined" fullWidth placeholder="City" {...register("personalCity")}/>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box>
                                <Button variant="contained" sx={{
                                    mt: 2
                                }} type="submit">Create user</Button>
                            </Box>
                        </Card>
                    </Grid>
                </Grid>
            </form>
          </Container>
        </Box>
      </Layout>
    </>
  );
}

export default CreateUser;
