import {
  ADD_CONTENT,
  EDIT_CONTENT,
  CHECKED_CONTENT,
  CONTENT_ASC_ORDER_BY_CREATEDAT,
  CONTENT_ASC_ORDER_BY_NAME,
  CONTENT_DELETE_REQUEST,
  CONTENT_DELETE_SUCCESS,
  CONTENT_DESC_ORDER_BY_CREATEDAT,
  CONTENT_DESC_ORDER_BY_NAME,
  CONTENT_FLAG_OFF,
  CONTENT_MOVE_TO_FOLDER,
  GET_CONTENT_SUCCESS,
  UNCHECKED_CONTENT,
  CHECKED_SINGLE_CONTENT,
  UNCHECKED_SINGLE_CONTENT,
  DELETE_BULK_CONTENT,
  RESTORE_FILES_REQUEST,
  RESTORE_FILES_SUCCESS,
  ADD_CONTENT_REQUEST,
  ADD_CONTENT_FAILED,
  GET_CONTENT_FROM_GOOGLE_DRIVE,
  PICKING_DATA_FROM_GOOGLE,
  UPLOAD_FAILED,
  CONTENT_MOVE_SUCCESS,
  CONTENT_MOVE_REQUEST,
  CONTENT_MOVE_FAILED,
  ADD_CONTENT_DUPLICATE_ERROR,
} from "../constant";
import toast from "react-hot-toast";

const initial = {
  content: [],
  isDeleted: false,
  isDeleting: false,
  isContentAddedInContent: false,
  isContentAdding: false,
  isFileRestored: false,
  pickedDataFromGoogle: false,
  contentDuplicateError: false
};
export const ContentReducer = (state = initial, action) => {
  switch (action.type) {
    case ADD_CONTENT_DUPLICATE_ERROR:
      return {
        ...state,
        contentDuplicateError: action.payload,
        isContentAdding: false
      }
    case CONTENT_FLAG_OFF:
      return {
        ...state,
        isDeleted: false,
        isDeleting: false,
        isContentAddedInContent: false,
        isFileRestored: false,
        isContentAdding: false,
        pickedDataFromGoogle: false,
      };
    case ADD_CONTENT_REQUEST:
      return {
        ...state,
        isContentAdding: true,
      };

      case EDIT_CONTENT: {
        const new_payload = action.payload.content ? action.payload.content : action.payload;
        console.log("reducer edit content", new_payload)
        console.log("reducer edit content item",  state.content);
        const isItemInList = state.content.some(item => item.id === new_payload.id);
        if (isItemInList) {
            console.log(`Item with id ${new_payload.id} found and updated.`);
        }
        else{
            console.log(`Item with id ${new_payload.id} not found and added.`);
        }
    
        return {
            ...state,
            isContentAdding: false,
            isContentAddedInContent: true,
            content: isItemInList
                ? state.content.map(item =>
                    item.id === new_payload.id ? new_payload : item
                  )
                : [new_payload, ...state.content],
        };
    }
    

    case ADD_CONTENT:
      console.log("Reducer: ADD_CONTENT");
      const newContent = Array.isArray(action.payload) 
        ? action.payload 
        : [action.payload]; 
    
      return {
        ...state,
        isContentAdding: false,
        isContentAddedInContent: true,
        content: [...newContent, ...state.content].reduce((content, item) => {
          if (!content.some(c => c.id === item.id)) {
            content.push(item); 
          }
          return content;
        }, []), 
      };
    case ADD_CONTENT_FAILED:
      return {
        ...state,
        isContentAdding: false,
      };
    case GET_CONTENT_SUCCESS:
      return {
        ...state,
        content: [...action.payload].filter((c) => c.isActive === true).reverse(),
      };
    case CONTENT_DELETE_REQUEST:
      return {
        ...state,
        isDeleted: false,
        isDeleting: true,
      };
    case CONTENT_DELETE_SUCCESS:
      const __fileIndex = state.content.findIndex((c) => c.id === action.payload);
      state.content[__fileIndex] = { ...state.content[__fileIndex], isActive: false };
      return {
        ...state,
        content: state.content.filter((c) => c.isActive == true),
        isDeleted: true,
        isDeleting: false,
      };
    case CONTENT_MOVE_TO_FOLDER:
      const __content_move_index = state.content.findIndex((c) => c.id === action.payload.id);
      state.content[__content_move_index] = { ...action.payload };
      return {
        ...state,
        content: state.content,
      };

    case CONTENT_DESC_ORDER_BY_CREATEDAT:
      return {
        ...state,
        content: state.content.sort((a, b) => {
          let aCr = new Date(a.createdAt);
          let bCr = new Date(b.createdAt);
          return aCr - bCr;
        }),
      };
    case CONTENT_ASC_ORDER_BY_CREATEDAT:
      return {
        ...state,
        content: state.content.sort((a, b) => {
          let aCr = new Date(a.createdAt);
          let bCr = new Date(b.createdAt);
          return bCr - aCr;
        }),
      };
    case CONTENT_DESC_ORDER_BY_NAME:
      return {
        ...state,
        content: state.content.sort((a, b) => {
          let nameA = a.name.toUpperCase();
          let nameB = b.name.toUpperCase();
          if (nameB < nameA) {
            return -1;
          }
          if (nameB > nameA) {
            return 1;
          }
          return 0;
        }),
      };
    case CONTENT_ASC_ORDER_BY_NAME:
      return {
        ...state,
        content: state.content.sort((a, b) => {
          let nameA = a.name.toUpperCase();
          let nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        }),
      };
    case CHECKED_CONTENT:
      let checkedList = [];
      state.content
        .filter((c) => c.folderId === action.payload)
        .forEach((c) => {
          checkedList.push({
            ...c,
            checked: true,
          });
        });
      // state.content.forEach((c) => {
      //   checkedList.push({
      //     ...c,
      //     checked: true,
      //   });
      // });
      return {
        ...state,
        content: checkedList,
      };
    case UNCHECKED_CONTENT:
      let unCheckedList = [];
      state.content
        .filter((c) => c.folderId === action.payload)
        .forEach((c) => {
          unCheckedList.push({
            ...c,
            checked: false,
          });
        });
      return {
        ...state,
        content: unCheckedList,
      };
    case CHECKED_SINGLE_CONTENT:
      const _getSingleContentIndex = state.content.findIndex((c) => c.id === action.payload);
      state.content[_getSingleContentIndex] = {
        ...state.content[_getSingleContentIndex],
        checked: true,
      };
      return {
        ...state,
        content: state.content,
      };
    case UNCHECKED_SINGLE_CONTENT:
      const _getSignleUnCheckedContent = state.content.findIndex((c) => c.id === action.payload);
      state.content[_getSignleUnCheckedContent] = {
        ...state.content[_getSignleUnCheckedContent],
        checked: false,
      };
      return {
        ...state,
        content: state.content,
      };
    case DELETE_BULK_CONTENT:
      // state.content.filter((c) => !action.payload.includes(c))
      action.payload.forEach((c) => {
        state.content[state.content.findIndex((f) => f.id === c.id)] = { ...state.content[state.content.findIndex((f) => f.id === c.id)], isActive: false };
      });
      return {
        ...state,
        isDeleting: false,
        content: state.content.filter((c) => c.isActive == true),
      };
    case RESTORE_FILES_REQUEST:
      return {
        ...state,
        isFileRestored: true,
      };
    case RESTORE_FILES_SUCCESS:
      const _fileIndex = state.content.findIndex((c) => c.id === action.payload);
      state.content[_fileIndex] = { ...state.content[_fileIndex], isActive: true };
      return {
        ...state,
        isFileRestored: false,
        content: state.content.filter((c) => c.isActive == false),
      };
    case PICKING_DATA_FROM_GOOGLE:
      return {
        ...state,
        isContentAdding: true,
      };
    case GET_CONTENT_FROM_GOOGLE_DRIVE:
      return {
        ...state,
        isContentAdding: false,
        isContentAddedInContent: true,
        content: [...state.content, ...action.payload],
      };
    case UPLOAD_FAILED:
      toast.error(action.payload);
      return {
        ...state,
        isContentAdding: false,
      };
    default:
      return state;
  }
};
