import { Box, IconButton, Typography, useTheme } from "@mui/material";
import React, { useState } from "react";
import Chart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import './screendatausagechartstyle.css'
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

function ScreenDataUsageStatsChart({ screenStats, formatBytes }) {
  const { t } = useTranslation();
  const [page, setPage] = useState(0)

  const isDarkTheme = useTheme().palette.mode === 'dark';

  const sentBytesArr = []
  const receivedBytesArr = []
  const xaxisArr = []

//   for (let x = 1; x <= 50; x++) {
//     xaxisArr.push(x);
//     sentBytesArr.push(x*200)
//     receivedBytesArr.push(x*190)
//   }

  screenStats.forEach(stat => {
    sentBytesArr.push(stat.totalSentBytes)
    receivedBytesArr.push(stat.totalReceivedBytes)
    xaxisArr.push(new Date(stat.date).toLocaleDateString())
  })

  const handlePageChange = (type) => {
    if(type == '+1'){
        if(page+1 < Math.ceil(xaxisArr.length/10)){
            setPage(page+1)
        }
    }else if(type == '-1'){ 
        if(page > 0){
            setPage(page-1)
        }
    }
  }

  return (
    <Box sx={{
        overflowX: 'auto'
    }}>
        <Box marginTop={"20px"} sx={{minWidth: '460px', minHeight: '400px'}}>
        <Chart
            type="bar" height={350} width={500}
            series={[{
                name: 'Sent Bytes',
                data: sentBytesArr.slice(page*10, (page*10)+10)
            }, {
                name: 'Received Bytes',
                data: receivedBytesArr.slice(page*10, (page*10)+10)
            }
            ]}
            options={{
                chart: {
                type: 'bar',
                height: 350,
                foreColor: isDarkTheme ? '#fff' : '#000'
                },
                plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    borderRadius: 5,
                    borderRadiusApplication: 'end'
                },
                },
                dataLabels: {
                enabled: false
                },
                stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
                },
                xaxis: {
                categories: xaxisArr.slice(page*10, (page*10)+10),
                },
                yaxis: {
                title: {
                    text: 'Bytes'
                }
                },
                fill: {
                opacity: 1
                },
                tooltip: {
                y: {
                    formatter: function (val) {
                    return formatBytes(val)
                    }
                }
                }
            }}
        />
        {xaxisArr.length > 10 ? (
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems:'center'
            }}>
                <IconButton onClick={() => handlePageChange('-1')}>
                    <ArrowLeftIcon/>
                </IconButton>
                <Typography variant="body1">{page+1}/{Math.ceil(xaxisArr.length/10)}</Typography>
                <IconButton onClick={() => handlePageChange('+1')}>
                    <ArrowRightIcon/>
                </IconButton>
            </Box>
        ) : null}
        </Box>
    </Box>
  );
}



export default ScreenDataUsageStatsChart;
