import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { useTranslation } from "react-i18next";
import { Scrollbar } from "src/components/scrollbar";
import { api } from "src/api/endpoints";
import { Box, Button, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, TableContainer, Tooltip, Typography } from "@mui/material";
import { unixTimestampConverter } from "src/utils/unix-timestamp-converter";
import { parsePrice } from "src/utils/parse-price";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DownloadIcon from '@mui/icons-material/Download';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PaidIcon from '@mui/icons-material/Paid';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CloseIcon from '@mui/icons-material/Close';

const InvoicesAdminTab = ({ invoicesList, user }) => {
    const { t } = useTranslation();

    const [currentInvoice, setCurrentInvoice] = useState(null)

    return (
        <Card sx={{
            p: 2
        }}>
            {currentInvoice ? (
            <Box>
                <Button onClick={() => {setCurrentInvoice(null)}} sx={{m: 2}} variant="contained" endIcon={<CloseIcon />}>Close</Button>
                <Box sx={{
                display: 'flex',
                alignItems: "center",
                gap: '20px',
                mx: 2       
                }}>
                <Typography variant="h3">Invoice {currentInvoice.number}</Typography>
                <Typography variant="caption" sx={{
                    py:'3px',
                    px: 2,
                    backgroundColor: currentInvoice.status == 'paid' ? '#D1FAB3' : '',
                    color: currentInvoice.status == 'paid' ? '#217005' : '',
                    borderRadius: '5px'
                }}>{currentInvoice.status}</Typography>
                <Tooltip title="Download invoice PDF" placement="top">
                    <a href={currentInvoice.invoice_pdf} download>
                    <IconButton>
                        <DownloadIcon color="primary"/>
                    </IconButton>
                    </a>
                </Tooltip>
                </Box>
                <Grid container spacing={2} my={3}>
                <Grid item xs={6}>
                    <List>
                    <ListItem>
                        <ListItemIcon>
                        <PersonIcon />
                        </ListItemIcon>
                        <ListItemText primary={`${currentInvoice.customer_name} (${currentInvoice.customer_email})`} secondary="Billed to"/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                        <PersonIcon />
                        </ListItemIcon>
                        <ListItemText primary={currentInvoice.customer_phone} secondary="Phone number"/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                        <LocationCityIcon />
                        </ListItemIcon>
                        <ListItemText primary={`${currentInvoice.customer_shipping.address.line1} ${currentInvoice.customer_shipping.address.line2} ${currentInvoice.customer_shipping.address.postal_code} ${currentInvoice.customer_shipping.address.city} ${currentInvoice.customer_shipping.address.country}`} secondary="Shipping details"/>
                    </ListItem>
                    </List>
                </Grid>
                <Grid item xs={6}>
                    <List>
                    <ListItem>
                        <ListItemIcon>
                        <AccessTimeIcon />
                        </ListItemIcon>
                        <ListItemText primary={unixTimestampConverter(currentInvoice.created)} secondary="Created"/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                        <PaidIcon />
                        </ListItemIcon>
                        <ListItemText primary={currentInvoice.currency} secondary="Currency"/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                        <CalendarMonthIcon />
                        </ListItemIcon>
                        <ListItemText primary={`${currentInvoice.lines.data.find(il => il.price.product == user.plan.productId) ? unixTimestampConverter(currentInvoice.lines.data.find(il => il.price.product == user.plan.productId).period.start) : 'No data'} - ${currentInvoice.lines.data.find(il => il.price.product == user.plan.productId) ? unixTimestampConverter(currentInvoice.lines.data.find(il => il.price.product == user.plan.productId).period.end) : 'No data'}`} secondary="Billing period"/>
                    </ListItem>
                    </List>             
                </Grid>
                </Grid>
                <Card variant="outlined">
                <Table>
                    <TableHead>
                    <TableRow>
                        <TableCell>Description</TableCell>
                        <TableCell>Amount</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {currentInvoice.lines.data.map(il => {
                        return (
                        <TableRow key={il.id}>
                            <TableCell>{il.description}</TableCell>
                            <TableCell>{parsePrice(il.amount)}</TableCell>
                        </TableRow>
                        )
                    })}
                    <TableRow>
                        <TableCell align="right"><Typography variant="button">Total amount due</Typography></TableCell>
                        <TableCell>{parsePrice(currentInvoice.amount_due)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="right"><Typography variant="button">Total amount paid</Typography></TableCell>
                        <TableCell>{parsePrice(currentInvoice.amount_paid)}</TableCell>
                    </TableRow>
                    </TableBody>
                </Table>
                </Card>
            </Box>
            ) : (user.stripeCustomerId && invoicesList ? (
                <Scrollbar>
                <Table sx={{ minWidth: 700 }}>
                    <TableHead>
                    <TableRow>
                        <TableCell>#</TableCell>
                        <TableCell>Amount</TableCell>
                        {/* <TableCell>Currency</TableCell> */}
                        <TableCell>Status</TableCell>
                        <TableCell>Created</TableCell>
                        <TableCell>Actions</TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {invoicesList.map((inv, index) => {
                        return (
                        <TableRow key={inv.id}>
                            <TableCell>{inv.number}</TableCell>
                            <TableCell>{parsePrice(inv.total)}</TableCell>
                            {/* <TableCell>{inv.currency.toUpperCase()}</TableCell> */}
                            <TableCell><Typography color={inv.status == 'paid' ? "success.main" : ''}>{inv.status}</Typography></TableCell>
                            <TableCell>{unixTimestampConverter(inv.created)}</TableCell>
                            <TableCell>
                            <IconButton onClick={() => setCurrentInvoice(inv)}>
                                <RemoveRedEyeIcon color="primary"/>
                            </IconButton>
                            <Tooltip title="Download invoice PDF" placement="top">
                                <a href={inv.invoice_pdf} download>
                                <IconButton>
                                    <DownloadIcon color="primary"/>
                                </IconButton>
                                </a>
                            </Tooltip>
                            </TableCell>
                        </TableRow>
                        )
                    })} 
                    </TableBody>
                </Table>
                </Scrollbar>
            ) : (
                <Typography variant="h5">No invoices</Typography>
            ))
            }
        </Card>
    );
};

export default InvoicesAdminTab;
