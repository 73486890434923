import React, {useEffect, useState} from 'react'
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import { Box, Dialog, Popover, DialogContent, DialogTitle, Grid, Button, Typography, Stack } from "@mui/material";
import { ArrowDropUp as ArrowDropUpIcon } from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import TvIcon from '@mui/icons-material/Tv';
import { getAllScreenById, } from "src/redux/actions/Screen";
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import {temporaryScreen, scheduleSettings} from "../EditScreen/types";
import Checkbox from '@mui/material/Checkbox';
import { useSettings } from 'src/hooks/use-settings';

import dayjs from 'dayjs';
import SchedulerPopover from './SchedulerPopover';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const DialogBoxPushScreen = ({isOpen, onClose}) => {
    const { t } = useTranslation();
    const settings = useSettings();
 
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.User);
    const { allScreens} = useSelector(
        (state) => ({
        //   screens: state.Screen.screens.filter((s) => s.folderId == state.FileFolder.currentFolder),
          allScreens: state.Screen.screens,
        }),
        shallowEqual
      );
    
    const theme = useTheme();
    const [doExpiration, setDoExpiration] = React.useState(false);
    const [personName, setPersonName] = React.useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const [anchorEl3, setAnchorEl3] = useState(null);
    const [anchorEl4, setAnchorEl4] = useState(null);
    const [pushSettings, setPushSettings] = useState({
        screens: [], temp: temporaryScreen.MIN_5, after_expiration: scheduleSettings.LAST_PLAYED,
        schedule_time: dayjs().add(1, 'hour').format("YYYY/MM/DD - HH:mm"), expiration_time: dayjs().add(2, 'hour').format("YYYY/MM/DD - HH:mm")
    });

    const handleClick = (event,type) => {
        if (type == 1){
            setAnchorEl(anchorEl ? null : event.currentTarget);
        }
        if (type == 2){
            setAnchorEl2(anchorEl2 ? null : event.currentTarget);
        }
        if (type == 3){
            setAnchorEl3(anchorEl3 ? null : event.currentTarget);
        }
        if (type == 4){
            if (doExpiration){
                setAnchorEl4(anchorEl3 ? null : event.currentTarget);
            }
        }
     
      };
    const handleClose = (type) => {
        if (type == 1){
            setAnchorEl(null);
        }
        if (type == 2){
            setAnchorEl2(null);
        }
        if (type == 3){
            setAnchorEl3(null);
        }
        if (type == 4){
            setAnchorEl4(null);
        }
    };

    const open = Boolean(anchorEl);
    const open2 = Boolean(anchorEl2);
    const open3 = Boolean(anchorEl3);
    const open4 = Boolean(anchorEl4);
    const id = open ? 'simple-popover' : undefined;
    const id2 = open2 ? 'simple-popover2' : undefined;

    const handleChange = (event) => {
    const {
        target: { value },
    } = event;

    setPushSettings({...pushSettings, screens: value === 'string' ? value.split(',') : value})
    };

    const handleSaveDate = (date, key) => {
        setPushSettings({...pushSettings, [key]: date})
    }

    // ---- useEffects ----
    useEffect(() => {
        dispatch(getAllScreenById(user?._id));
      }, []);


    // ---- Console Logs ----
    // console.log("DialogBoxPushScreen -> allScreens", allScreens)

  return (
    <Dialog
    fullWidth
    maxWidth="md"
        open={isOpen}
    onClose={() => onClose(false)}
    >     
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" backgroundColor={"#1A1A1A"}>
          {/* <img src={"./images/postermywallLogo.png"} alt="Logo" width={"150px"} /> */}
          <TvIcon  sx={{ fontSize: 80, color : settings.paletteMode == "light" ? "white" : "white" }} /> 
          <DialogTitle sx={{ textAlign: "center", fontWeight: "bold", color : settings.paletteMode == "light" ? "white" : "white" }}>Push To Screens</DialogTitle>
          <div style={{
                width: "100%",
                backgroundColor: "#e0e0e0",
                height: "1px",
            }}></div>
        </Box>
        <DialogContent sx={{ minHeight: "30vh" }} >
            <Stack direction="column" justifyContent="center" style={{ width: '100%',  margin:"auto", alignItems:"center"}}>
                <FormControl sx={{ m: 1, width: 300 }}>
                    <InputLabel id="demo-multiple-chip-label">Screens</InputLabel>
                    <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={pushSettings.screens}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="Screens" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip key={value} label={value} />
                        ))}
                        </Box>
                    )}
                    MenuProps={MenuProps}
                    >
                    {allScreens && allScreens.map((screen) => (
                        <MenuItem
                        key={screen.deviceName}
                        value={screen.deviceName}
                        style={getStyles(screen.deviceName, personName, theme)}
                        >
                        {screen.deviceName}
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>

                <Stack direction="row" sx={{marginLeft:"auto", gap:"6px", marginTop:"30px"}}>
                    <Button variant="outlined" sx={{ borderRadius: 1}} onClick={()=>onClose()}>Cancel</Button>
                    <Button
                        variant="contained"
                        onClick={(e)=>handleClick(e,1)}
                        endIcon={<ArrowDropUpIcon />} // Ikona na górze przycisku
                        sx={{ borderRadius: 1}}
                        disabled = {pushSettings.screens.length == 0}
                    >
                        Temp Takeover
                    </Button>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={()=>handleClose(1)}
                        sx={{
                            '& .MuiPaper-root': { // Selekcja klasy root Paper
                              padding: '20px', // Ustawienie paddingu
                              marginBottom: '10px',
                              borderRadius:"10px",
                              display: 'flex',
                              flexDirection: 'column',
                            },
                          }}
                        anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                        }}
                        transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                        }}
                    >
                        <Typography sx={{  fontWeight:"bold", marginBottom:"20px" }}>Temporarily take over the screens</Typography>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="select-label"></InputLabel>
                            <Select
                                labelId="select-label"
                                value={pushSettings.temp}
                                onChange={(e)=>setPushSettings({...pushSettings, temp: e.target.value})}
                                label=""
                            >
                                {Object.entries(temporaryScreen).map(([key, value]) => (
                                <MenuItem key={key} value={value}>
                                    {value}
                                </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button
                            variant="outlined"
                            onClick={()=>handleClose(1)}
                            sx={{ borderRadius: 1,marginLeft:"auto", marginTop:"20px"}}
                            >
                            Set
                        </Button>

                    </Popover>

                    <Button
                        variant="outlined"
                        onClick={(e)=>handleClick(e,2)}
                        endIcon={<ArrowDropUpIcon />} // Ikona na górze przycisku
                        sx={{
                            '& .MuiPaper-root': { // Selekcja klasy root Paper
                              padding: '20px', // Ustawienie paddingu
                              marginBottom: '10px',
                              borderRadius:"10px",
                              display: 'flex',
                              flexDirection: 'column',
                            },
                          }}
                          disabled = {pushSettings.screens.length == 0}
                    >
                        Schedule Changes
                    </Button>
                    <Popover
                        id={id2}
                        open={open2}
                        anchorEl={anchorEl2}
                        onClose={()=>handleClose(2)}
                        sx={{
                            '& .MuiPaper-root': { // Selekcja klasy root Paper
                              padding: '20px', // Ustawienie paddingu
                              marginBottom: '10px',
                              borderRadius:"10px",
                              display: 'flex',
                              flexDirection: 'column',
                              width:"350px"
                            },
                          }}
                        anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                        }}
                        transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                        }}
                    >
                        <Typography sx={{  fontWeight:"bold", marginBottom:"8px", fontSize:"14px" }}>Schedule go-live time</Typography>
                        <div onClick={(e)=>handleClick(e,3)} style={{border:"1px solid #1f2837", borderRadius:"6px", padding:"10px", marginBottom:"20px",cursor:"pointer"}}>
                            <Typography sx={{  }}>{pushSettings.schedule_time}</Typography>
                        </div>
                        <Stack direction="row" sx={{alignItems:"center", marginBottom:"8px"}}>
                            <Checkbox sx={{marginLeft:"-10px"}} onChange={()=>setDoExpiration(prev=>!prev)}/>
                            <Typography sx={{  fontWeight:"bold", fontSize:"14px" }}>Expiration time</Typography>
                        </Stack>
                        <div onClick={(e)=>handleClick(e,4)} style={{cursor: !doExpiration ? "not-allowed" : "pointer", border: !doExpiration ? "1px solid #1f2837" : "1px solid #83878e",color:!doExpiration ? "#83878e":"", borderRadius:"px", padding:"10px", marginBottom:"20px",cursor:"pointer"}}>
                            <Typography sx={{  }}>{pushSettings.expiration_time}</Typography>
                        </div>
                        <SchedulerPopover id={id} open={open4} anchorEl={anchorEl4} handleClose={handleClose} num={4} time={pushSettings.expiration_time} handleSaveDate={handleSaveDate} key_={"expiration_time"}/>

                        <FormControl fullWidth  >
                            <InputLabel id="select-label">After expiration time</InputLabel>
                            <Select
                                disabled={!doExpiration}
                                sx={{cursor:"not-allowed"}}
                                labelId="select-label"
                                value={pushSettings.after_expiration}
                                onChange={(e)=>setPushSettings({...pushSettings, after_expiration: e.target.value})}
                                label="After expiration time"
                            >
                                {Object.entries(scheduleSettings).map(([key, value]) => (
                                <MenuItem key={key} value={value}>
                                    {value}
                                </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <Button
                            variant="outlined"
                            onClick={()=>handleClose(2)}
                            sx={{ borderRadius: 1,marginLeft:"auto", marginTop:"20px"}}
                            >
                            Schedule
                        </Button>
                    </Popover>
                    <Button variant="contained" sx={{ borderRadius: 1}} disabled={pushSettings.screens.length == 0}>Push</Button>
                </Stack>
                
            </Stack>
        </DialogContent>

    </Dialog>
  )
}

export default DialogBoxPushScreen