import { api } from "../../api/endpoints";
import { CHANGE_FOLDER, CREATE_FOLDER, FOLDER_FLAG_OFF, GET_FOLDERS, SET_FOLDER_ID, FOLDER_DELETE, EDIT_FOLDER, CREATE_FOLDER_FAIL } from "../constant";
export const folderFlagOff = () => {
  return async (dispatch) => {
    dispatch({
      type: FOLDER_FLAG_OFF,
      payload: "",
    });
  };
};
export const createFolder = (obj) => async (dispatch) => {
  try {
    const { data } = await api.post("/folder/createfolder", obj);

    dispatch({
      type: CREATE_FOLDER,
      payload: data.folder,
    });

  } catch (error) {
    dispatch({
      type: CREATE_FOLDER_FAIL,
      payload: error.response
    })
  }
};
export const getFolders = (id) => {
  return async (dispatch) => {
    const { data, status } = await api.get(`/folder/getfolders/${id}`);
    if (status === 200) {
      dispatch({
        type: GET_FOLDERS,
        payload: data.folders,
      });
    }
  };
};
export const setFolderId = (id) => {
  return async (dispatch) => {
    console.log("SET_FOLDER_ID triggered from:", new Error().stack);
    dispatch({
      type: SET_FOLDER_ID,
      payload: id,
    });
  };
};
export const changeFolder = (id) => {
  return async (dispatch) => {
    dispatch({
      type: CHANGE_FOLDER,
      payload: id,
    });
  };
};
export const folderDelete = (id) => {
  return async (dispatch) => {
    const { status, data } = await api.get(`/folder/deletefolder/${id}`);
    if (status === 201) {
      if (data.deleted) {
        dispatch({
          type: FOLDER_DELETE,
          payload: id,
        });
      }
    }
  };
};
export const editFolder = (id, name) => {
  return async (dispatch) => {
    const { status, data } = await api.patch(`/folder/editfolder/${id}`, { name });
    if (status === 201) {
      dispatch({
        type: EDIT_FOLDER,
        payload: data.updated,
      });
    }
  };
};
