import toast from "react-hot-toast";
import {
    CREATE_PRODUCT,
    CREATE_PRICE,
    PRODUCTS_FAIL,
    EDIT_PRODUCT,
    EDIT_PRICE,
    GET_PRODUCTS,
    ATTACH_PRODUCT_SUCCESS,
} from "../constant";
import { t } from "i18next";

const initial = {
    products: [],
    hasMoreProducts: false
};

export const ProductsReducer = (state = initial, action) => {
    switch (action.type) {
        case CREATE_PRODUCT:
            state.products.push(action.payload)
            return {
                ...state
            }

        case GET_PRODUCTS:
            console.log(action.payload)
            return {
                ...state,
                products: [...action.payload.data],
                hasMoreProducts: action.payload.has_more
            }

        case EDIT_PRODUCT:
            const existingProductIndex = state.products.findIndex(prod => prod.id == action.payload.id)

            state.products[existingProductIndex] = action.payload

            return {
                ...state,
            }

        case CREATE_PRICE:
            toast.success(t("views.productsManager.priceCreateSuccess"))
            return {
                ...state
            }

        case PRODUCTS_FAIL:
            if(action.payload){
                // console.log(action.payload)
                toast.error(action.payload)
            }else{
                toast.error(t("views.productsManager.productManagerFail"))
            }
            return {
                ...state
            }
        case EDIT_PRICE: 
            toast.success(t("views.productsManager.priceEditedSuccess"))
            return {
                ...state
            }
        case ATTACH_PRODUCT_SUCCESS: 
            toast.success(t("views.productsManager.attachProductSuccess"))
            return {
                ...state
            }
        default:
            return state;
    }
};