import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  Stack,
  DialogContent,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Typography,
  Checkbox,
  Tooltip,
} from "@mui/material";
import { FaTwitch } from "react-icons/fa";
import { useSettings } from "src/hooks/use-settings";
import { addWidget, editContent } from "src/redux/actions/Content";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

const Themes = {
  LIGHT: "light",
  DARK: "dark",
};

const DialogBoxTwitchPlaylist = ({
  object,
  isNew,
  isOpen,
  onClose,
  isIcon,
}) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const { t } = useTranslation();
  const settings = useSettings();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.User, shallowEqual);
  const { currentFolder } = useSelector(
    (state) => state.FileFolder,
    shallowEqual
  );

  const [twitchConfig, setTwitchConfig] = useState({
    name: "",
    url: "",
    theme: Themes.DARK,
    chat: false,
  });
  const [savedTwitchConfig, setSavedTwitchConfig] = useState({});
  const [isSaved, setIsSaved] = useState(false);
  const [savedId, setSavedId] = useState("");
  const [channel, setChannel] = useState("");

  useEffect(() => {
    if (isOpen) {
      if (isNew) {
        resetConfig();
      } else if (object) {
        initializeConfig(object);
      }
    }
  }, [isOpen, isNew, object]);

  useEffect(() => {
    // Jeśli pole jest puste, wyzeruj stan i wyjdź
    if (!twitchConfig.url) {
      setChannel("");
      return;
    }
  
    // Spróbuj sparsować URL (z protokołem)
    try {
      const validatedUrl = normalizeTwitchUrl(twitchConfig.url);
      const url = new URL(validatedUrl);
  
      if (url.pathname.includes("/videos/")) {
        setChannel(`video=${url.pathname.split("/").pop()}`);
      } else if (url.pathname.includes("/clip/")) {
        setChannel(`clip=${url.pathname.split("/").pop()}`);
        handleChangeTwitchConfig("chat", false);
      } else {
        // Zakładamy, że ścieżka to nazwa kanału, np. /ninja
        setChannel(url.pathname.split("/").pop());
      }
    } catch (e) {
      // Jeśli URL jest niepoprawny, np. "asd" albo "twitch.tv"
      console.warn("Niepoprawny URL Twitch:", e.message);
      setChannel("");
    }
  }, [twitchConfig.url]);

  const normalizeTwitchUrl = (inputUrl) => {
    if (
      inputUrl &&
      !inputUrl.startsWith("http://") &&
      !inputUrl.startsWith("https://")
    ) {
      return `https://${inputUrl}`;
    }
    return inputUrl;
  };
  
  const resetConfig = () => {
    setTwitchConfig({ name: "", url: "", theme: Themes.DARK, chat: false });
    setSavedId("");
    setIsSaved(false);
    setChannel("");
  };

  const initializeConfig = (obj) => {
    const config = {
      name: obj.name,
      url: obj.widget?.url,
      theme: obj.widget?.theme || Themes.DARK,
      chat: obj.widget?.chat,
    };
    setTwitchConfig(config);
    setSavedTwitchConfig(config);
    setIsSaved(true);
    setSavedId(obj.id);
  };

  const handleChangeTwitchConfig = (key, value) => {
    setTwitchConfig((prev) => ({ ...prev, [key]: value }));
  };

  const areConfigsDifferent = (config1, config2) => {
    return JSON.stringify(config1) !== JSON.stringify(config2);
  };

  const canSave = () => {
    return (
      twitchConfig.name &&
      twitchConfig.url &&
      (!isSaved || areConfigsDifferent(twitchConfig, savedTwitchConfig))
    );
  };

  const handleSave = async () => {
    try {
      const widgetData = {
        name: twitchConfig.name,
        type: "widget",
        widgetCat: "twitch",
        url: twitchConfig.url,
        widgetIcon: isIcon,
        theme: twitchConfig.theme,
        chat: twitchConfig.chat,
        user: user.isMember ? user?.user._id : user._id,
        folderId: currentFolder,
      };

      let action =
        isSaved && savedId
          ? editContent({ object: widgetData, id: savedId })
          : addWidget(widgetData);

      const content = await dispatch(action);

      if (content && content.id) {
        setSavedId(content.id);
        setSavedTwitchConfig(twitchConfig);
        setIsSaved(true);
      }
    } catch (error) {
      toast.error(error.message || "Failed to save Twitch playlist");
    }
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={() => onClose(false)}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        backgroundColor="#1c2536"
        width="100%"
        sx={{ padding: "15px" }}
      >
        <FaTwitch
          size={80}
          style={{
            color: settings.paletteMode === "light" ? "#4c525d" : "white",
          }}
        />
        <Typography sx={{ fontWeight: "bold", fontSize: "30px" }}>
          Twitch
        </Typography>
      </Box>
      <DialogContent>
        <Stack
          direction="column"
          sx={{ gap: "15px", justifyContent: "center" }}
        >
          <TextField
            label={`${t("views.DialogBoxTwitchPlaylist.name_label")} *`}
            variant="outlined"
            value={twitchConfig.name}
            onChange={(e) => handleChangeTwitchConfig("name", e.target.value)}
            fullWidth
          />

          <TextField
            label="Twitch URL *"
            variant="outlined"
            value={twitchConfig.url}
            onChange={(e) => handleChangeTwitchConfig("url", e.target.value)}
            fullWidth
          />
          <FormControl fullWidth variant="outlined">
            <InputLabel id="select-label">{`${t(
              "views.DialogBoxTwitchPlaylist.theme_label"
            )} *`}</InputLabel>
            <Select
              labelId="select-label"
              value={twitchConfig.theme}
              onChange={(e) =>
                handleChangeTwitchConfig("theme", e.target.value)
              }
              label={`${t("views.DialogBoxTwitchPlaylist.theme_label")} *`}
            >
              <MenuItem value={Themes.LIGHT}>
                {t("views.DialogBoxTwitchPlaylist.light_theme")}
              </MenuItem>
              <MenuItem value={Themes.DARK}>
                {t("views.DialogBoxTwitchPlaylist.dark_theme")}
              </MenuItem>
            </Select>
          </FormControl>

          <Stack
            direction="row"
            sx={{ alignItems: "center", marginLeft: "2px" }}
          >
            <Typography>
              {t("views.DialogBoxTwitchPlaylist.chat_label")}
            </Typography>
            <Checkbox
              checked={twitchConfig.chat}
              onChange={(e) =>
                handleChangeTwitchConfig("chat", e.target.checked)
              }
            />
          </Stack>
        </Stack>
      </DialogContent>
      <Divider sx={{ width: "100%" }} />
      <DialogActions
        sx={{
          backgroundColor: "#1A2432",
          width: "100%",
          padding: "8px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Tooltip
          title={t("views.DialogBoxTwitchPlaylist.preview_tooltip")}
          arrow
        >
          <span>
            <Button
              disabled={!isSaved}
              onClick={() => {
                window.open(
                  `${baseUrl}/twitchtv?chat=${twitchConfig.chat}&channel=${channel}&theme=${twitchConfig.theme}`,
                  "_blank"
                );
              }}
              variant="contained"
            >
              {t("views.DialogBoxTwitchPlaylist.preview_button")}
            </Button>
          </span>
        </Tooltip>
        <Stack direction="row" sx={{ alignItems: "center", gap: "10px" }}>
          <Button onClick={() => onClose(false)} variant="outlined">
            {t("views.DialogBoxTwitchPlaylist.close_button")}
          </Button>
          <Tooltip
            title={t("views.DialogBoxTwitchPlaylist.save_tooltip")}
            arrow
          >
            <span>
              <Button
                disabled={!canSave()}
                variant="contained"
                onClick={handleSave}
              >
                {t("views.DialogBoxTwitchPlaylist.save_button")}
              </Button>
            </span>
          </Tooltip>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default DialogBoxTwitchPlaylist;