import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConfirmDisableDialog({openConfirmDisableDialog, setOpenConfirmDisableDialog, handlePriceDisable}) {
  return (
      <Dialog
        open={openConfirmDisableDialog}
        onClose={() => setOpenConfirmDisableDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure to disable price?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This action is irreversible. You will be able to add a new price.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmDisableDialog(false)}>Cancel</Button>
          <Button onClick={handlePriceDisable} autoFocus color='error'>
            Disable
          </Button>
        </DialogActions>
      </Dialog>
  );
}