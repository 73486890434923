import {
    GET_DAY_SCREEN_DATA_USAGE_STATS,
    GET_DAY_SCREEN_DATA_USAGE_STATS_FAIL,
    GET_PERIOD_SCREEN_DATA_USAGE_STATS_FAIL,
    GET_PERIOD_SCREEN_DATA_USAGE_STATS_SUCCESS
} from "../constant";

const initial = {
  screenStatsError: true,
  dayScreenStat: null,
  periodScreenStatsError: true,
  periodScreenStatsErrorMsg: '',
  periodScreenStats: null
};
export const ScreenStatsReducer = (state = initial, action) => {
  switch (action.type) {
    case GET_DAY_SCREEN_DATA_USAGE_STATS:
      return {
        ...state,
        dayScreenStat: action.payload,
        screenStatsError: false
      };
    
    case GET_DAY_SCREEN_DATA_USAGE_STATS_FAIL:
      return {
        ...state,
        screenStatsError: true
      }
    case GET_PERIOD_SCREEN_DATA_USAGE_STATS_FAIL:
      return {
        ...state,
        periodScreenStatsError: true,
        periodScreenStatsErrorMsg: action.payload
      }

    case GET_PERIOD_SCREEN_DATA_USAGE_STATS_SUCCESS:
      return {
        ...state,
        periodScreenStats: action.payload,
        periodScreenStatsError: false
      }
    default:
      return state;
  }
};
