import { Box, Container, Stack, Card, Grid, SvgIcon, TextField, Button, Typography, CardContent, Tooltip } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Layout from "../../components/layout/Layout";
import Edit02Icon from "@untitled-ui/icons-react/build/esm/Edit02";
import { emailTempSave, getEmailTemp, editEmailTemp, emailFlagOff } from "../../redux/actions/EmailAction";
import { useSettings } from "src/hooks/use-settings";
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddProductDialog from "./components/AddProductDialog";
import { attachProductToPlan, createPrice, createProduct, editPrice, editProduct } from "src/redux/actions/Products";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import toast from "react-hot-toast";
import { api } from "src/api/endpoints";
import AddPriceDialog from "./components/AddPriceDialog";
import ConfirmDisableDialog from "./components/ConfirmDisableDialog";
import ConfirmDisableProductDialog from "./components/ConfirmDisableProductDialog";
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { parsePrice } from "src/utils/parse-price";

function ProductsManager({ user }) {
    const { t } = useTranslation();
    const settings = useSettings();
    const dispatch = useDispatch()

    const { plans, additionalFeatures } = useSelector((state) => state.Plan);
    const [planToAttach, setPlanToAttach] = useState('')

    const [accordionExpanded, setAccordionExpanded] = useState('')

    const [addProductModalOpen, setAddProductModalOpen] = useState(false);
    const [newProductName, setNewProductName] = useState('')
    const [newProductDescription, setNewProductDescription] = useState('')
    const [newProductCurrency, setNewProductCurrency] = useState('')
    const [newProductPrice, setNewProductPrice] = useState('')
    const [newProductBillingInterval, setNewProductBillingInterval] = useState('')
    const [productsPrices, setProductsPrices] = useState([])

    const [addPriceModalOpen, setPriceModalOpen] = useState(false)
    const [newPriceCurrency, setNewPriceCurrency] = useState('')
    const [newPrice, setNewPrice] = useState('')
    const [newPriceBillingInterval, setNewPriceBillingInterval] = useState('')
    const [currentAddPriceProduct, setCurrentAddPriceProduct] = useState(null)

    const [openConfirmDisableDialog, setOpenConfirmDisableDialog] = useState(false)
    const [openConfirmDisableProductDialog, setOpenConfirmDisableProductDialog] = useState(false)

    const [currentDisablingPriceId, setCurrentDisablingPriceId] = useState('')
    const [currentDisablingProductId, setCurrentDisablingProductId] = useState('')

    const { products, hasMoreProducts } = useSelector((state) => state.Products);

    const handleAddProductModalOpen = () => {
        setAddProductModalOpen(true);
    };
    const handleAddProductModalClose = () => {
        setAddProductModalOpen(false);
    };

    const handleAddPriceModalClose = () => {
        setPriceModalOpen(false)
    }
    const handleAddPriceModalOpen = (id, name) => {
        setCurrentAddPriceProduct({id: id, name: name})
        setPriceModalOpen(true)

    }

    // useEffect(() => {
    //     console.log(products)
    //     plans.forEach(x => {
    //         if(x.productId){
    //             const productIndex = products.findIndex(y => y.id == x.productId)
    //             console.log(productIndex)
    //             if(productIndex){
    //                 products[productIndex].planId = x._id
    //                 products[productIndex].planName = x.name
    //             }
    //         }
    //     })
    // }, [plans])


    const handleProductAdd = () => {
        handleAddProductModalClose()
        if(newProductPrice){
            if(Number(newProductPrice) >= 100){
                dispatch(createProduct({
                    productName: newProductName,
                    productDescription: newProductDescription,
                    priceCurrency: newProductCurrency,
                    price: Number(newProductPrice),
                    priceInterval: newProductBillingInterval
                }))
            }else{
                toast.error("Price must be more than 100 cents")
            }
        }else{
            toast.error("Price is required")
        }
    }

    const handleProductPriceDataGet = async (id) => {
        if(id){
            setAccordionExpanded(id)
            const existingItem = productsPrices.find(prod => prod.id == id)

            if(!existingItem){
                try{
                    const resp = await api.post('/products/getPricesAttachedToProduct', {
                        productId: id
                    })
                    setProductsPrices([
                        ...productsPrices,
                        {
                            id: id,
                            data: resp.data.prices.data
                        }
                    ])
                }catch (error) {
                    toast.error('Error while downloading product data')
                }
            }
        }
    }   

    const handlePriceDisable = () => {
        setOpenConfirmDisableDialog(false)
        if(currentDisablingPriceId){
            dispatch(editPrice({
                active: false,
                priceId: currentDisablingPriceId
            }))
            let productToDelete = ''
            const oldProductsPrices = productsPrices
            oldProductsPrices.forEach(x =>{
                if(x.data.find(y => y.id == currentDisablingPriceId)){
                    productToDelete = x.id
                }
            })
            if(productToDelete){
                setProductsPrices(productsPrices.filter(x => x.id !== productToDelete))
                setAccordionExpanded('')
            }
        }else{
            toast.error("Error during price inactivation")
        }
    }

    const handleProductDisable = () => {
        setOpenConfirmDisableProductDialog(false)
        if(currentDisablingProductId){
            dispatch(editProduct({
                productActive: false,
                productId: currentDisablingProductId
            }))
        }else{
            toast.error("Error during product inactivation") 
        }
    }

    const handleProductEnable = (id) => {
        dispatch(editProduct({
            productActive: true,
            productId: id
        }))
    }

    const handlePriceAdd = () => {
        // toast.success(currentAddPriceProduct.name)
        handleAddPriceModalClose()
        if(currentAddPriceProduct){
            if(currentAddPriceProduct.id){
                if(newPrice){
                    if(Number(newPrice) >= 100){
                        dispatch(createPrice({
                            priceCurrency: newPriceCurrency,
                            price: Number(newPrice),
                            priceInterval: newPriceBillingInterval,
                            productId: currentAddPriceProduct.id
                        }))
                        setProductsPrices(productsPrices.filter(x => x.id !== currentAddPriceProduct.id))
                        setAccordionExpanded('')
                    }else{
                        toast.error("Price must be more than 100 cents")
                    }
                }else{
                    toast.error("Price is required")
                }
            }else{
                toast.error("Error when adding a new price")
            }
        }else{
            toast.error("Error when adding a new price")
        }
    }

    const handlePlanAttach = (productId) => {
        if(planToAttach){
            dispatch(attachProductToPlan({
                productId: productId,
                planId: planToAttach
            }))
            setPlanToAttach('')
            
            if(plans.concat(additionalFeatures).find(p => p._id == planToAttach)){
                const index = products.findIndex(x => x.id == productId)
                products[index].productName = plans.concat(additionalFeatures).find(p => p._id == planToAttach).name  
            }
        }else{
            toast.error("Select Plan to Continue")
        }
    }

  return (
    <>
      <Layout title={t("views.redesignEmail.title")} user={user}>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            py: 2,
          }}>
          <Container maxWidth={settings.stretch ? false : "xl"}>
            <Button variant="contained" onClick={handleAddProductModalOpen} sx={{
                mb: 5
            }}>Add Product</Button>
            { products.length > 0 ? 
                products.map(item => {
                    return (
                        <Accordion onChange={() => handleProductPriceDataGet(item.id)} key={item.id} expanded={accordionExpanded === item.id}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                            >
                                <Typography variant="h6" sx={{
                                    display: 'inline'
                                }}>{item.name} &nbsp;</Typography>
                                <Typography variant="caption" sx={{
                                    display: 'inline',
                                    color: item.active ? '#4caf50' : '#b2102f'
                                }}>{item.active ? 'Active' : 'Inactive'}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box sx={{
                                    display: 'flex',
                                    gap: '10px',
                                    flexWrap: 'wrap'
                                }}>
                                    <Box>
                                        <Typography variant="body1">Attached plan:&nbsp;</Typography>
                                        <Typography variant="body1" fontWeight="900">{item.productName ? item.productName : plans.concat(additionalFeatures).find(p => p.productId == item.id) ? plans.concat(additionalFeatures).find(p => p.productId == item.id).name : 'N/A'}</Typography>
                                    </Box>
                                    <Box sx={{width: '100%', display: 'flex', gap: '20px', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                                        {!plans.concat(additionalFeatures).find(p => p.productId == item.id) ? (
                                            item.active ? (
                                                <Button color="error" onClick={() => {setCurrentDisablingProductId(item.id); setOpenConfirmDisableProductDialog(true)}} variant="outlined">Disable product</Button>
                                            ) : (
                                                <Button color="success" variant="outlined" onClick={() => handleProductEnable(item.id)}>Enable product</Button>
                                            )
                                        ) : (
                                            <Tooltip title="You can't disable this product, because it's attached to plan" placement="top">
                                                <Button color="error" disabled variant="outlined">Disable product</Button>
                                            </Tooltip>
                                        )}

                                        <Box sx={{width: '80%', display: 'flex', gap: '20px', alignItems: 'center', flexWrap: 'wrap', justifyContent: 'flex-end'}}>
                                            <Typography variant="body1">Attach product to plan</Typography>
                                            <FormControl fullWidth sx={{
                                                maxWidth: '250px'
                                            }}>
                                                <InputLabel id="billingIntervalLabel">Select Plan</InputLabel>
                                                <Select
                                                    labelId="billingIntervalLabel"
                                                    id="billingInterval"
                                                    value={planToAttach}
                                                    label="Age"
                                                    required
                                                    onChange={(e) => setPlanToAttach(e.target.value)}
                                                >
                                                    {plans.concat(additionalFeatures).map(plan => {
                                                        return (
                                                            <MenuItem value={plan._id} key={plan._id}>{plan.name}</MenuItem> 
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                            <Button variant="contained" onClick={() => handlePlanAttach(item.id)}>Attach</Button>
                                        </Box>
                                    </Box>
                                    { productsPrices.find(prod => prod.id == item.id) ? productsPrices.find(prod => prod.id == item.id).data.length > 0 ? (
                                            <TableContainer component={Paper}>
                                                <Table sx={{ minWidth: 650 }}>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>#</TableCell>
                                                            <TableCell align="center">Active</TableCell>
                                                            <TableCell align="center">Price</TableCell>
                                                            {/* <TableCell align="center">Currency</TableCell> */}
                                                            <TableCell align="center">Billing interval</TableCell>
                                                            <TableCell align="center">Actions</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                    {productsPrices.find(prod => prod.id == item.id).data.map((price, index) => (
                                                        <TableRow
                                                            key={index}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell component="th" scope="row">
                                                                {index+1}
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                <Typography variant="body2" sx={{color: price.active ? '#4caf50' : '#b2102f'}}>
                                                                    {price.active ? 'Yes' : 'No'}
                                                                </Typography>
                                                            </TableCell>
                                                            <TableCell align="center">{parsePrice(price.unit_amount)}</TableCell>
                                                            {/* <TableCell align="center">{price.currency.toUpperCase()}</TableCell> */}
                                                            <TableCell align="center">{price.recurring.interval}</TableCell>
                                                            <TableCell align="center">
                                                                {price.active ? (
                                                                    <Button color="error" onClick={() => {setCurrentDisablingPriceId(price.id); setOpenConfirmDisableDialog(true)}}>Disable</Button>
                                                                ) : null}
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                    ) : (
                                        <Typography variant="body1" textAlign="center" sx={{width: '100%'}}>No price data</Typography>
                                    ) : (
                                        <Typography variant="body1" textAlign="center" sx={{width: '100%'}}>No price data</Typography>
                                    )}
                                    <Button variant="contained" sx={{mt: 3}} onClick={() => handleAddPriceModalOpen(item.id, item.name)}>Add price</Button>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    )
                }) : (
                <Typography variant="h5" textAlign="center">No products</Typography>
            ) }

            <AddProductDialog addProductModalOpen={addProductModalOpen} 
                handleClose={handleAddProductModalClose} 
                newProductName={newProductName} 
                setNewProductName={setNewProductName} 
                newProductDescription={newProductDescription} 
                setNewProductDescription={setNewProductDescription}
                newProductCurrency={newProductCurrency}
                setNewProductCurrency={setNewProductCurrency}
                newProductPrice={newProductPrice}
                setNewProductPrice={setNewProductPrice}
                newProductBillingInterval={newProductBillingInterval}
                setNewProductBillingInterval={setNewProductBillingInterval}
                handleProductAdd={handleProductAdd}
            />



            <AddPriceDialog addPriceModalOpen={addPriceModalOpen} handleClose={handleAddPriceModalClose}
                newPriceCurrency={newPriceCurrency}
                setNewPriceCurrency={setNewPriceCurrency}
                newPrice={newPrice}
                setNewPrice={setNewPrice}
                newPriceBillingInterval={newPriceBillingInterval}
                setNewPriceBillingInterval={setNewPriceBillingInterval}
                handlePriceAdd={handlePriceAdd}
                currentAddPriceProduct={currentAddPriceProduct}
            />

            <ConfirmDisableDialog openConfirmDisableDialog={openConfirmDisableDialog} setOpenConfirmDisableDialog={setOpenConfirmDisableDialog} handlePriceDisable={handlePriceDisable} />
            <ConfirmDisableProductDialog handleProductDisable={handleProductDisable} openConfirmDisableDialog={openConfirmDisableProductDialog} setOpenConfirmDisableDialog={setOpenConfirmDisableProductDialog}/>
          </Container>
        </Box>
      </Layout>
    </>
  );
}

export default ProductsManager;
