import React from "react";
import { Button, Box, Typography } from "@mui/material";
import { useMediaQuery } from "react-responsive";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { changeFolder } from "../../../redux/actions/FileFolder";
import { useTranslation } from "react-i18next";

function RedesignBodyHeader() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentFolder, currentFolderData, userFolders, content } =
    useSelector(
      (state) => ({
        currentFolder: state.FileFolder.currentFolder,
        currentFolderData: state.FileFolder.userFolders.find(
          (u) => u._id === state.FileFolder.currentFolder
        ),
        userFolders: state.FileFolder.userFolders,
        content: state.Content.content,
      }),
      shallowEqual
    );

  const handleNavigate = (id) => {
    dispatch(changeFolder(id)); // Update current folder in state
  };

  const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

  return (
    <>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "relative",
          marginBottom: "5px",
        }}
      >
        <div
          role="presentation"
          style={{
            display: "flex",
            width: "100%",
            margin: "0 5px",
            alignItems: "center",
          }}
        >
          {currentFolder !== "root" ? (
            <>
              <Button onClick={() => handleNavigate("root")}>
                {t("components.redesignBodyHeader.home")}
              </Button>
              <Box
                sx={{
                  backgroundColor: "neutral.500",
                  borderRadius: "50%",
                  height: 4,
                  width: 4,
                  margin: "0 8px",
                }}
              />
              {currentFolderData?.path?.map((c, index) => (
                <React.Fragment key={index}>
                  <Button onClick={() => handleNavigate(c.parent)}>
                    {userFolders.find((u) => u._id === c.parent)?.name ||
                      t("components.redesignBodyHeader.unknownFolder")}
                  </Button>
                  <Box
                    sx={{
                      backgroundColor: "neutral.500",
                      borderRadius: "50%",
                      height: 4,
                      width: 4,
                      margin: "0 8px",
                    }}
                  />
                </React.Fragment>
              ))}

              <Typography
                sx={{ padding: "10px 10px" }}
                variant="button"
                display="block"
              >
                {currentFolderData?.name}
              </Typography>
            </>
          ) : (
            <Typography
              variant="button"
              sx={{ marginLeft: "25px" }}
              display="block"
            >
              {t("components.redesignBodyHeader.home")}
            </Typography>
          )}
        </div>
      </Box>
    </>
  );
}

export default RedesignBodyHeader;
