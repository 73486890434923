import { api } from "src/api/endpoints";

import { geocodeByAddress } from "react-places-autocomplete";

import {
  GET_ALL_USERS,
  LOGIN_FAILED,
  LOGIN_BLOCKED,
  LOGIN_FLAG_OFF,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  REFRESH_LOGIN,
  SIGNUP_REQUEST,
  SIGNUP_SUCCESS,
  SIGNUP_SUCCESS_FLAG_OFF,
  UPDATE_USER_PROFILE,
  DELETE_USER_PROFILE,
  VERIFY_SIGNUP_REQUEST,
  VERIFY_SIGNUP_SUCCESS,
  GET_SINGLE_USER,
  PASSWORD_CHANGE_EMAIL,
  INVALID_PASSWORD_CHANGE_EMAIL,
  PASSWORD_CHANGE_REQUEST,
  PASSWORD_CHANGE_TOKEN_EXPIRED,
  PASSWORD_CHANGED,
  LOGIN_WITH_FACEBOOK,
  BLOCKED_USER,
  RESTORED_USER,
  REMOVED_USER,
  RESENT_EMAIL,
  USER_DESC_ORDER_BY_EMAIL,
  USER_ASC_ORDER_BY_EMAIL,
  USER_DESC_ORDER_BY_NAME,
  USER_ASC_ORDER_BY_NAME,
  USER_DESC_ORDER_BY_DATE,
  USER_ASC_ORDER_BY_DATE,
  USER_DESC_ORDER_BY_STATUS,
  USER_ASC_ORDER_BY_STATUS,
  SET_USERS_GROUP,
  PASSWORD_CHANGE_ERROR,
  CURRENT_PASSWORD_INVALID,
  REMOVE_USERS_GROUP,
} from "../constant";
import { app, fbAuth, provider } from "src/firbaseConfig";
import { GoogleAuthProvider } from "firebase/auth";

export const login = (email, password) => {
  return async (dispatch) => {
    dispatch({
      type: LOGIN_REQUEST,
      payload: "",
    });
    const user = await api.post(`/user/login`, { email, password });

    console.log(user);
    if (user.status === 200) {
      window.localStorage.setItem("isLogin", true);
      if (user.data.auth) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: user.data,
        });
      } else if (user.data.message === "User is blocked.") {
        dispatch({
          type: LOGIN_BLOCKED,
          payload: user.data,
        });
      } else {
        dispatch({
          type: LOGIN_FAILED,
          payload: user.data,
        });
      }
    }
  };
};
export const loginWithFacebook = (obj) => {
  return async (dispatch) => {
    dispatch({
      type: LOGIN_REQUEST,
      payload: "",
    });
    const { status, data } = await api.post("/user/facebook-register", obj);
    console.log(data);
    if (status === 201) {
      dispatch({
        type: LOGIN_WITH_FACEBOOK,
        payload: { user: data.user, auth: data.auth, social: true },
      });
    }
  };
};
export const loginWithGoogle = (result) => {
  return async (dispatch) => {
    dispatch({
      type: LOGIN_REQUEST,
      payload: "",
    });
    const credential = GoogleAuthProvider.credentialFromResult(result);
    console.log("credential=>", credential);
    const token = credential.idToken;
    console.log("google login=>", { user: await fbAuth.currentUser.getIdToken() });
    const { status, data } = await api.post("/user/login", { token: await fbAuth.currentUser.getIdToken(), type: "google" });

    console.log(data);
    if (status === 200) {
      window.localStorage.setItem("isLogin", true);
      if (data.auth) {
        dispatch({
          type: LOGIN_SUCCESS,
          payload: data,
        });
      } else if (data.message === "User is blocked.") {
        dispatch({
          type: LOGIN_BLOCKED,
          payload: data,
        });
      } else {
        dispatch({
          type: LOGIN_FAILED,
          payload: data,
        });
      }
    }
  };
};
export const socialMediaLogin = (user) => {
  return async (dispatch) => {
    // console.log("social media=>",user)
    dispatch({
      type: LOGIN_SUCCESS,
      payload: user,
      // payload: { user, social: true }
    });
  };
};
export const logout = () => {
  return async (dispatch) => {
    // window.localStorage.removeItem('isLogin');
    const user = await api.get("/user/logout");
    dispatch({
      type: LOGOUT,
      payload: "",
    });
  };
};
export const socialMediaLogout = () => {
  return async (dispatch) => {
    const user = await api.get("/logout");
    dispatch({
      type: LOGOUT,
      payload: "",
    });
  };
};
export const loginFlagOff = () => {
  return async (dispatch) => {
    dispatch({
      type: LOGIN_FLAG_OFF,
      payload: "",
    });
  };
};
export const signup = (obj) => {
  return async (dispatch) => {
    dispatch({
      type: SIGNUP_REQUEST,
      payload: "",
    });
    const user = await api.post(`/user/register`, obj);
    if (user.status === 201) {
      dispatch({
        type: SIGNUP_SUCCESS,
        payload: user.data.user,
      });
    }

    return user.data;
  };
};
export const signupFlagOff = () => {
  return async (dispatch) => {
    dispatch({
      type: SIGNUP_SUCCESS_FLAG_OFF,
      payload: "",
    });
  };
};
export const verifySignupAccount = (token) => {
  return async (dispatch) => {
    dispatch({
      type: VERIFY_SIGNUP_REQUEST,
      payload: "",
    });
    const user = await api.get(`/user/verify/${token}`);
    console.log("verified=>", user);
    if (user.status === 200) {
      dispatch({
        type: VERIFY_SIGNUP_SUCCESS,
        payload: user.data,
      });
    }
  };
};
export const refreshLogin = (status, data) => {
  return async (dispatch) => {
    if (status === 201) {
      if (data.auth) {
        dispatch({ type: REFRESH_LOGIN, payload: data });
      }
    }
  };
};
export const getAllUsers = () => {
  return async (dispatch) => {
    const users = await api.get("/user/list");
    if (users.status === 200) {
      dispatch({
        type: GET_ALL_USERS,
        payload: users.data.users,
      });
    }
  };
};
export const getSingleUser = (id) => {
  return async (dispatch) => {
    dispatch({
      type: GET_SINGLE_USER,
      payload: id,
    });
  };
};
export const updateUserProfile = (id, obj) => async (dispatch) => {
  try {
    const { data } = await api.patch(
      `/user/${id}`,
      obj,
      obj.avatar instanceof File
        ? {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        : {}
    );

    dispatch({
      type: UPDATE_USER_PROFILE,
      payload: data.user,
    });
  } catch (error) {
    if (!error.response) return;

    const { status, data } = error.response;

    if (status === 400) throw { formErrors: data };
  }
};
export const getUserProfile = (id) => async (dispatch) => {
  const { data } = await api.get(`/user/${id}`);

  dispatch({
    type: UPDATE_USER_PROFILE,
    payload: data.user,
  });
};
export const passwordChangeEmail = (email) => {
  return async (dispatch) => {
    const { status, data } = await api.post("/user/forgetpassword", { email });
    if (status === 200) {
      data.linkSent &&
        dispatch({
          type: PASSWORD_CHANGE_EMAIL,
          payload: email,
        });
      data.inValidEmail &&
        dispatch({
          type: INVALID_PASSWORD_CHANGE_EMAIL,
          payload: email,
        });
    }
  };
};
export const passwordChange = (obj) => {
  return async (dispatch) => {
    dispatch({
      type: PASSWORD_CHANGE_REQUEST,
      payload: "",
    });
    // const { status, data } =
    await api
      .post(`/user/forgetpassword/${obj.token}`, {
        password: obj.password,
        confirmPassword: obj.confirmPasswords,
      })
      .then((res) => {
        dispatch({
          type: PASSWORD_CHANGED,
          payload: res.data.user,
        });
      })
      .catch((error) => {
        if (error.status === 400) {
          dispatch({
            type: PASSWORD_CHANGE_TOKEN_EXPIRED,
            payload: "",
          });
        }
      });
    // if (status === 201) {
    //   dispatch({
    //     type: PASSWORD_CHANGED,
    //     payload: data.user,
    //   })
    // }
    // if (status === 400) {
    //   dispatch({
    //     type: PASSWORD_CHANGE_TOKEN_EXPIRED,
    //     payload: ''
    //   })
    // }
  };
};
export const changePassword = (userId, obj) => {
  return async (dispatch) => {
    dispatch({
      type: PASSWORD_CHANGE_REQUEST,
      payload: "",
    });
    try {
      const passwordRequest = await api.post(`/user/${userId}/password`, obj);
      console.log(passwordRequest);
      if (passwordRequest.status === 200) {
        dispatch({
          type: PASSWORD_CHANGED,
          payload: passwordRequest.data.user,
        });
      }
    } catch (error) {
      let errorMessage = error.response.data.toString();
      console.log("passwordRequest error", errorMessage);

      if (errorMessage == "Incorrect current password") {
        console.log("passwordRequest error if", errorMessage);

        dispatch({
          type: CURRENT_PASSWORD_INVALID,
          payload: errorMessage,
        });
      } else {
        console.log("passwordRequest error else", errorMessage);

        dispatch({
          type: PASSWORD_CHANGE_ERROR,
          payload: errorMessage,
        });
      }
    }
  };
};
export const userBlocked = (id, active) => {
  return async (dispatch) => {
    const { status, data } = await api.post(`/user/${id}/block`, { active });
    dispatch({
      type: BLOCKED_USER,
      payload: data.user,
    });
  };
};
export const userRestored = (id, active) => {
  return async (dispatch) => {
    const { status, data } = await api.post(`/user/${id}/restore`);
    dispatch({
      type: RESTORED_USER,
      payload: data.user
    })
  }
}
export const userRemoved = (id) => {
  return async (dispatch) => {
    const { status, data } = await api.post(`/user/${id}/removeUser`);
    console.log(data)
    dispatch({
      type: REMOVED_USER,
      payload: data.userId
    })
  }
}
export const resendVerificationEmail = (id) => {
  return async () => {
    await api.post(`/user/${id}/resendVerifyEmail`)
  }
}
export const userDescByEmail = () => {
  return async (dispatch) => {
    dispatch({
      type: USER_DESC_ORDER_BY_EMAIL,
      payload: "",
    });
  };
};
export const userAscByEmail = () => {
  return async (dispatch) => {
    dispatch({
      type: USER_ASC_ORDER_BY_EMAIL,
      payload: "",
    });
  };
};
export const userDescByName = () => {
  return async (dispatch) => {
    dispatch({
      type: USER_DESC_ORDER_BY_NAME,
      payload: "",
    });
  };
};
export const userAscByName = () => {
  return async (dispatch) => {
    dispatch({
      type: USER_ASC_ORDER_BY_NAME,
      payload: "",
    });
  };
};
export const userDescByDate = () => {
  return async (dispatch) => {
    dispatch({
      type: USER_DESC_ORDER_BY_DATE,
      payload: "",
    });
  };
};
export const userAscByDate = () => {
  return async (dispatch) => {
    dispatch({
      type: USER_ASC_ORDER_BY_DATE,
      payload: "",
    });
  };
};
export const userDescByStatus = () => {
  return async (dispatch) => {
    dispatch({
      type: USER_DESC_ORDER_BY_STATUS,
      payload: "",
    });
  };
};
export const userAscByStatus = () => {
  return async (dispatch) => {
    dispatch({
      type: USER_ASC_ORDER_BY_STATUS,
      payload: "",
    });
  };
};
export const deleteUser = (id) => async (dispatch) => {
  const { data } = await api.delete(`/user/${id}`);

  dispatch({
    type: DELETE_USER_PROFILE,
    payload: data.user,
  });
};

export const assignGroup = (obj) => async (dispatch) => {
  const { data } = await api.post(`/user/group/`, obj);

  dispatch({
    type: SET_USERS_GROUP,
    payload: data.users,
  });
};

export const removeGroup = (obj) => async (dispatch) => {
  const { data } = await api.delete(`/user/group/`, {
    data: obj,
  });

  dispatch({
    type: SET_USERS_GROUP,
    payload: data.users,
  });
};
