import { api } from "../../api/endpoints";
import {
    LIST_FEATURES_LOCKS_SUCCESS,
    SET_FEATURES_LOCKS_SUCCESS,
    SET_FEATURES_LOCKS_FAIL,
    SET_FEATURES_LOCKS_LOADING
} from "../constant";

export const listFeaturesLocks = () => {
    return async (dispatch) => {
        try{
            const response = await api.get(`/functionalityLocks/list`);
            dispatch({
                type: LIST_FEATURES_LOCKS_SUCCESS,
                payload: response.data.functionalitiesList,
            });
        } catch(error) {}
    };
}

export const setFeatureLock = (flId, lockStatus) => {
    return async (dispatch) => {
        try {
            const setFeatureOb = await api.post('/functionalityLocks/set', {
                functionalityId: flId,
                lock: lockStatus
            })
            dispatch({
                type: SET_FEATURES_LOCKS_SUCCESS,
                payload: setFeatureOb.data.functionality
            })
        } catch (error) {
            dispatch({
                type: SET_FEATURES_LOCKS_FAIL
            })
        }
    }
}

export const setLoading = (status) => {
    return (dispatch) => {
        dispatch({
            type: SET_FEATURES_LOCKS_LOADING,
            payload: status
        })
    }
}