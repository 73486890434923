import { SvgIcon, Tooltip, Typography } from "@mui/material";
import { CgEthernet } from "react-icons/cg";
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import { t } from "i18next";

export const NetworkIcon = ({ ethernetStatus, wifiSignal, connectionType }) => {
    const wifiStatusArray = [t("components.tableBodies.noConnectionLabel"), t("components.tableBodies.poorConnectionLabel"), t("components.tableBodies.fairConnectionLabel"), t("components.tableBodies.goodConnectionLabel"), t("components.tableBodies.excellentConnectionLabel")]
    if (ethernetStatus === 1) {
      return (
        <Tooltip title="Ethernet" placement="top">
            <SvgIcon>
                <CgEthernet size={20} color="green"/>
            </SvgIcon>
        </Tooltip>
      );
    }else if(ethernetStatus === 0){
        if(connectionType == 'Cellular'){
            return (
                <Tooltip title={t('components.tableBodies.cellularNetwork')} placement="top">
                    <SvgIcon>
                        <SignalCellularAltIcon/>
                    </SvgIcon>
                </Tooltip>
            )
        }else if(connectionType === 'WiFi'){
            const wifiIcons = [
                <img src={require('../wifi-icons/wifi1.png')} alt="No Connection" style={{width: '30px'}}/>,
                <img src={require('../wifi-icons/wifi2.png')} alt="Poor" style={{width: '30px'}}/>,
                <img src={require('../wifi-icons/wifi3.png')} alt="Fair/Average" style={{width: '30px'}}/>,
                <img src={require('../wifi-icons/wifi4.png')} alt="Good" style={{width: '30px'}}/>,
                <img src={require('../wifi-icons/wifi5.png')} alt="Excellent" style={{width: '30px'}}/>,
            ];
            return (
                <Tooltip placement="top" title={wifiStatusArray[wifiSignal]}>
                    {wifiIcons[wifiSignal]}
                </Tooltip>
            ) || (
                <Tooltip placement="top" title={wifiStatusArray[0]}>
                    {wifiIcons[0]}
                </Tooltip>
            );
        }else{
            return (
                <Typography>N/A</Typography>
            )
        }
    }
};
