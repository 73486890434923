import React from "react";
import PropTypes from "prop-types";
import { format } from "date-fns";
import numeral from "numeral";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import { Tooltip } from "@mui/material";

const statusMap = {
  complete: "success",
  pending: "info",
  canceled: "warning",
  rejected: "error",
};

export const OrderListTable = (props) => {
  const { items = [], onSelect } = props;
  const { t } = useTranslation();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div>
      <Table>
        <TableBody>
          {items.length > 0 &&
            items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((playlist) => {
              return (
                <TableRow hover key={playlist._id} onClick={() => onSelect?.(playlist._id)} sx={{ cursor: "pointer" }}>
                  <TableCell
                    sx={{
                      alignItems: "center",
                      display: "flex",
                    }}>
                    <Box sx={{ ml: 2 }}>
                      <Tooltip title={playlist.playlistName.length > 30 ? playlist.playlistName : ''} placement="right">
                        <Typography variant="subtitle2">
                          {playlist.playlistName.length > 30 ? `${playlist.playlistName.slice(0, 30)}...` : playlist.playlistName}
                        </Typography>
                      </Tooltip>
                    </Box>
                    {playlist?.defaultPlaylist && (
                      <Box sx={{ ml: "auto" }}>
                        <Typography variant="subtitle2">{t("views.orderListTable.demoPlaylist")}</Typography>
                      </Box>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
          {items.length < 1 && (
            <TableRow sx={{ cursor: "pointer" }}>
              <TableCell
                sx={{
                  alignItems: "center",
                  display: "flex",
                }}>
                <Box sx={{ ml: 2 }}>
                  <Typography variant="subtitle2">{t("views.orderListTable.playlistsNotFound")}</Typography>
                </Box>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        labelRowsPerPage={t("views.orderListTable.rowsQty")}
        count={items.length}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </div>
  );
};

OrderListTable.propTypes = {
  count: PropTypes.number,
  items: PropTypes.array,
  onPageChange: PropTypes.func,
  onRowsPerPageChange: PropTypes.func,
  onSelect: PropTypes.func,
  page: PropTypes.number,
  rowsPerPage: PropTypes.number,
};
