export const paths = {

  // vcp
  home: {
    index: '/',
  },
  apk: {
    index: '/apk',
  },
  files: {
    index: '/files'
  },
  screens: {
    index: '/screen'
  },
  playlist: {
    index: '/playlist'
  },
  users: {
    index: '/users',
    details: '/user-details/:id',
    edit: '/user-details/edit/:id/',
    remove: '/users/remove/:id',
    groups: '/users/group'
  },
  reports: {
    index: '/report'
  },
  restore_files: {
    index: '/restorefile'
  },
  feature_manager: {
    index: '/feature-manager'
  },
  products_manager: {
    index: '/products-manager'
  },
  email_templates: {
    index: '/email-templete'
  },
  authentication: {
    logout: '/auth/logout'
  },
  // devias
  dashboard: {
    invoices: {
      index: '',
      details: ''
    },
  }
};
