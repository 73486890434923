import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";

import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";

import Layout from "src/components/layout/Layout";
import { useStripe } from "@stripe/react-stripe-js";
import { Alert } from "@mui/material";
import { useSettings } from "src/hooks/use-settings";
import { useState, useEffect } from "react";

const CheckPaymentStatus = ({user}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const stripe = useStripe()
    const settings = useSettings();

  const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret')

  const [stripeResult, setStripeResult] = useState('error')

  usePageView();


  useEffect(() => {
    const getStripeResult = async () => {
      console.log(stripe)
      try {
        await stripe.retrievePaymentIntent(clientSecret).then(({paymentIntent}) => {
          console.log(paymentIntent)
          // Inspect the PaymentIntent `status` to indicate the status of the payment
          // to your customer.
          //
          // Some payment methods will [immediately succeed or fail][0] upon
          // confirmation, while others will first enter a `processing` state.
          //
          // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
          switch (paymentIntent.status) {
            case 'succeeded':
              // message.innerText = 'Success! Payment received.';
              setStripeResult('success')
              console.log(paymentIntent.status)
              break
              // return (
              //     <Box sx={{
              //         display: 'flex',
              //         flexDirection: 'column',
              //         alignItems: 'center'
              //     }}>
              //         <Alert variant="filled" severity="success">
              //             Success! Payment received.
              //         </Alert>
              //         <Button variant="contained" onClick={() => navigate(`/user-details/${user._id}`)}>Back to Your account</Button>
              //     </Box>
              // )
        
            case 'processing':
              // message.innerText = "Payment processing. We'll update you when payment is received.";
              setStripeResult('processing')
              break
              // return (
              //     <Box sx={{
              //         display: 'flex',
              //         flexDirection: 'column',
              //         alignItems: 'center'
              //     }}>
              //         <Alert variant="filled" severity="info">
              //             Payment processing. We'll update your account when payment is received.
              //         </Alert>
              //         <Button variant="contained" onClick={() => navigate(`/user-details/${user._id}`)}>Back to Your account</Button>
              //     </Box>
              // )
        
            case 'requires_payment_method':
              // message.innerText = 'Payment failed. Please try another payment method.';
              setStripeResult('failed')
              break
              // return (
              //     <Box sx={{
              //         display: 'flex',
              //         flexDirection: 'column',
              //         alignItems: 'center'
              //     }}>
              //         <Alert variant="filled" severity="warning">
              //             Payment failed. Please try another payment method.
              //         </Alert>
              //         <Button variant="contained" onClick={() => navigate(`/user-details/${user._id}`)}>Back to Your account</Button>
              //     </Box>
              // )
              // Redirect your user back to your payment page to attempt collecting
              // payment again
        
            default:
              // message.innerText = 'Something went wrong.';
              setStripeResult('error')
              break
              // return (
              //     <Box sx={{
              //         display: 'flex',
              //         flexDirection: 'column',
              //         alignItems: 'center'
              //     }}>
              //         <Alert variant="filled" severity="error">
              //             Something went wrong.
              //         </Alert>
              //         <Button variant="contained" onClick={() => navigate(`/user-details/${user._id}`)}>Back to Your account</Button>
              //     </Box>
              // )
          }
        });
      }catch (err) { console.log(err) }
    }
    getStripeResult()
  }, [stripe])

  console.log(stripeResult)

  return (
    <Layout>
      <Seo title={t("views.userDetails.seoTitle")} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}>
        <Container maxWidth={settings.stretch ? false : "xl"}>
            {
              stripeResult == 'success' && (
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '20px'
              }}>
                  <Alert variant="filled" severity="success">
                      Success! Payment received.
                  </Alert>
                  <Button variant="contained" onClick={() => navigate(`/user-details/${user._id}`)}>Back to Your account</Button>
              </Box>
              )
            }
            {
              stripeResult == 'processing' && (
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '20px'
              }}>
                  <Alert variant="filled" severity="info">
                      Payment processing. We'll update your account when payment is received.
                  </Alert>
                  <Button variant="contained" onClick={() => navigate(`/user-details/${user._id}`)}>Back to Your account</Button>
              </Box>
              )
            }
            {
              stripeResult == 'failed' && (
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '20px'
              }}>
                  <Alert variant="filled" severity="warning">
                      Payment failed. Please try another payment method.
                  </Alert>
                  <Button variant="contained" onClick={() => navigate(`/user-details/${user._id}`)}>Back to Your account</Button>
              </Box>
              )
            }
            {
              stripeResult == 'error' && (
                <Box sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '20px'
              }}>
                  <Alert variant="filled" severity="error">
                      Something went wrong.
                  </Alert>
                  <Button variant="contained" onClick={() => navigate(`/user-details/${user._id}`)}>Back to Your account</Button>
              </Box>
              )
            }
        </Container>
      </Box>
    </Layout>
  );
};

export default CheckPaymentStatus;
