import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Box } from '@mui/system';
import { useEffect } from 'react';
import { api } from 'src/api/endpoints';
import { useState } from 'react';
import { Card, Collapse, Divider, Grid, List, ListItem, ListItemText, DialogTitle, DialogContent, DialogContentText, DialogActions, ListItemIcon, TextField, TableContainer, Table, TableBody, TableRow, TableCell, TableHead, useTheme } from '@mui/material';
import { unixTimestampConverter } from 'src/utils/unix-timestamp-converter';
import UpgradeIcon from '@mui/icons-material/Upgrade';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import toast from 'react-hot-toast';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import { parsePrice } from 'src/utils/parse-price';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import PaymentsIcon from '@mui/icons-material/Payments';
import EditIcon from '@mui/icons-material/Edit';
import RemoveIcon from '@mui/icons-material/Remove';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RedesignSubscriptionManager({customer, setCurrentTab, plans, user, handleScreenQuantityChange, additionalFeatures}) {
    const [ customerPlan, setCustomerPlan ] = useState(null)
    const [ customerAdditionalScreensSI, setCustomerAdditionalScreensSI ] = useState(null)
    const [cancelConfirmOpen, setCancelConfirmOpen] = useState(false)
    const [editScreensOpen, setEditScreensOpen] = useState(false)
    const [additionalScreensAmount, setAdditionalScreensAmount] = useState('')
    const [additionalScreensPricesList, setAdditionalScreensPricesList] = useState([])
    const [openCancelScreens, setOpenCancelScreens] = useState(false)
    const [screensModifyMode, setScreensModifyMode] = useState('')
    const [screensToIncrease, setScreensToIncrease] = useState('0')
    const [screensToDescrease, setScreensToDescrease] = useState('0')
    const [downgradeScreensConfirm, setDowngradeScreensConfirm] = useState(false)
    const [upcomingInvoice, setUpcomingInvoice] = useState(null)

    useEffect(() => {
        setCustomerPlan(customer.subscriptions.data[0].items.data.find(si => si.planDetails?.planType == 'plan'))
        setCustomerAdditionalScreensSI(customer.subscriptions.data[0].items.data.find(si => si.planDetails?.name == 'Additional screen'))
        setAdditionalScreensAmount(customer.subscriptions.data[0].items.data.find(si => si.planDetails?.name == 'Additional screen') ? customer.subscriptions.data[0].items.data.find(si => si.planDetails?.name == 'Additional screen').quantity : '0')

        const getDataOnStartup = async () => {
            const additionalScreensPricesRes = await api.post('/payment/getPricesAttachedToProduct', {productId: additionalFeatures.find(p => p.name == 'Additional screen').productId})
            const subscriptionUpcomingInvoice = await api.post('/payment/retrieveSubscriptionUpcomingInvoice', {subscriptionId: customer.subscriptions.data[0].id, customerId: user.stripeCustomerId})

            setAdditionalScreensPricesList(additionalScreensPricesRes.data.prices.data)
            setUpcomingInvoice(subscriptionUpcomingInvoice.data.invoice)
        }
        getDataOnStartup()
    }, [])

    const isDarkTheme = useTheme().palette.mode === 'dark';

    const handleSubscriptionCancel = async (subId) => {
        setCancelConfirmOpen(false)
        try {
            await api.post('/payment/cancelUserPlanSubscription', {subId: subId})
            setTimeout(() => {
                window.location.reload()
            }, 500)
        }catch (err) {
            toast.error("Error while cancelling subscription")
        }
    }

    const getScreenPrices = async (mode) => {
        try {
            setEditScreensOpen(true)
            const additionalScreensPricesRes = await api.post('/payment/getPricesAttachedToProduct', {productId: additionalFeatures.find(p => p.name == 'Additional screen').productId})
            setAdditionalScreensPricesList(additionalScreensPricesRes.data.prices.data)
            setScreensModifyMode(mode)
        } catch (error) { }
    }

    const changeScreensQuantity = (price) => {
        if(Number(additionalScreensAmount) > 0) {
            if(customerAdditionalScreensSI){
                if(customerAdditionalScreensSI.quantity == Number(additionalScreensAmount)){
                    toast.error('Enter a different number of screens')
                }else{
                    handleScreenQuantityChange(price, additionalScreensAmount, customerAdditionalScreensSI ? 'edit' : 'create')
                }
            }else{
                handleScreenQuantityChange(price, additionalScreensAmount, customerAdditionalScreensSI ? 'edit' : 'create')
            }
        }else{
            toast.error('Quantity is required')
        }
    }

    const handleScreenSubscriptionCancel = async () => {
        setOpenCancelScreens(false)
        try {
            const cancelRes = await api.post('/payment/cancelAdditionalFeatureSubscription', {
                siId: customerAdditionalScreensSI.id,
                customerId: user.stripeCustomerId
            })
            window.location.reload()
        } catch (error) {
            toast.error(error.response.data.message)
        }   
    }

    return (
        <React.Fragment>
            <Box sx={{overflow: 'auto'}}>

            <Grid container spacing={2} mx="auto" width="100%" maxWidth={1800} mt={2} pb={2} minWidth={990} sx={{
            alignItems: 'stretch'
            }}>
            <Grid item xs={6}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    gap: '20px'
                }}>
                    <Card sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px'
                    }} variant='outlined'>
                    <Box sx={{
                        backgroundColor: isDarkTheme ? '#1C2536' : '#F8F9FA',
                        p:2
                    }}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            mt: 1
                        }}>
                            <Typography variant='h3'>{customerPlan ? customerPlan.planDetails.name : 'No data'}</Typography>
                            <Typography variant='h6'>{customerPlan ? parsePrice(customerPlan.price.unit_amount) : 'No data'} / {customerPlan ? customerPlan.price.recurring.interval : 'No data'}</Typography>
                        </Box>
                        <Typography variant='h6'>Current plan</Typography>
                    </Box>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '10px',
                    }}>
                        <List>
                            <ListItem>
                                <ListItemText primary={customer ? unixTimestampConverter(customer.subscriptions.data[0].start_date) : 'No data'} secondary="subscription start date"></ListItemText>
                            </ListItem>
                        </List>
                        <List>
                            <ListItem>
                                <ListItemText primary={customer ? unixTimestampConverter(customer.subscriptions.data[0].current_period_start) : 'No data'} secondary="previous payment"></ListItemText>
                            </ListItem>
                        </List>
                        <List>
                            <ListItem>
                                <ListItemText primary={customer ? unixTimestampConverter(customer.subscriptions.data[0].current_period_end) : 'No data'} secondary="next payment"></ListItemText>
                            </ListItem>
                        </List>
                        <List>
                            <ListItem>
                                <ListItemText primary={customer ? customer.subscriptions.data[0].status : 'No data'} secondary="status"></ListItemText>
                            </ListItem>
                        </List>
                    </Box>
                    <Divider />
                    <Box mt={2} sx={{px: 2, pb: 2}}>
                        <Button variant='contained' onClick={() => setCurrentTab('featuresList')} endIcon={<UpgradeIcon/>}>
                        Choose another plan
                        </Button>
                        <Button variant='contained' sx={{
                        ml: 2
                        }} color="error" onClick={() => setCancelConfirmOpen(true)} endIcon={<CancelIcon/>}>
                        Cancel
                        </Button>
                    </Box>
                    <Collapse in={cancelConfirmOpen} timeout="auto" unmountOnExit sx={{px: 2, pb: 2}}>
                        <Box>
                            <Typography variant='body1'>Are you sure about cancelling your subscription?</Typography>
                            <Button variant="contained" sx={{mr: 2, mt:2}} color="error" onClick={() => setCancelConfirmOpen(false)}>No</Button>
                            <Button variant="contained" sx={{mr: 2, mt:2}} onClick={() => handleSubscriptionCancel(customer.subscriptions.data[0].id)}>Yes, cancel</Button>
                        </Box>
                    </Collapse>
                    </Card>
                    <Card sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '10px',
                        p: 2
                        }} variant='outlined'>
                        <Typography variant='h5'>Upcoming invoice</Typography>
                        <Typography variant="subtitle2">On: {upcomingInvoice ? unixTimestampConverter(upcomingInvoice.next_payment_attempt) : 'No data'}</Typography>
                        {upcomingInvoice ? (
                            <TableContainer sx={{mt: 2}}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            {/* <TableCell>#</TableCell> */}
                                            <TableCell>Description</TableCell>
                                            {/* <TableCell>Quantity</TableCell> */}
                                            {/* <TableCell>Unit price</TableCell> */}
                                            <TableCell>Amount</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {upcomingInvoice.lines.data.map((it, index) => {
                                            return (
                                                <TableRow key={index}>
                                                    {/* <TableCell>{index+1}</TableCell> */}
                                                    <TableCell>{it.description}</TableCell>
                                                    {/* <TableCell>{it.quantity}</TableCell> */}
                                                    {/* <TableCell>{parsePrice(it.unit_amount)}</TableCell> */}
                                                    <TableCell>{it.amount < 0 ? `- ${parsePrice(Math.abs(it.amount))}` : parsePrice(it.amount)}</TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        ) : (
                            <Typography>No upcoming invoice</Typography>
                        )}
                        <Box mt={2}>
                            <Typography variant='h6' textAlign="end">Total:</Typography>
                            <Typography variant='h5' textAlign="end">{upcomingInvoice ? parsePrice(upcomingInvoice.total) : 'No data'}</Typography>
                        </Box>
                    </Card>
                </Box>
            </Grid>
            <Grid item xs={6}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    gap: '20px'
                }}> 
                    <Card sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px'
                    }} variant='outlined'>
                        <Box sx={{
                            backgroundColor: isDarkTheme ? '#1C2536' : '#F8F9FA',
                            p:2
                        }}>
                            <Typography variant='h3'>Screens</Typography>
                            <Typography variant="h6" sx={{mt: 1}}>You have total of {customerAdditionalScreensSI ? customerAdditionalScreensSI.quantity + Number(user.plan.planFeatures.find(pf => pf.slug == 'max_screens').value) : user.plan.planFeatures.find(pf => pf.slug == 'max_screens').value} screens</Typography>
                        </Box>
                    <List>
                        <ListItem>
                            <ListItemText primary={`${user.plan.planFeatures.find(pf => pf.slug == 'max_screens').value} Free screens`}/>
                        </ListItem>
                        <ListItem>
                        <ListItemText primary={customerAdditionalScreensSI ? `${customerAdditionalScreensSI.quantity} Paid screens` : 'No paid screens'}/>
                        </ListItem>
                    </List>
                    <Divider />
                    <Box mt={2} sx={{
                        display: 'flex',
                        gap: '10px',
                        flexDirection: 'column',
                        p:2
                    }}>
                        {/* {
                            additionalScreensPricesList ? (
                                <>
                                    <Typography variant='subtitle1'>Price per screen per {additionalScreensPricesList.find(asp => asp.recurring.interval == customerPlan.price.recurring.interval)?.recurring.interval}: {parsePrice(additionalScreensPricesList.find(asp => asp.recurring.interval == customerPlan.price.recurring.interval)?.unit_amount)}</Typography>
                                </>
                            ) : (
                                <Typography>No price</Typography>
                            )
                        } */}
                        <Typography variant='subtitle1'>Upgrade / Downgrade screen quantity up to:</Typography>
                        {additionalScreensPricesList ? customerAdditionalScreensSI ? (
                            <>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px'
                                }}>
                                    <IconButton onClick={() => {
                                        if(Number(additionalScreensAmount) > 0){
                                            setAdditionalScreensAmount(`${Number(additionalScreensAmount)-1}`)
                                        }
                                    }}>
                                        <RemoveIcon color='primary'/>
                                    </IconButton>
                                    <TextField variant='outlined' type="number" InputProps={{inputProps: {min: 0}}} autoComplete="off" sx={{
                                            my: 3
                                    }} value={additionalScreensAmount} onInput={(event) => setAdditionalScreensAmount(event.target.value)}/>
                                    <Typography variant='h6'>screens</Typography>
                                    <IconButton onClick={() => setAdditionalScreensAmount(`${Number(additionalScreensAmount)+1}`)}>
                                        <AddIcon color='primary'/>
                                    </IconButton>
                                    <Button variant='contained' disabled={additionalScreensPricesList.find(asp => asp.recurring.interval == customerPlan.price.recurring.interval)?.unit_amount*(Number(additionalScreensAmount)-customerAdditionalScreensSI.quantity) == 0 ? true : false} onClick={() => {
                                        if(additionalScreensAmount == '0'){
                                            setOpenCancelScreens(true)
                                        }else{
                                            if(Number(additionalScreensAmount)-customerAdditionalScreensSI.quantity < 0){
                                                setDowngradeScreensConfirm(true)
                                            }else{
                                                changeScreensQuantity(additionalScreensPricesList.find(asp => asp.recurring.interval == customerPlan.price.recurring.interval))
                                            }
                                        }
                                    }}>Submit</Button>
                                </Box>
                                <Typography variant='h6'>Subscription update: {Number(additionalScreensAmount)-customerAdditionalScreensSI.quantity < 0 ? '- ' : ''}{Number(additionalScreensAmount)-customerAdditionalScreensSI.quantity !== 0 ? parsePrice(Math.abs(additionalScreensPricesList.find(asp => asp.recurring.interval == customerPlan.price.recurring.interval)?.unit_amount*(Number(additionalScreensAmount)-customerAdditionalScreensSI.quantity))) : '$0'}</Typography>
                            </>
                        ) : (
                            <>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '10px'
                                }}>
                                    <IconButton onClick={() => {
                                        if(Number(additionalScreensAmount) > 0){
                                            setAdditionalScreensAmount(`${Number(additionalScreensAmount)-1}`)
                                        }
                                    }}>
                                        <RemoveIcon color='primary'/>
                                    </IconButton>
                                    <TextField variant='outlined' type="number" InputProps={{inputProps: {min: 0}}} autoComplete="off" sx={{
                                            my: 3
                                    }} value={additionalScreensAmount} onInput={(event) => setAdditionalScreensAmount(event.target.value)}/>
                                    <Typography variant='h6'>screens</Typography>
                                    <IconButton onClick={() => setAdditionalScreensAmount(`${Number(additionalScreensAmount)+1}`)}>
                                        <AddIcon color='primary'/>
                                    </IconButton>
                                    <Button variant='contained' disabled={(Number(additionalScreensAmount)) == 0 ? true : false} onClick={() => changeScreensQuantity(additionalScreensPricesList.find(asp => asp.recurring.interval == customerPlan.price.recurring.interval))}>Submit</Button>
                                </Box>
                                <Typography>Screen subscription total: {Number(additionalScreensAmount) !== 0 ? parsePrice(additionalScreensPricesList.find(asp => asp.recurring.interval == customerPlan.price.recurring.interval)?.unit_amount*(Number(additionalScreensAmount))) : '$0'}</Typography>
                            </>
                        ) : null}
                        {/* {customerAdditionalScreensSI ? (
                            <Box sx={{
                                mt: 3
                            }}>
                                <Button variant="contained" endIcon={<CancelIcon />} color="error" onClick={() => setOpenCancelScreens(true)}>Cancel additional screens subscription</Button>
                            </Box>
                        ) : null} */}
                    </Box>
                    <Collapse in={openCancelScreens} timeout="auto" unmountOnExit sx={{mt: 1, p:2}}>
                        <Box>
                            <Box>
                                <Typography variant='body1'>Are you sure you want to downgrade the quantity of screens?</Typography>
                                <Typography variant="caption" color="error">Remember, we do not refund unused subscription time of paid screens</Typography>
                            </Box>
                            <Button variant="contained" color='error' onClick={() => setOpenCancelScreens(false)} sx={{mr: 2, mt:2}}>No</Button>
                            <Button variant="contained" onClick={() => handleScreenSubscriptionCancel(customer.subscriptions.data[0].id)}sx={{mt:2}}>Yes, downgrade</Button>
                        </Box>
                    </Collapse>
                    <Collapse in={downgradeScreensConfirm} timeout="auto" unmountOnExit sx={{mt: 1, p:2}}>
                        <Box>
                            <Box>
                                <Typography variant='body1'>Are you sure you want to downgrade the quantity of screens?</Typography>
                                <Typography variant="caption" color="error">Remember, we do not refund unused subscription time of paid screens</Typography>
                            </Box>
                            <Button variant="contained" color='error' onClick={() => setDowngradeScreensConfirm(false)} sx={{mr: 2, mt:2}}>No</Button>
                            <Button variant="contained" onClick={() => handleScreenSubscriptionCancel(changeScreensQuantity(additionalScreensPricesList.find(asp => asp.recurring.interval == customerPlan.price.recurring.interval)))}sx={{mt:2}}>Yes, downgrade</Button>
                        </Box>
                    </Collapse>
                    {/* <Collapse in={editScreensOpen} timeout="auto" unmountOnExit sx={{mt: 3}}>
                        {screensModifyMode === 'create' && (
                            <Box>
                                <Typography variant='body1'>Enter the number of screens</Typography>
                                <TextField variant='outlined' type="number" label="Additional screens" fullWidth autoComplete="off" sx={{
                                    my: 3
                                }} value={additionalScreensAmount} onInput={(event) => setAdditionalScreensAmount(event.target.value)}/>
                                {additionalScreensPricesList ? (additionalScreensPricesList.length > 0 ? additionalScreensPricesList.map(asp => {
                                    if(asp.recurring.interval == customerPlan.price.recurring.interval){
                                        return (
                                            <Button onClick={() => changeScreensQuantity(asp)} variant="outlined" sx={{mr: 2}} key={asp.id}>{parsePrice(asp.unit_amount)} {asp.currency.toUpperCase()} / screen / {asp.recurring.interval}</Button>
                                        )
                                    }
                                }) : (
                                    <Typography variant='body1'>No prices available</Typography>
                                )) : (
                                    <Typography variant='body1'>No prices available</Typography>
                                )}
                                {additionalScreensPricesList ? (
                                    <>
                                        <Typography variant='subtitle1'>Price / screen, / {additionalScreensPricesList.find(asp => asp.recurring.interval == customerPlan.price.recurring.interval).recurring.interval}: {parsePrice(additionalScreensPricesList.find(asp => asp.recurring.interval == customerPlan.price.recurring.interval).unit_amount)}</Typography>
                                        <Typography variant='subtitle1'>Total {parsePrice(additionalScreensPricesList.find(asp => asp.recurring.interval == customerPlan.price.recurring.interval).unit_amount*Number(screensToIncrease))}</Typography>
                                        <Button variant="contained" onClick={() => changeScreensQuantity(additionalScreensPricesList.find(asp => asp.recurring.interval == customerPlan.price.recurring.interval))}>Buy</Button>
                                    </>
                                ) : (
                                    <Typography>No price</Typography>
                                )}
                                <Box>
                                    <Button onClick={() => setEditScreensOpen(false)} sx={{mt: 2}}>Cancel</Button>
                                </Box>
                            </Box>
                        )}
                        {screensModifyMode === 'increase' && (
                            <Box>
                                <Typography variant='body1'>Enter the number of screens to add</Typography>
                                <TextField variant='outlined' type="number" label="Screens to add" fullWidth autoComplete="off" sx={{
                                    my: 3
                                }} value={screensToIncrease} onInput={(event) => setScreensToIncrease(event.target.value)}/>
                                <Box sx={{
                                    display: 'flex',
                                    gap: '10px'
                                }}>
                                    <Button endIcon={<AddIcon />} variant='contained' onClick={() => setScreensToIncrease(`${Number(screensToIncrease)+1}`)}>1</Button>
                                    <Button endIcon={<AddIcon />} variant='contained' onClick={() => setScreensToIncrease(`${Number(screensToIncrease)+5}`)}>5</Button>
                                    <Button endIcon={<AddIcon />} variant='contained' onClick={() => setScreensToIncrease(`${Number(screensToIncrease)+10}`)}>10</Button>
                                </Box>
                                {additionalScreensPricesList ? (
                                    <>
                                        <Typography variant='subtitle1'>Price per screen / {additionalScreensPricesList.find(asp => asp.recurring.interval == customerPlan.price.recurring.interval).recurring.interval}: {parsePrice(additionalScreensPricesList.find(asp => asp.recurring.interval == customerPlan.price.recurring.interval).unit_amount)}</Typography>
                                        <Typography variant='subtitle1'>{parsePrice(additionalScreensPricesList.find(asp => asp.recurring.interval == customerPlan.price.recurring.interval).unit_amount*Number(screensToIncrease))}</Typography>
                                        <Button variant="contained">Buy</Button>
                                    </>
                                ) : (
                                    <Typography>No price</Typography>
                                )}
                                <Box>
                                    <Button onClick={() => setEditScreensOpen(false)} sx={{mt: 2}}>Cancel</Button>
                                </Box>
                            </Box>
                        )}
                    </Collapse> */}
                    </Card> 
                </Box>
            </Grid>
            </Grid>
            </Box>
        </React.Fragment>
    );
}