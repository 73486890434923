import React from "react";
import { Typography, Button, Box, Card} from "@mui/material";
import checkedSuccsessIcon from '../../../../icons/untitled-ui/icons/checked.png'
import { useNavigate } from "react-router-dom";

const ConfirmTab = ({ user }) => {

  const navigate = useNavigate()

  return (
    <>
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: '20px',
            textAlign: 'center',
            mx: 'auto',
            width: '100%'
        }}>
            <Card sx={{maxWidth: '500px', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '20px', textAlign: 'center', p: 3}} variant="outlined">
                <img src={checkedSuccsessIcon} alt="Success" style={{
                    width: '100px',
                    height: '100px'
                }}/>
                <Typography variant="h3">Success</Typography>
                <Typography variant="h5">Your order has been processed.</Typography>
                <Typography variant="body1">After a successful transaction, we will send an email notification with the details of your purchase</Typography>
                <Button variant="contained" onClick={() => {
                    window.location.replace(`/user-details/${user._id}#invoices`)
                    window.location.reload()
                }}>My invoices</Button>
                <Button variant="contained" onClick={() => {
                    window.location.replace(`/user-details/${user._id}#mysubscriptions`)
                    window.location.reload()
                }}>Return to my subscriptions</Button>
            </Card>
        </Box>
    </>
  );
};

export default ConfirmTab;
