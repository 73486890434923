import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { Box } from '@mui/system';

export default function AddProductDialog({addProductModalOpen, handleClose, newProductName,
    setNewProductName,
    newProductDescription,
    setNewProductDescription,
    newProductCurrency,
    setNewProductCurrency,
    newProductPrice,
    setNewProductPrice,
    newProductBillingInterval,
    setNewProductBillingInterval,
    handleProductAdd
  }) {
  return (
    <React.Fragment>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open form dialog
      </Button> */}
      <Dialog
        open={addProductModalOpen}
        onClose={handleClose}
      >
        <DialogTitle>Add new product</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Product can have multiple prices and it can be attached to plan
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="productName"
            name="productName"
            label="Product name"
            type="text"
            fullWidth
            variant="standard"
            value={newProductName}
            onInput={(e) => setNewProductName(e.target.value)}
          />
          <TextField
            autoFocus
            required
            margin="dense"
            id="productDescription"
            name="productDescription"
            label="Product short description"
            type="text"
            fullWidth
            variant="standard"
            value={newProductDescription}
            onInput={(e) => setNewProductDescription(e.target.value)}
          />
          <Typography variant="body1" textAlign="center" sx={{
            mt: 5
          }}>Default billing properties</Typography>
            <TextField
                autoFocus
                required
                fullWidth
                id="price"
                name="price"
                label="Price (cents)"
                type="number"
                variant="standard"
                value={newProductPrice}
                onInput={(e) => setNewProductPrice(e.target.value)}
            />
          <FormControl fullWidth sx={{
            mt: 2
          }}>
            <InputLabel id="currencySelectLabel">Currency</InputLabel>
                <Select
                    labelId="currencySelectLabel"
                    id="currencySelect"
                    value={newProductCurrency}
                    label="Age"
                    required
                    onChange={(e) => setNewProductCurrency(e.target.value)}
                >
                    <MenuItem value="usd">USD</MenuItem>
                    <MenuItem value="eur">EUR</MenuItem>
                </Select>
          </FormControl>
          <FormControl fullWidth sx={{
            mt: 2
          }}>
            <InputLabel id="billingIntervalLabel">Billing interval</InputLabel>
                <Select
                    labelId="billingIntervalLabel"
                    id="billingInterval"
                    value={newProductBillingInterval}
                    label="Age"
                    required
                    onChange={(e) => setNewProductBillingInterval(e.target.value)}
                >
                    <MenuItem value="day">Day</MenuItem>
                    <MenuItem value="month">Month</MenuItem>
                    <MenuItem value="week">Week</MenuItem>
                    <MenuItem value="year">Year</MenuItem>
                </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" onClick={handleProductAdd}>Add</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}