//src/views/files/components/EditScreen/types.js
import { getRandomColor } from "./utlis";

// ---- ENUMS ----
export const resolutionCounters = {
    PLUS_HD: 1920/1080,
    HD: 1280/720,
}

export const Orientation = {
    LANDSCAPE: 'Landscape',
    PORTRAIT: 'Portrait',
  };

export const ContentType = {
    ASSET: 'Object',
    PLAYLIST: 'Playlist',
    // SCHEDULE: 'Schedule',
    // NONE: ''
}

export const FitScreen = {
    FIT: 'Fit',
    NONE: 'None',
    ZOOM: 'Zoom',
    STRETCH: 'Stretch'
}

export const RotationTypes = {
    LANDSCAPE: 'Landscape',
    ROTATE_90: 'Rotate 90 degrees',
    ROTATE_180: 'Rotate 180 degrees',
    ROTATE_270: 'Rotate 270 degrees',
}

// SETTING ENUMS
export const UnitTypes = {
    PERCENTAGE: "%",
    PIXELS: "Pixels",
}

export const ResolutionTypes = {
    FOUR_K: "2160p - 4K (3860 x 2160)",
    TWO_K: "1140p - 2K (2560 x 1440)",
    FULL_HD: "1080p - FHD (1920 x 1080)",
    HD: "720p - HD (1280 x 720)",
}

export const BackgroundMusicTypes = {
    NONE: "None",
    TOP_LEFT: "Top Left",
    TOP_RIGHT: "Top Right",
    BOTTOM_LEFT: "Bottom Left",
    BOTTOM_RIGHT: "Bottom Right",
}

export const AudioZoneTypes = {
    ALL: "All",
    TOP_LEFT: "Top Left",
    TOP_RIGHT: "Top Right",
    BOTTOM_LEFT: "Bottom Left",
    BOTTOM_RIGHT: "Bottom Right",
}

export const PrimaryZoneTypes = {
    NONE: "None",
    TOP_LEFT: "Top Left",
    TOP_RIGHT: "Top Right",
    BOTTOM_LEFT: "Bottom Left",
    BOTTOM_RIGHT: "Bottom Right",
}


// COLORS LIST
// export const COLORS = ['#FFC0CB', '#FFB6C1', '#FF69B4', '#FF1493', '#F08080', '#FA8072', '#E9967A', '#F0E68C', '#E6E6FA', '#D8BFD8', '#DDA0DD', '#EE82EE', '#DA70D6', '#C71585', '#C0C0C0', '#B0E0E6', '#ADD8E6', '#BFEFFF', '#87CEFA', '#LightSteelBlue', '#4682B4', '#5F9EA0', '#20B2AA', '#00FA9A', '#00FF7F', '#32CD32', '#98FB98', '#90EE90', '#9ACD32', '#A9A9A9', '#808080', '#696969', '#A0522D', '#800000', '#483D8B', '#2F4F4F', '#708090', '#778899', '#B22222', '#CD5C5C', '#F4A460', '#D2B48C', '#D2691E', '#8B4513', '#A0522D', '#800000', '#B22222', '#FF6347', '#FF7F50', '#FF8C00', '#FFA07A', '#FF4500', '#FFD700', '#DAA520', '#B8860B', '#F4A460', '#D2B48C', '#FF6347', '#FF7F50', '#FF8C00', '#FFA07A', '#FFD700', '#DAA520', '#B8860B', '#D2691E', '#8B4513', '#A0522D', '#800000', '#E6E6FA', '#D8BFD8', '#DDA0DD', '#EE82EE', '#DA70D6', '#C71585', '#C0C0C0'];
export const COLORS = [
    '#FF0000', // Czerwony
    '#00FF00', // Zielony
    '#0000FF', // Niebieski
    '#FFFF00', // Żółty
    '#FF00FF', // Magenta
    '#00FFFF', // Cyjan
    '#FF7F00', // Pomarańczowy
    '#7F00FF', // Fioletowy
    '#007FFF', // Jasnoniebieski
    '#FF007F', // Różowy
    '#7FFF00', // Jasnozielony
    '#007F00', // Zielony ciemny
    '#7F0000', // Brązowy
    '#7F7F00', // Oliwkowy
    '#007F7F', // Turkusowy
    '#00007F', // Granatowy
    '#7F007F', // Purpurowy
];

export const temporaryScreen = {
    MIN_5: "for 5 minutes",
    MIN_10: "for 10 minutes",
    MIN_15: "for 15 minutes",
    MIN_30: "for 30 minutes",
    MIN_45: "for 45 minutes",
    MIN_60: "for 1 hour",
}

export const scheduleSettings = {
    LAST_PLAYED: "The last played content",
    STOP: "Stop playing",
}

export const SCREEN_WARIANTS  = [
    {
        "text": "CUSTOM",
        "orientation": Orientation.LANDSCAPE,
        "rectangles":[
            {
                startX: 0,
                startY: 0,
                w_factor: 1,
                h_factor: 1,
                fill: getRandomColor(),
            },
        ]
    },
    {
        "text": "4 SCREENS + BAR",
        "orientation": Orientation.LANDSCAPE,
        "rectangles":[
            {
                startX: 0,
                startY: 0,
                w_factor: 0.5,
                h_factor: 0.444,
                fill: getRandomColor(),
            },
            {
                startX: 0.5,
                startY: 0,
                w_factor: 1 - 0.5,
                h_factor: 0.444,
                fill: getRandomColor(),
            },
            {
                startX: 0,
                startY: 0.444,
                w_factor: 0.5,
                h_factor: 0.444,
                fill: getRandomColor(),
            },
            {
                startX: 0.5,
                startY: 0.444,
                w_factor: 1 - 0.5,
                h_factor: 0.444,
                fill: getRandomColor(),
            },
            {
                startX: 0,
                startY: 2* 0.444,
                w_factor: 1,
                h_factor: 1 - 2* 0.444,
                fill: getRandomColor(),
            },
        ]
    },
    {
        "text": "4 SCREENS",
        "orientation": Orientation.LANDSCAPE,
        "rectangles":[
            {
                startX: 0,
                startY: 0,
                w_factor: 0.5,
                h_factor: 0.5,
                fill: getRandomColor(),
            },
            {
                startX: 0.5,
                startY: 0,
                w_factor: 1 - 0.5,
                h_factor: 0.5,
                fill: getRandomColor(),
            },
            {
                startX: 0,
                startY: 0.5,
                w_factor: 0.5,
                h_factor: 0.5,
                fill: getRandomColor(),
            },
            {
                startX: 0.5,
                startY: 0.5,
                w_factor: 1 - 0.5,
                h_factor: 0.5,
                fill: getRandomColor(),
            },
        ]
    },
    {
        "text": "2 SCREENS + BAR",
        "orientation": Orientation.LANDSCAPE,
        "rectangles":[
            {
                startX: 0,
                startY: 0,
                w_factor: 1,
                h_factor: 1 - 0.889,
                fill: getRandomColor(),
            },
            {
                startX: 0,
                startY: 1 - 0.889,
                w_factor: 0.719,
                h_factor: 0.889,
                fill: getRandomColor(),
            },
            {
                startX: 0.719,
                startY: 1 - 0.889,
                w_factor: 1 - 0.719,
                h_factor: 0.889,
                fill: getRandomColor(),
            },
        ]
    },
    {
        "text": "3 SCREENS",
        "orientation": Orientation.LANDSCAPE,
        "rectangles":[
            {
                startX: 0,
                startY: 0,
                w_factor: 0.719,
                h_factor: 1,
                fill: getRandomColor(),
            },
            {
                startX: 0.719,
                startY: 0,
                w_factor: 1 - 0.719,
                h_factor: 0.5,
                fill: getRandomColor(),
            },
            {
                startX: 0.719,
                startY: 1 - 0.5,
                w_factor: 1 - 0.719,
                h_factor: 1 - 0.5,
                fill: getRandomColor(),
            }
        ]
    },
    {
        "text": "1 SCREEN + BAR",
        "orientation": Orientation.LANDSCAPE,
        "rectangles":[
            {
                startX: 0,
                startY: 0,
                w_factor: 1,
                h_factor: 0.889,
                fill: getRandomColor(),
            },
            {
                startX: 0,
                startY: 0.889,
                w_factor: 1,
                h_factor: 1 - 0.889,
                fill: getRandomColor(),
            }
        ]
    },
    {
        "text": "2 SCREENS + BAR",
        "orientation": Orientation.LANDSCAPE,
        "rectangles":[
            {
                startX: 0,
                startY: 0,
                w_factor: 0.719,
                h_factor: 0.889,
                fill: getRandomColor(),
            },
            {
                startX: 0.719,
                startY: 0,
                w_factor: 1 - 0.719,
                h_factor: 0.889,
                fill: getRandomColor(),
            },
            {
                startX: 0,
                startY: 0.889,
                w_factor: 1,
                h_factor: 1 - 0.889,
                fill: getRandomColor(),
            }
        ]
    },
    {
        "text": "2 SCREENS",
        "orientation": Orientation.LANDSCAPE,
        "rectangles":[
            {
                startX: 0,
                startY: 0,
                w_factor: 0.719,
                h_factor: 1,
                fill: getRandomColor(),
            },
            {
                startX: 0.719,
                startY: 0,
                w_factor: 1 - 0.719,
                h_factor: 1,
                fill: getRandomColor(),
            },
        ]
    },
    {
        "text": "1 SCREEN + BAR",
        "orientation": Orientation.PORTRAIT,
        "rectangles":[
            {
                startX: 0,
                startY: 0,
                w_factor: 1,
                h_factor: 1 - 0.111,
                fill: getRandomColor(),
            },
            {
                startX: 0,
                startY: 1 - 0.111,
                w_factor: 1,
                h_factor: 0.111,
                fill: getRandomColor(),
            }
        ]
    },
    {
        "text": "2 SCREENS + BAR",
        "orientation": Orientation.PORTRAIT,
        "rectangles":[
            {
                startX: 0,
                startY: 0,
                w_factor: 1,
                h_factor: 0.62,
                fill: getRandomColor(),
            },
            {
                startX: 0,
                startY: 0.62,
                w_factor: 1,
                h_factor: 0.32,
                fill: getRandomColor(),
            },
            {
                startX: 0,
                startY: 0.62 + 0.32,
                w_factor: 1,
                h_factor: 1 - 0.62 + 0.32,
                fill: getRandomColor(),
            }
        ]
    },
    {
        "text": "3 SCREENS",
        "orientation": Orientation.PORTRAIT,
        "rectangles":[
            {
                startX: 0,
                startY: 0,
                w_factor: 1,
                h_factor: 0.683,
                fill: getRandomColor(),
            },
            {
                startX: 0,
                startY: 0.683,
                w_factor: 0.5,
                h_factor: 1 - 0.683,
                fill: getRandomColor(),
            },
            {
                startX: 0.5,
                startY: 0.683,
                w_factor: 1 - 0.5,
                h_factor: 1 - 0.683,
                fill: getRandomColor(),
            },
        ]
    },

    {
        "text": "2 SCREENS",
        "orientation": Orientation.PORTRAIT,
        "rectangles":[
            {
                startX: 0,
                startY: 0,
                w_factor: 1,
                h_factor: 0.683,
                fill: getRandomColor(),
            },
            {
                startX: 0,
                startY: 0.683,
                w_factor: 1,
                h_factor: 1 - 0.683,
                fill: getRandomColor(),
            },
        ]
    },  
]