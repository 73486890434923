import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { Box } from '@mui/system';

export default function AddPriceDialog({addPriceModalOpen, handleClose,
    newPriceCurrency,
    setNewPriceCurrency,
    newPrice,
    setNewPrice,
    newPriceBillingInterval,
    setNewPriceBillingInterval,
    handlePriceAdd,
    currentAddPriceProduct
  }) {
  return (
    <React.Fragment>
      <Dialog
        open={addPriceModalOpen}
        onClose={handleClose}
      >
        <DialogTitle>Add new price</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Price will be attached to product {'('+currentAddPriceProduct?.name+')'}
          </DialogContentText>
            <TextField
                autoFocus
                required
                fullWidth
                id="price"
                name="price"
                label="Price (cents)"
                type="number"
                variant="standard"
                value={newPrice}
                onInput={(e) => setNewPrice(e.target.value)}
            />
          <FormControl fullWidth sx={{
            mt: 2
          }}>
            <InputLabel id="currencySelectLabel">Currency</InputLabel>
                <Select
                    labelId="currencySelectLabel"
                    id="currencySelect"
                    value={newPriceCurrency}
                    label="Age"
                    required
                    onChange={(e) => setNewPriceCurrency(e.target.value)}
                >
                    <MenuItem value="usd">USD</MenuItem>
                    <MenuItem value="eur">EUR</MenuItem>
                </Select>
          </FormControl>
          <FormControl fullWidth sx={{
            mt: 2
          }}>
            <InputLabel id="billingIntervalLabel">Billing interval</InputLabel>
                <Select
                    labelId="billingIntervalLabel"
                    id="billingInterval"
                    value={newPriceBillingInterval}
                    label="Age"
                    required
                    onChange={(e) => setNewPriceBillingInterval(e.target.value)}
                >
                    {/* <MenuItem value="day">Day</MenuItem> */}
                    <MenuItem value="month">Month</MenuItem>
                    {/* <MenuItem value="week">Week</MenuItem> */}
                    <MenuItem value="year">Year</MenuItem>
                </Select>
          </FormControl>
          <Typography variant='caption' textAlign="center" sx={{
            display: 'block',
            mt: 3
          }}>Changes will be saved within a minute</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button type="submit" onClick={handlePriceAdd}>Add</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}