import React, { useState, useEffect } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Divider,
  Checkbox,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import { FaYoutube } from "react-icons/fa";
import { useSettings } from "src/hooks/use-settings";
import { addWidget, editContent } from "src/redux/actions/Content";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

// Component for adding or editing a YouTube playlist widget
const DialogBoxYoutubePlaylist = ({
  object,
  isNew,
  isOpen,
  onClose,
  isIcon,
}) => {
  const { t } = useTranslation();

  const settings = useSettings();
  const dispatch = useDispatch();

  // Get user and current folder from Redux store
  const { user } = useSelector((state) => state.User, shallowEqual);
  const { currentFolder } = useSelector(
    (state) => state.FileFolder,
    shallowEqual
  );

  // State for YouTube playlist configuration
  const [ytConfig, setYtConfig] = useState({
    name: "",
    url: "",
    caption: false,
    shuffle: false,
  });
  const [savedYtConfig, setSavedYtConfig] = useState({});
  const [isSaved, setIsSaved] = useState(false);
  const [savedId, setSavedId] = useState("");

  // Effect to initialize or reset state when dialog opens
  useEffect(() => {
    if (isOpen) {
      if (isNew) {
        resetConfig();
      } else if (object) {
        initializeConfig(object);
      }
    }
  }, [isOpen, isNew, object]);

  // Function to reset configuration
  const resetConfig = () => {
    setYtConfig({ name: "", url: "", caption: false, shuffle: false });
    setSavedId("");
    setIsSaved(false);
  };

  // Function to initialize configuration with existing object
  const initializeConfig = (obj) => {
    const config = {
      name: obj.name,
      url: obj.widget?.url,
      caption: obj.widget?.caption,
      shuffle: obj.widget?.shuffle,
    };
    setYtConfig(config);
    setSavedYtConfig(config);
    setIsSaved(true);
    setSavedId(obj.id);
  };

  // Function to handle changes in the configuration
  const handleChangeYtConfig = (key, value) => {
    setYtConfig((prev) => ({ ...prev, [key]: value }));
  };

  // Function to convert YouTube link to playlist URL
  const convertLink = (link) => {
    try {
      // Normalize the link by ensuring it starts with https://
      const normalizedLink = link.startsWith("http") ? link : `https://${link}`;
      const url = new URL(normalizedLink);

      // Check if it's a YouTube domain
      if (
        !url.hostname.includes("youtube.com") &&
        !url.hostname.includes("youtu.be")
      ) {
        console.log(`Not a YouTube link: ${link}`);
        return url.toString();
      }

      let playlistId = url.searchParams.get("list");
      const videoId =
        url.searchParams.get("v") || url.pathname.split("/").pop();

      // If it's a youtu.be link, the video ID is in the pathname
      if (url.hostname === "youtu.be") {
        url.hostname = "www.youtube.com";
        url.pathname = "/watch";
        url.searchParams.set("v", videoId);
      }

      // If we have a playlist ID, prioritize it
      if (playlistId) {
        url.pathname = "/playlist";
        url.searchParams.delete("v");
      } else if (videoId) {
        // If no playlist, but we have a video ID, use the watch endpoint
        url.pathname = "/watch";
        url.searchParams.set("v", videoId);
      } else {
        console.log(`No valid YouTube video or playlist ID found: ${link}`);
        return url.toString();
      }

      // Preserve all other original parameters
      return url.toString();
    } catch (error) {
      console.error(`Error processing link: ${link}`);
      console.error(error);
      return link; // Return the original link if there's an error
    }
  };

  // Function to check if configurations are different
  const areConfigsDifferent = (config1, config2) => {
    return JSON.stringify(config1) !== JSON.stringify(config2);
  };

  // Function to check if save is possible
  const canSave = () => {
    return (
      ytConfig.name &&
      ytConfig.url &&
      (!isSaved || areConfigsDifferent(ytConfig, savedYtConfig))
    );
  };

  // Function to handle save action
  const handleSave = async () => {
    try {
      const widgetData = {
        name: ytConfig.name,
        type: "widget",
        widgetCat: "yt_playlist",
        url: convertLink(ytConfig.url),
        caption: ytConfig.caption,
        shuffle: ytConfig.shuffle,
        widgetIcon: isIcon,
        user: user.isMember ? user?.user._id : user._id,
        folderId: currentFolder,
      };

      let action =
        isSaved && savedId
          ? editContent({ object: widgetData, id: savedId })
          : addWidget(widgetData);

      const content = await dispatch(action);

      if (content && content.id) {
        setSavedId(content.id);
        setSavedYtConfig(ytConfig);
        setIsSaved(true);
        toast.success("YouTube playlist saved successfully!");
      }
    } catch (error) {
      toast.error(error.message || "Failed to save YouTube playlist");
    }
  };

  return (
    <Dialog fullWidth open={isOpen} onClose={() => onClose(false)}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        backgroundColor="#1c2536"
        width="100%"
        sx={{ padding: "15px" }}
      >
        <FaYoutube
          size={80}
          style={{
            color: settings.paletteMode === "light" ? "#4c525d" : "white",
          }}
        />
        <Typography sx={{ fontWeight: "bold", fontSize: "30px" }}>
          {t("views.DialogBoxYoutubePlaylist.youtube_playlist_title")}
        </Typography>
      </Box>
      <DialogContent>
        <Stack
          direction="column"
          sx={{ gap: "15px", justifyContent: "center" }}
        >
          <TextField
            label={`${t("views.DialogBoxYoutubePlaylist.name_label")} *`}
            variant="outlined"
            value={ytConfig.name}
            onChange={(e) => handleChangeYtConfig("name", e.target.value)}
            fullWidth
          />
          <TextField
            label={`${t("views.DialogBoxYoutubePlaylist.url_label")} *`}
            variant="outlined"
            value={ytConfig.url}
            onChange={(e) => handleChangeYtConfig("url", e.target.value)}
            fullWidth
          />
          <Stack
            direction="row"
            sx={{ alignItems: "center", marginLeft: "2px" }}
          >
            <Typography>
              {t("views.DialogBoxYoutubePlaylist.caption_label")}
            </Typography>
            <Checkbox
              checked={ytConfig.caption}
              onChange={(e) =>
                handleChangeYtConfig("caption", e.target.checked)
              }
            />
          </Stack>
          <Stack
            direction="row"
            sx={{ alignItems: "center", marginLeft: "2px" }}
          >
            <Typography>
              {t("views.DialogBoxYoutubePlaylist.shuffle_label")}
            </Typography>
            <Checkbox
              checked={ytConfig.shuffle}
              onChange={(e) =>
                handleChangeYtConfig("shuffle", e.target.checked)
              }
            />
          </Stack>
        </Stack>
      </DialogContent>
      <Divider sx={{ width: "100%" }} />
      <DialogActions
        sx={{
          backgroundColor: "#1A2432",
          width: "100%",
          padding: "8px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Tooltip
          title={t("views.DialogBoxYoutubePlaylist.preview_tooltip")}
          arrow
        >
          <span>
            <Button
              disabled={!isSaved}
              onClick={() => window.open(ytConfig.url, "_blank")}
              variant="contained"
            >
              {t("views.DialogBoxYoutubePlaylist.preview_button")}
            </Button>
          </span>
        </Tooltip>
        <Stack direction="row" sx={{ alignItems: "center", gap: "10px" }}>
          <Button onClick={() => onClose(false)} variant="outlined">
            {t("views.DialogBoxYoutubePlaylist.close_button")}
          </Button>
          <Tooltip
            title={t("views.DialogBoxYoutubePlaylist.save_tooltip")}
            arrow
          >
            <span>
              <Button
                disabled={!canSave()}
                variant="contained"
                onClick={handleSave}
              >
                {t("views.DialogBoxYoutubePlaylist.save_button")}
              </Button>
            </span>
          </Tooltip>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default DialogBoxYoutubePlaylist;
