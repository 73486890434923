import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { Table, TableBody, TableCell, TableRow, Checkbox, Typography, Button, Grid, Divider, Paper, List, ListItem, ListItemText, TableHead, Card, ListItemIcon, TableContainer } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { useTheme } from "@mui/material/styles";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { parsePrice } from "src/utils/parse-price";
import { Box } from "@mui/system";
import PersonIcon from '@mui/icons-material/Person';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

const ChangePlanCheckout = ({ checkoutPreviousData, checkoutData, handleSubmit, handleCancel }) => {

  return (
    <>
        <Box sx={{overflow: 'auto'}}>
            <Box sx={{minWidth: '1000px', pb: 2}}>
            <Grid container mx="auto" spacing={2} maxWidth={1000} mt={3} sx={{
            alignItems: 'stretch'
        }} width="100%">
            <Grid item xs={6} md={6} xl={6}>
                <Box>
                    <Typography color="textSecondary" sx={{mb:1}}>Summary change:</Typography>
                    <Typography variant="h5"><span>{checkoutPreviousData.orderName} &rarr; {checkoutData.orderName}</span></Typography>
                </Box>
                <List>
                    <ListItem>
                        <ListItemIcon>
                            <CalendarMonthIcon/>
                        </ListItemIcon>
                        <ListItemText primary={<span>{checkoutPreviousData.interval} &rarr; {checkoutData.interval}</span>} secondary="Billing interval" />
                    </ListItem>
                    {/* <ListItem>
                        <ListItemIcon>
                            <AccessTimeIcon/>
                        </ListItemIcon>
                        <ListItemText primary={checkoutData.realizationTime} secondary="Realization time" />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <SubscriptionsIcon/>
                        </ListItemIcon>
                        <ListItemText primary={checkoutData.type} secondary="Type" />
                    </ListItem> */}
                </List>
                {/* <Card sx={{p: 2}} variant="outlined">
                    <Typography variant="h6" sx={{mt: 2}}>Personal data</Typography>
                    <Grid container mx="auto" maxWidth={1800} mt={3} sx={{
                        alignItems: 'stretch'
                    }}>
                        <Grid item xs={6}>
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <PersonIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={checkoutData.customerName}
                                        secondary="Name"
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <LocationCityIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={`${checkoutData.customerCountry} ${checkoutData.customerCity} ${checkoutData.customerPostalCode}`}
                                        secondary="City"
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={6}>
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <LocalPhoneIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={checkoutData.customerPhone}
                                        secondary="Phone number"
                                    />
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <EmailIcon />
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={checkoutData.customerEmail}
                                        secondary="E-mail address"
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </Card> */}
                <Paper elevation={6} />
            </Grid>
            <Grid item xs={6} md={6} xl={6}>
                <Card sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%',
                    p:2
                }} variant="outlined">
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Product</TableCell>
                                    <TableCell>Price</TableCell>
                                    {/* <TableCell>Currency</TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th">1</TableCell>
                                    <TableCell><span>{checkoutPreviousData.orderName} &rarr; {checkoutData.orderName}</span></TableCell>
                                    <TableCell><span>{checkoutPreviousData.price} &rarr; {checkoutData.price}</span></TableCell>
                                    {/* <TableCell>{checkoutData.currency}</TableCell> */}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{
                        mt: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <CreditCardIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={checkoutData.cardDetails}
                                />
                            </ListItem>
                        </List>
                        <Box sx={{
                            display: 'flex',
                            gap: '20px',
                            width: '100%',
                            justifyContent: 'space-evenly'
                        }}>
                            <Button variant="contained" onClick={handleSubmit}>Subscribe</Button>
                            <Button variant="contained" color="error" onClick={handleCancel}>Cancel</Button>
                        </Box>
                    </Box>
                </Card>
            </Grid>
        </Grid>
            </Box>
        </Box>
        {/* <Typography variant='h4' sx={{my: 3, ml:1}}>Finish your order</Typography> */}
    </>
  );
};

export default ChangePlanCheckout;
