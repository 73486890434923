import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { Table, TableBody, TableCell, TableRow, Checkbox, Typography, Button, Grid, Divider, Paper, List, ListItem, ListItemText, TableHead, Card, ListItemIcon, TableContainer, FormGroup, FormControlLabel, Backdrop, CircularProgress } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { useTheme } from "@mui/material/styles";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { parsePrice } from "src/utils/parse-price";
import { Box } from "@mui/system";
import PersonIcon from '@mui/icons-material/Person';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import { AddressElement, CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { api } from "src/api/endpoints";
import toast from "react-hot-toast";

const PlanBuyCheckout = ({ checkoutData, handleCancel, user }) => {
  const elements = useElements()
  const stripe = useStripe()

  const [customerEmail, setCustomerEmail] = useState(checkoutData.customerEmail)
  const [stripeTermsCheckbox, setStripeTermsCheckbox] = useState(false)
  const [appTermsCheckbox, setAppTermsCheckbox] = useState(false)
  const [loadingBackdrop, setLoadingBackdrop] = useState(false)

  const handleBuyPlanAndCreateCustomerBillingAccount = async () => {
    if(appTermsCheckbox && stripeTermsCheckbox){
        if(customerEmail){
            const addressElement = elements.getElement('address')
            const {complete, value} = await addressElement.getValue()
            if(value.phone) {
                  if(complete){
                    setLoadingBackdrop(true)
                      try {
                            const {data, status} = await api.post('/payment/createCustomer', {
                                userId: user._id,
                                email: customerEmail,
                                name: value.name,
                                city: value.address.city,
                                country: value.address.country,
                                line1: value.address.line1,
                                line2: value.address.line2 ? value.address.line2 : '',
                                postalCode: value.address.postal_code,
                                state: value.address.state,
                                phone: value.phone
                            }) 

                            const cardElement = elements.getElement('card')
                
                            const result = await stripe.createPaymentMethod({
                                type: 'card',
                                card: cardElement
                            })
                
                            if (result.error) {
                                toast.error("Payment details incomplete")
                                setLoadingBackdrop(false)
                            } else {
                                try {
                                    const attachPMtoUserRes = await api.post('/payment/attachPMtoCustomer', {pmId: result.paymentMethod.id, customerId: data.newCustomer.id})
                                    const resp = await api.post('/payment/createPlanSubscription', {
                                        customerId: data.newCustomer.id,
                                        priceId: checkoutData.priceId
                                    })
                                    setTimeout(() => {
                                        window.location.replace(`/user-details/${user._id}#orderconfirm`)
                                        window.location.reload()
                                    }, 500)
                                }catch (err) {
                                    toast.error("Error while buying plan")
                                    setLoadingBackdrop(false)
                                }
                            }
                      } catch (error) {
                          toast.error("Error when adding a billing account")
                          setLoadingBackdrop(false)
                      }
                  }
            }else{
                toast.error('Phone number is required')      
            }
        }else{
          toast.error('Email address is required')
        }
    }else{
        toast.error("You must accept the terms of service")
    }
  }

  return (
    <>
        <Backdrop
        sx={(theme) => ({zIndex: theme.zIndex.drawer + 1 })}
        open={loadingBackdrop}
        onClick={() => setLoadingBackdrop(false)}
        >
        <CircularProgress/>
        </Backdrop>
        {/* <Typography variant='h4' sx={{my: 3, ml:1}}>Finish your order</Typography> */}
                <Box sx={{overflow: 'visible'}}>
                <Box sx={{pb: 2}}>
                <Grid container mx="auto" spacing={2} maxWidth={1400} mt={3} width="100%" sx={{
            alignItems: 'stretch'
        }}>
            <Grid item xs={12} md={12} xl={8}>
                <Box sx={{display: 'flex', alignItems: 'center', gap: '20px'}}>
                    <Box>
                        <Typography color="textSecondary" sx={{mb:1}}>Summary:</Typography>
                        <Typography variant="h5">{checkoutData.orderName}</Typography>
                    </Box>
                    <List>
                        <ListItem>
                            <ListItemIcon>
                                <CalendarMonthIcon/>
                            </ListItemIcon>
                            <ListItemText primary={checkoutData.interval} secondary="Billing interval" />
                        </ListItem>
                    </List>
                </Box>
                {/* <List>
                    <ListItem>
                        <ListItemIcon>
                            <CalendarMonthIcon/>
                        </ListItemIcon>
                        <ListItemText primary={checkoutData.interval} secondary="Billing interval" />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <AccessTimeIcon/>
                        </ListItemIcon>
                        <ListItemText primary={checkoutData.realizationTime} secondary="Realization time" />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <SubscriptionsIcon/>
                        </ListItemIcon>
                        <ListItemText primary={checkoutData.type} secondary="Type" />
                    </ListItem>
                </List> */}
                <Card sx={{p: 2, overflow:"visible", mt: 2}} variant="outlined">
                    <Typography variant="h6" sx={{mt: 2, py: 1, px: 2}}>Billing data</Typography>
                    <Grid container mx="auto" maxWidth={1800} mt={3} sx={{
                        alignItems: 'stretch'
                    }}>
                        <Grid item xs={12} md={6}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                py: 1,
                                px: 2
                            }}>
                                <Box sx={{mb: 2}}>
                                    <TextField variant="outlined" label="E-mail address" fullWidth color="primary" value={customerEmail} onInput={(e) => setCustomerEmail(e.target.value)}/>
                                </Box>
                                <AddressElement options={{mode: 'billing', fields: {phone: 'always'}, validation: {phone: {required: 'always'}}, autocomplete: {mode: 'google_maps_api', apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY}}}/>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6} p={2}>
                            <Box sx={{
                                backgroundColor: '#252525',
                                p: 1,
                                borderRadius: '8px'
                            }}>
                                <CardElement options={{style: {
                                    base: {
                                        color: '#fff',
                                        backgroundColor: '#252525',
                                        lineHeight: '30px'
                                    }
                                }}}/>
                            </Box>
                            <FormGroup sx={{mt: 3, ml: 2}}>
                                <FormControlLabel control={<Checkbox/>} label="By checking this box, you are agreeing to our terms of service." onChange={(e) => setAppTermsCheckbox(e.target.checked)} checked={appTermsCheckbox} />
                                <FormControlLabel control={<Checkbox/>} label="By checking this box, you are agreeing to Stripe terms of service." onChange={(e) => setStripeTermsCheckbox(e.target.checked)} checked={stripeTermsCheckbox} />
                            </FormGroup>
                            <Box sx={{
                                display: 'flex',
                                gap: '20px',
                                width: '100%',
                                justifyContent: 'space-evenly',
                                mt: 4
                            }}>
                                <Button variant="contained" onClick={handleBuyPlanAndCreateCustomerBillingAccount}>Buy</Button>
                                <Button variant="contained" color="error" onClick={handleCancel}>Cancel</Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Card>
                <Paper elevation={6} />
            </Grid>
            <Grid item xs={12} md={12} xl={4}>
                <Card sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%',
                    p:2
                }} variant="outlined">
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>#</TableCell>
                                    <TableCell>Product</TableCell>
                                    <TableCell>Price</TableCell>
                                    {/* <TableCell>Currency</TableCell> */}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell component="th">1</TableCell>
                                    <TableCell>{checkoutData.orderName}</TableCell>
                                    <TableCell>{checkoutData.price}</TableCell>
                                    {/* <TableCell>{checkoutData.currency}</TableCell> */}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box sx={{
                        mt: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center'
                    }}>
                    </Box>
                </Card>
            </Grid>
        </Grid>
                </Box>
                </Box>

    </>
  );
};

export default PlanBuyCheckout;
