import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { Table, TableBody, TableCell, TableRow, Checkbox } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { useTheme } from "@mui/material/styles";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    overflowX: "auto",
    maxWidth: "100%",
  },
  staticColumn: {
    position: "sticky",
    left: 0,
    zIndex: 1,
    border: 0,
    width: 250,
    flexShrink: 0,
    fontWeight: "bold",
    fontSize: "18px",
  },
}));

const FeatureManagerListTable = ({ featureGroups, featureSettings, plans, handlePlanFeatureChange, handlePlanFeatureValueChange }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState(null);

  return (
    <div className={classes.tableContainer}>
      <Table sx={{ minWidth: 700 }}>
        <TableBody>
          <TableRow>
            <TableCell className={classes.staticColumn} sx={{ backgroundColor: theme.palette.background.default }}>
              {t("views.featureManagerListTable.features")}
            </TableCell>
            {plans.length !== 0 &&
              plans.map((plan) => (
                <TableCell key={plan._id} align="center">
                  {plan.name}
                </TableCell>
              ))}
          </TableRow>
          {plans.length !== 0 &&
            plans[0].planFeatures.map((planFeature, featureIndex) => (
              <TableRow hover key={planFeature._id}>
                <TableCell className={classes.staticColumn} sx={{ backgroundColor: theme.palette.background.default }}>
                  {planFeature.name}
                </TableCell>
                {plans.map((plan, planIndex) => {
                  const value = plan.planFeatures[featureIndex].value;
                  const regex = new RegExp(plan.planFeatures[featureIndex].regex);
                  let error = false;

                  if (regex && value.toString().match(regex) === null) {
                    error = true;
                  }

                  return (
                    <TableCell key={plan._id} align="center">
                      {plan.planFeatures[featureIndex].inputType === "text" ? (
                        <TextField
                          error={error}
                          id="outlined-basic"
                          sx={{ width: 100 }}
                          placeholder={plan.planFeatures[featureIndex].placeholder}
                          value={value}
                          variant="standard"
                          InputProps={{
                            inputProps: {
                              style: { textAlign: "right" },
                              "aria-label": t("views.featureManagerListTable.featureValue", { feature: planFeature.name, plan: plan.name }),
                            },
                            endAdornment: <InputAdornment position="end">{plan.planFeatures[featureIndex].units}</InputAdornment>,
                          }}
                          onChange={(e) => handlePlanFeatureValueChange(plan.planFeatures[featureIndex]._id, plan._id, e.target.value)}
                        />
                      ) : (
                        <Checkbox
                          sx={{ padding: 0 }}
                          defaultChecked={plan.planFeatures[featureIndex].enabled}
                          onChange={(e) => handlePlanFeatureChange(plan.planFeatures[featureIndex]._id, plan._id, e.target.checked, regex)}
                          aria-label={t("views.featureManagerListTable.toggleFeature", { feature: planFeature.name, plan: plan.name })}
                        />
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default FeatureManagerListTable;
