import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { Table, TableBody, TableCell, TableRow, Checkbox, Typography, Button, TableHead } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { useTheme } from "@mui/material/styles";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { parsePrice } from "src/utils/parse-price";

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    overflowX: "auto",
    maxWidth: "100%",
  },
  staticColumn: {
    position: "sticky",
    left: 0,
    zIndex: 1,
    border: 0,
    width: 250,
    flexShrink: 0,
    fontWeight: "bold",
    fontSize: "18px",
  },
}));

const PlansFeaturesTable = ({ plans, billingType, showBuyButtons, handleBuyPlan, userPlan, prices, customerPaymentMethods }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();


  return (
    <div className={classes.tableContainer}>
      <Table sx={{ minWidth: 700, maxWidth: 800, mx: 'auto' }}>
        <TableHead>
          { showBuyButtons ? (
            <TableRow>
              <TableCell sx={{width: '250px', fontSize: '20px', fontWeight: '700' }}>
                Choose plan
              </TableCell>
              {plans.length !== 0 &&
                
                plans.filter(pi => !pi.hidden).map((plan) => (
                  <TableCell key={plan._id} align="center">
                    {plan.paid ? (userPlan?._id !== plan._id ? (!userPlan?.paid ? (
                      <Button variant="contained" onClick={() => handleBuyPlan(plan, 'create')}>Buy</Button>
                    ) : (
                      <Button variant="contained" onClick={() => handleBuyPlan(plan, 'edit')}>Choose</Button>
                    )) : (
                      <Button disabled>Current</Button>
                    ) ) : null} 
                  </TableCell>
                ))}
                {customerPaymentMethods?.length == 0 && (
                  <TableCell colSpan={3} align="center"><Typography color="warning.main" variant="caption">Cannot purchase a plan without a linked credit card</Typography></TableCell>
                )}
            </TableRow>
          ) : null }
          <TableRow>
            <TableCell sx={{width: '250px', fontSize: '20px', fontWeight: '700' }}>
              Feature name
            </TableCell>
            {plans.length !== 0 &&
              plans.filter(pi => !pi.hidden).map((plan) => (
                <TableCell key={plan._id} align="center">
                    <Typography variant="h6">
                        {plan.name}
                    </Typography>
                </TableCell>
              ))}
          </TableRow>
          <TableRow>
            <TableCell sx={{width: '250px', fontSize: '20px', fontWeight: '700' }}>
              {billingType == 'month' ? (
                "Monthly subscription"
              ) : (
                "Annual subscription"
              )}
            </TableCell>
            {plans.filter(pi => !pi.hidden).map((plan, planIndex) => {
                  return prices.length > 0 ? (
                    <TableCell key={plan._id} align="center">
                        {prices.find(p => p.product == plan.productId && p.recurring.interval == billingType) ? (
                          Math.floor(prices.find(p => p.product == plan.productId && p.recurring.interval == billingType)?.unit_amount/100)+' '+prices.find(p => p.product == plan.productId && p.recurring.interval == billingType)?.currency.toUpperCase()
                        ) : (
                          <Typography variant="caption">No price</Typography>
                        )}
                    </TableCell>
                  ) : null
            })}
          </TableRow>
          </TableHead>
          <TableBody>
          {plans.length !== 0 &&
            plans[0].planFeatures.map((planFeature, featureIndex) => (
              <TableRow key={planFeature._id}>
                <TableCell sx={{width: '250px', fontWeight: '400' }}>
                  {planFeature.name}
                </TableCell>
                {plans.filter(pi => !pi.hidden).map((plan, planIndex) => {
                  const value = plan.planFeatures[featureIndex].value;
                  const regex = new RegExp(plan.planFeatures[featureIndex].regex);
                  let error = false;

                  if (regex && value.toString().match(regex) === null) {
                    error = true;
                  }

                  return (
                    <TableCell key={plan._id} align="center">
                      {plan.planFeatures[featureIndex].enabled ? (
                        <CheckIcon color="success"/>
                      ) : (
                        <CloseIcon htmlColor="#DC3545"/>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default PlansFeaturesTable;
