import React from "react";
import Chart from "react-apexcharts";

function DonutChart({ series, labels, views, labelColor = "#A0AEC0", viewsColor = "#A0AEC0" }) {
  return (
    <Chart
      style={{
        fontFamily: "Open Sans !important",
      }}
      type="donut"
      series={series}
      options={{
        title: {
          text: views,
          style: {
            fontSize: 15,
            color: viewsColor,
          },
        },
        labels: labels,
        dataLabels: {
          enabled: true,
          style: {
            colors: ["white"],
          },
        },
        legend: {
          position: "left",
          show: false,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  showAlways: true,
                  show: true,
                  label: "Total",
                  color: labelColor,
                },
                value: {
                  fontSize: "22px",
                  show: true,
                  color: labelColor,
                },
              },
            },
          },
        },
        tooltip: {
          y: {
            formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
              return value;
            },
            title: {
              formatter: function (seriesName) {
                return seriesName;
              },
            },
          },
          theme: "dark",
          style: {
            fontSize: "12px",
            fontFamily: undefined,
          },
        },
      }}
    />
  );
}

export default DonutChart;
