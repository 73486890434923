import React, { useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { Button, Card, Typography, useTheme } from "@mui/material";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { getPeriodScreenStats, getScreenDataUsageStaticsPerDay, throwGetPeriodScreenStatsError } from "src/redux/actions/ScreenStats";
import { useSelector } from "react-redux";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ScreenDataUsageStatsChart from "./ScreenDataUsageStatsChart";
import { useTranslation } from "react-i18next";

function ScreenDataUsageStatsWidget({ mac }) {
    const { t } = useTranslation();
    const dispatch = useDispatch()
    const [value, setValue] = useState('1');
    const [day, setDay] = useState(dayjs(new Date()))
    const [dateFrom, setDateFrom] = useState(null);
    const [dateTo, setDateTo] = useState(null);

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const isDarkTheme = useTheme().palette.mode === 'dark';

    useEffect(() => {
        if(mac){
            dispatch(getScreenDataUsageStaticsPerDay({
                day: `${day.$y}-${day.$M+1}-${day.$D}`,
                screenMac: mac
            }))
        }
    }, [mac])

    function formatBytesOld(bytes, decimals = 2) {
        if (!+bytes) return '0 Bytes'
    
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['B', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
    
        const i = Math.floor(Math.log(bytes) / Math.log(k))
    
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    function formatBytes(bytes, decimals = 2) {
        if (!+bytes) return '0 Bytes'

        const dm = decimals < 0 ? 0 : decimals
    
        return `${parseFloat((bytes / 1073741824).toFixed(4))} GB`
    }

    const handleDayChange = (date) => {
        setDay(date)
        dispatch(getScreenDataUsageStaticsPerDay({
            day: `${date.$y}-${date.$M+1}-${date.$D}`,
            screenMac: mac
        }))
    }

    const { screenStatsDayError, dayScreenStat, periodScreenStats, periodScreenStatsError, periodScreenStatsErrorMsg } = useSelector(
        (state) => ({
            screenStatsDayError: state.ScreenStats.screenStatsError,
            dayScreenStat: state.ScreenStats.dayScreenStat,
            periodScreenStats: state.ScreenStats.periodScreenStats,
            periodScreenStatsError: state.ScreenStats.periodScreenStatsError,
            periodScreenStatsErrorMsg: state.ScreenStats.periodScreenStatsErrorMsg
        })
    );

    const handlePeriodSearch = () => {
        if(dateFrom && dateTo){
            const dateFromDate = new Date(`${dateFrom.$y}-${dateFrom.$M+1}-${dateFrom.$D}`)
            const dateToDate = new Date(`${dateTo.$y}-${dateTo.$M+1}-${dateTo.$D}`)
            const dateDiff = Math.round((new Date(dateToDate) - new Date(dateFromDate)) / (1000 * 60 * 60 * 24))
            if(dateDiff > 0){
                if(dateDiff < 100){
                    dispatch(getPeriodScreenStats({
                        screenMac: mac,
                        dateFrom: `${dateFrom.$y}-${dateFrom.$M+1}-${dateFrom.$D}`,
                        dateTo: `${dateTo.$y}-${dateTo.$M+1}-${dateTo.$D}`
                    }))
                }else{
                    dispatch(throwGetPeriodScreenStatsError(t("views.screenDetail.max100days")))
                }
            }else{
                dispatch(throwGetPeriodScreenStatsError(t("views.screenDetail.badTimePeriod")))
            }
        }else{
            dispatch(throwGetPeriodScreenStatsError(t("views.screenDetail.chooseTimeperiod")))
        }
    }

    return (
      <Box sx={{ width: '100%', typography: 'body1', marginTop: '20px' }}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="choose tab" variant="scrollable">
              <Tab label={t("views.screenDetail.daysStats")} value="1" />
              <Tab label={t("views.screenDetail.periodStats")} value="2" />
              <Tab label={t("views.screenDetail.rangeStats")} value="3" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <Box sx={{
                display:'flex',
                flexWrap: 'wrap',
                alignItems:'center',
                gap: '20px'
            }}>
                <Box sx={{maxWidth: '320px', overflow: 'auto'}}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateCalendar onChange={(date) => {handleDayChange(date)}} value={day}/>
                    </LocalizationProvider>
                </Box>
                {screenStatsDayError ? (
                    <>
                        <Card sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: isDarkTheme ? '#3c3c3c' : '#eeeeee',  
                            p: 3,
                            maxWidth: '300px',
                            width: '100%'
                        }}>
                            <CloudDownloadIcon sx={{fontSize: 100}}/>
                            <Typography variant="subtitle1" color="#00E396">{t("views.screenDetail.receivedLabel")}</Typography>
                            <Typography variant="h3">0 GB</Typography>
                        </Card>
                        <Card sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: isDarkTheme ? '#3c3c3c' : '#eeeeee',  
                            p: 3,
                            maxWidth: '300px',
                            width: '100%'
                        }}>
                            <CloudUploadIcon sx={{fontSize: 100}}/>
                            <Typography variant="subtitle1" color="#008FFB">{t("views.screenDetail.sentLabel")}</Typography>
                            <Typography variant="h3">0 GB</Typography>
                        </Card>
                    </>
                ) : (
                    <>
                        <Card sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: isDarkTheme ? '#3c3c3c' : '#eeeeee',  
                            p: 3,
                            maxWidth: '300px',
                            width: '100%'
                        }}>
                            <CloudDownloadIcon sx={{fontSize: 100}}/>
                            <Typography variant="subtitle1" color="#00E396">{t("views.screenDetail.receivedLabel")}</Typography>
                            <Typography variant="h3">{formatBytes(dayScreenStat?.totalReceivedBytes)}</Typography>
                        </Card>
                        <Card sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            backgroundColor: isDarkTheme ? '#3c3c3c' : '#eeeeee',  
                            p: 3,
                            maxWidth: '300px',
                            width: '100%'
                        }}>
                            <CloudUploadIcon sx={{fontSize: 100}}/>
                            <Typography variant="subtitle1" color="#008FFB">{t("views.screenDetail.sentLabel")}</Typography>
                            <Typography variant="h3">{formatBytes(dayScreenStat?.totalSentBytes)}</Typography>
                        </Card>
                    </>
                )}
            </Box>
          </TabPanel>
          <TabPanel value="2">
            <Typography variant="subtitle1">{t("views.screenDetail.choosePeriod")}</Typography>
            <Box sx={{
                display:'flex',
                alignItems: 'center',
                marginTop: '20px',
                gap: '10px',
                width: '100%',
                flexWrap: 'wrap'
            }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                    label={t("views.screenDetail.startDate")}
                    value={dateFrom}
                    onChange={(newDateFrom) => setDateFrom(newDateFrom)}
                    />
                </LocalizationProvider>
                <Typography variant="subtitle1">-</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                    label={t("views.screenDetail.endDate")}
                    value={dateTo}
                    onChange={(newDateTo) => setDateTo(newDateTo)}
                    />
                </LocalizationProvider>
                <Button onClick={handlePeriodSearch}>{t("views.screenDetail.search")}</Button>
            </Box>
            <Box>
                {periodScreenStatsError ? (
                    <Typography sx={{width: '100%', textAlign: 'center', marginTop: '30px'}}>{periodScreenStatsErrorMsg}</Typography>
                ) : (
                    <>
                        {periodScreenStats ? (
                            <>
                                <Typography sx={{width: '100%', marginTop: '30px'}}>{t("views.screenDetail.receivedSum")}: <b>{formatBytes(periodScreenStats.totalPeriodReceivedBytes)}</b></Typography>
                                <Typography sx={{width: '100%'}}>{t("views.screenDetail.sentSum")}: <b>{formatBytes(periodScreenStats.totalPeriodSentBytes)}</b></Typography>
                                <ScreenDataUsageStatsChart screenStats={periodScreenStats.screenStats} formatBytes={formatBytes}/>
                            </>
                        ) : null}
                    </>
                )}
            </Box>
          </TabPanel>
          <TabPanel value="3">TODO</TabPanel>
        </TabContext>
      </Box>
    );
}

export default ScreenDataUsageStatsWidget;
