import { Box, FormControl, InputLabel, Select, MenuItem, Button, Typography, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import { baseImg, resolveSrc } from "../../../api/endpoints";
const PlaylistOrAssetSelector = ({ playlists, setOpenSelectFiles, selectedType, setSelectedType, playlistId, setPlaylistId, selectedAsset, setSelectedAsset }) => {
  console.log(selectedAsset);
  const { t } = useTranslation();
  console.log("selectedAsset", selectedAsset);
  return (
    <>
      <Box>
        <FormControl variant="standard" sx={{ marginBottom: "10px" }} fullWidth>
          <InputLabel>{t("views.redesignScreens.typeRequired")}</InputLabel>
          <Select value={selectedType} onChange={(e) => setSelectedType(e.target.value)} label={t("views.redesignScreens.selectType")}>
            <MenuItem value="playlist">{t("views.redesignScreens.playlist")}</MenuItem>
            <MenuItem value="asset">{t("views.redesignScreens.asset")}</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box>
        {selectedType === "playlist" ? (
          <FormControl variant="standard" fullWidth>
            <InputLabel>{t("views.redesignScreens.selectPlaylist")}</InputLabel>
            <Select value={playlistId} onChange={(e) => setPlaylistId(e.target.value)} label={t("views.redesignScreens.selectPlaylist")}>
              {playlists.map((playlist) => (
                <MenuItem key={playlist._id} value={playlist._id}>
                  <Tooltip title={playlist.playlistName.length > 30 ? playlist.playlistName : ''}>
                    {playlist.playlistName.length > 30 ? `${playlist.playlistName.slice(0, 30)}...` : playlist.playlistName}
                  </Tooltip>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <>
            {selectedAsset && (
              <Box
                sx={{
                  mt: 2,
                  mb: 2,
                  mx: "auto",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 2,
                }}>
                {selectedAsset.widget?.widgetIcon ? (
                  <Box
                    sx={{
                      width: 50,
                      height: 50,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    <img src={selectedAsset.widget.widgetIcon} alt={selectedAsset.name} style={{ width: 50, height: 50, objectFit: "cover" }} />
                  </Box>
                ) : selectedAsset.type === "image" ? (
                  <Box
                    sx={{
                      width: 50,
                      height: 50,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    <img
                      src={resolveSrc(selectedAsset.url)}
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        maxHeight: "60px",
                        margin: "auto",
                        display: "block",
                      }}
                    />
                  </Box>
                ) : selectedAsset.type === "video" ? (
                  <Box
                    sx={{
                      width: 50,
                      height: 50,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    <video
                      src={resolveSrc(selectedAsset.url)}
                      style={{
                        width: "100%",
                        height: "auto",
                        maxHeight: "60px",
                        margin: "auto",
                        display: "block",
                        objectFit: "cover",
                      }}
                      controls={false}
                      autoPlay={false}
                      muted
                    />
                  </Box>
                ) : selectedAsset.type === "widget" && selectedAsset?.widget?.widgetCat === "twitch" ? (
                  <Box
                    sx={{
                      width: 50,
                      height: 50,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    <img
                      src={"/images/twitch_app.png"}
                      style={{
                        maxWidth: "100%",
                        height: "auto",
                        maxHeight: "60px",
                        margin: "auto",
                        display: "block",
                      }}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      width: 50,
                      height: 50,
                      bgcolor: "grey.300",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}>
                    <Typography variant="caption">No icon</Typography>
                  </Box>
                )}
                <Box>
                  <Typography variant="body2">
                    {t("views.redesignScreens.selectedAsset")}: {selectedAsset.name}
                  </Typography>
                  <Typography variant="caption" color="textSecondary">
                    {t("views.redesignScreens.type")}: {selectedAsset.widget.widgetCat || selectedAsset.type}
                  </Typography>
                </Box>
                <Button variant="outlined" onClick={() => setOpenSelectFiles(true)}>
                  {selectedAsset ? t("views.redesignScreens.changeAsset") : t("views.redesignScreens.selectAsset")}
                </Button>
              </Box>
            )}
            {selectedType === "asset" && !selectedAsset && (
              <Button variant="outlined" onClick={() => setOpenSelectFiles(true)} fullWidth>
                {selectedAsset ? t("views.redesignScreens.changeAsset") : t("views.redesignScreens.selectAsset")}
              </Button>
            )}
          </>
        )}
      </Box>
    </>
  );
};

export default PlaylistOrAssetSelector;
