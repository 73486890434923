import { makeStyles } from "@material-ui/core";
import {
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  DialogActions,
  Typography,
  Card,
  SvgIcon,
  IconButton,
  Box,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormControl,
  Tooltip,
} from "@mui/material";

import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Scrollbar } from "src/components/scrollbar";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { createFolder, setFolderId } from "../../redux/actions/FileFolder";
import { Link, useNavigate } from "react-router-dom";
import TableFolders from "./TableFolders";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";

import PlanFeatureComponent from "../planFeature/PlanFeatureComponent";

import { updateScreen } from "../../redux/actions/Screen";
//icons
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import AddToQueueIcon from "@mui/icons-material/AddToQueue";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import DotsHorizontalIcon from "@untitled-ui/icons-react/build/esm/DotsHorizontal";
import EditIcon from "@mui/icons-material/Edit";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import WifiIcon from '@mui/icons-material/Wifi';
import SettingsEthernetIcon from '@mui/icons-material/SettingsEthernet';
import { Switch } from "@mui/material";
import { NetworkIcon } from "../../icons/untitled-ui/duocolor/network";
import BlockIcon from '@mui/icons-material/Block';

import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';

const useStyles = makeStyles((theme) => {
  return {
    chipStyling: {
      "&.MuiChip-root": {
        borderRadius: "2px",
        border: 0,
        height: { xl: "40px", lg: "30px", md: "20px", sm: "20px", xs: "20px" },
        width: { xl: "110px", lg: "80px", md: "80px", sm: "50px", xs: "50px" },
      },
    },
    chipOnlineColor: {
      background: "#D3FFE0",
    },
    chipOfflineColor: {
      background: "#FFD3D3",
    },
    tableHeaderFonts: {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 600,
      fontSize: "16px",
      lineHeight: "22px",
      letterSpacing: "-0.02em",
      color: "#15192C",
    },
  };
});

function TableBodies({
  dataRows,
  user,
  onUnpaired,
  onSyncScreen,
  onPaired,
  onFolderEdit,
  onScreenFolderDelete,
  onEditScreen,
  onScreenMove,
  onPairScreenModel,
  onQuickPairScreenModal,
  currentFolderData,
  currentFolderPoint,
  onPlaylistPreview,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { currentFolder, allFolders, userFolders, screenFolders, isFolderDeleted } = useSelector(
    (state) => ({
      currentFolder: state.FileFolder.currentFolder,
      userFolders: state.FileFolder.userFolders.filter((f) => f.parent === state.FileFolder.currentFolder && f.folderFor === "screen"),
      allFolders: state.FileFolder.userFolders,
      screenFolders: state.FileFolder.userFolders.filter((f) => f.folderFor === "screen"),
      isFolderDeleted: state.FileFolder.isFolderDeleted,
    }),
    shallowEqual
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const imgMenuRef = useRef(null);
  const open = Boolean(anchorEl);
  const [docId, setDocId] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [openCreateFolder, setOpenCreateFolder] = useState(false);
  const [oneClick, setOneClick] = useState(false);
  useEffect(() => {
    const openBtn = document.querySelector("#openBtn");
    const closeBtn = document.querySelector("#closeBtn");
    const pagg = document.querySelector("#pagination-box");
    const btnGrp = document.querySelector("#btn-group");
    openBtn?.addEventListener("click", function (e) {
      btnGrp.classList.remove("hidden-rm");
      pagg.classList.remove("show-rm");
      pagg?.classList.add("hidden-rm");
      btnGrp.classList.add("show-rm");
    });
    closeBtn?.addEventListener("click", function (e) {
      pagg.classList.remove("hidden-rm");
      btnGrp.classList.remove("show-rm");
      pagg?.classList.add("show-rm");
      btnGrp.classList.add("hidden-rm");
    });
  }, []);

  const handleClose = (type, obj) => {
    setAnchorEl(null);
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  const makeFolder = async () => {
    // Check if folder name exists in current folders
    const isAvailable = allFolders.find((f) => f.name.toLocaleUpperCase() === folderName.toLocaleUpperCase());

    if (folderName === "") {
      toast.error(t("components.tableBodies.fillFolderNameField"));
    } else if (isAvailable) {
      toast.error(t("components.tableBodies.folderAlreadyAvailable"));
    } else {
      const folderData = {
        name: folderName,
        parent: currentFolder,
        folderFor: "screen",
        path: currentFolder === "root" ? [] : [...currentFolderData.path, { parent: currentFolder, name: currentFolderData.name }],
      };

      // Add specific properties based on user type
      if (user?.socialMedia) {
        Object.assign(folderData, {
          role: "socialMedia",
          platForm: "socialMedia",
          platFormId: user?._id,
        });
      } else {
        Object.assign(folderData, {
          role: user?.role,
          user: user?._id,
          subuser: user?._id,
        });
      }

      const folder = await dispatch(createFolder(folderData));
      if (!folder) toast.error(t("components.tableBodies.folderAlreadyAvailable"));
    }

    setOpenCreateFolder(false);
    setFolderName("");
  };
  const handleNavigate = (link, id) => {
    dispatch(setFolderId(id));
    return navigate(link);
  };

  const handlePairing = (id, mac, action) => {
    if (action === "unpair") {
      onUnpaired(id, mac);
    }
    if (action === "pair") {
      onPaired(id, mac);
    }
    handleClose();
  };

  const isMobile = useMediaQuery({ query: "(max-width: 786px)" });

  const tableStyle = {
    width: "100%",
    margin: "auto",
    border: "none",
    padding: isMobile ? 12 : 20,
    position: "relative",
  };
  // const handleChangeOfflineMode = (row) => {
  //   const { _id, playlist, ...screen } = row;

  //   dispatch(
  //     updateScreen(_id, {
  //       ...screen,
  //       playlistId: playlist?._id,
  //       offline_mode_enabled: !row?.offline_mode_enabled,
  //     })
  //   );
  // };

  const TableRowOld = ({ rows, unpair }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = (action, obj) => {
      if (action === "sync") {
        onSyncScreen(obj);
      }
      if (action === "moveinfolder") {
        onScreenMove(obj);
      }
      if (action === "screen-detail") {
        return navigate(`/screendetail/${obj}`);
      }
      setAnchorEl(null);
    };
    const handleChangeOfflineMode = () => {
      console.log("Updating screen status:", rows);
      const { _id, playlist, ...screen } = rows;

      dispatch(
        updateScreen(_id, {
          ...screen,
          playlistId: playlist?._id,
          offline_mode_enabled: !rows?.offline_mode_enabled,
        })
      );
    };

    return (
      <>
        <TableRow hover>
          <TableCell sx={{ maxWidth: "8rem" }}>
            <Tooltip title={rows.deviceName.length > 30 ? rows.deviceName : ''} placement="right">
              {rows.deviceName.length > 30 ? `${rows.deviceName.slice(0, 30)}...` : rows.deviceName}
            </Tooltip>
          </TableCell>
          <TableCell sx={{ maxWidth: "6rem" }} align="center">
            {rows?.isOnline ? (
              rows?.isInOfflineMode ? (
                <Chip color="warning" label="OFF-LINE MODE" />
              ) : (
                <Chip color="success" label="ON-LINE" />
              )
            ) : (
              <Chip color="error" label="OFF-LINE" />
            )}
          </TableCell>
          <TableCell align="center">
            {rows?.locationDetails ? (
              rows?.locationDetails.fullLocation ? (
                !rows?.isOnline ? 'N/A' : (
                  <NetworkIcon ethernetStatus={rows?.device_details?.ethernetStatus} wifiSignal={rows?.device_details?.wifiSignal} connectionType={rows?.device_details?.connectionType}/>
                )
              ) : (
                <HtmlTooltip title={
                  <>
                  {t("components.tableBodies.notAccurateLocation")} &nbsp;
                  <span role="button" style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => navigate(`/screendetail/${rows?._id}`)}>set</span>
                  </>
                }>
                  <BlockIcon/>
                </HtmlTooltip>
              )
            ) : (
              <HtmlTooltip title={
                <>
                {t("components.tableBodies.locationNecessary")} &nbsp;
                <span role="button" style={{textDecoration: 'underline', cursor: 'pointer'}} onClick={() => navigate(`/screendetail/${rows?._id}`)}>set</span>
                </>
              }>
                <BlockIcon/>
              </HtmlTooltip>
            ) }
          </TableCell>
          <TableCell align="center">{rows?.screenCode || "N/A"}</TableCell>
          <TableCell align="center">{rows?.oriantation || "N/A"}</TableCell>
          <TableCell align="center">{rows?.user?.fullname || "N/A"}</TableCell>
          <TableCell sx={{ maxWidth: "2rem" }} align="center">
            <IconButton onClick={() => onPlaylistPreview(rows)}>
              <SvgIcon>
                <SlideshowIcon color="primary" />
              </SvgIcon>
            </IconButton>
          </TableCell>

          {/* <TableCell align="center">{rows?.playlist?.playlistName || "N/A"}</TableCell> */}
          <TableCell align="center" sx={{ maxWidth: "6rem" }}>
            <Tooltip
              title={rows?.playlist?.playlistName || "N/A"} placement="bottom">
              {/* <Typography
                variant="caption"
                align="center"
                sx={{
                  maxWidth: "200px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  margin: "0 auto",
                  textAlign: "center",
                }}> */}
                {rows?.playlist?.playlistName || "N/A"}
              {/* </Typography> */}
            </Tooltip>
          </TableCell>
          <TableCell align="center" sx={{maxWidth: '50px'}}>{rows?.locationDetails ? (
            <Tooltip title={rows?.locationDetails?.formatted_address}>
              {rows?.locationDetails?.short_address}
            </Tooltip>
          ) : (
            'N/A'
          )}</TableCell>
          <TableCell sx={{ maxWidth: "2rem" }} align="center">
            {user.isMember ? (
              user?.previlliages?.screen?.canEdit ? (
                <IconButton onClick={() => onEditScreen(rows._id)}>
                  <SvgIcon>
                    <EditIcon color="primary" />
                  </SvgIcon>
                </IconButton>
              ) : (
                <></>
              )
            ) : (
              <IconButton onClick={() => onEditScreen(rows._id)}>
                <SvgIcon>
                  <EditIcon color="primary" />
                </SvgIcon>
              </IconButton>
            )}
          </TableCell>
          <TableCell sx={{ maxWidth: "2rem" }} align="center">
            <PlanFeatureComponent component={Switch} feature="screen_offline_mode" onChange={handleChangeOfflineMode} checked={rows?.offline_mode_enabled ?? false} />
          </TableCell>
          <TableCell sx={{ maxWidth: "1rem" }} align="right">
            <IconButton
              onClick={handleClick}
              aria-controls={open ? "content-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              id="content-menu-btn">
              <SvgIcon>
                <DotsHorizontalIcon />
              </SvgIcon>
            </IconButton>

            <Menu
              id="content-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              PaperProps={{
                elevation: 4,
                style: {
                  left: anchorEl?.offsetLeft,
                  top: anchorEl?.offsetTop + anchorEl?.offsetHeight,
                  position: "absolute",
                  display: Boolean(anchorEl) ? "block" : "none",
                },
              }}>
              <MenuItem onClick={() => unpair(rows._id, rows.mac, "unpair")}>{t("components.tableBodies.unpair")}</MenuItem>
              <MenuItem onClick={() => handleClose("sync", rows.mac)}>{t("components.tableBodies.sync")}</MenuItem>
              <MenuItem onClick={() => handleClose("moveinfolder", rows._id)}>{t("components.tableBodies.move")}</MenuItem>
              <MenuItem onClick={() => handleClose("screen-detail", rows._id)}>{t("components.tableBodies.screenInfo")}</MenuItem>
            </Menu>
          </TableCell>
        </TableRow>
      </>
    );
  };

  return (
    <>
      <Box style={tableStyle}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            position: "relative",
            flexDirection: isMobile ? "column" : "row",
          }}>
          {/* Breadcrumbs */}
          <div role="presentation" style={{ display: "flex", width: "100%", alignItems: "center" }}>
            {currentFolder !== "root" ? (
              <>
                {" "}
                <Button onClick={() => handleNavigate("/screen", "root")}>{t("components.tableBodies.home")}</Button>
                <Box
                  sx={{
                    backgroundColor: "neutral.500",
                    borderRadius: "50%",
                    height: 4,
                    width: 4,
                  }}
                />
                {currentFolderData?.path?.map((c, index) => (
                  <>
                    {" "}
                    <Button onClick={() => handleNavigate(`/screenfolders/${c.parent}`, `${c.parent}`)}>{c.name}</Button>
                    <Box
                      sx={{
                        backgroundColor: "neutral.500",
                        borderRadius: "50%",
                        height: 4,
                        width: 4,
                      }}
                    />
                  </>
                ))}
                <Typography sx={{ padding: "10px 10px" }} variant="button" display="block">
                  {currentFolderData?.name}
                </Typography>
              </>
            ) : (
              <Typography variant="button" display="block">
                {t("components.tableBodies.home")}
              </Typography>
            )}
          </div>
          {/* menu buttons */}
          <div
            role="presentation"
            style={{
              display: "flex",
              justifyContent: isMobile ? "flex-start" : "flex-end",
              width: "100%",
            }}>
            <Button
              variant="outlined"
              onClick={() => onQuickPairScreenModal()}
              disabled={user.isMember ? (user?.previlliages?.screen?.canCreate ? false : true) : false}
              startIcon={<AddToQueueIcon />}>
              {t("components.tableBodies.quickAddScreen")}
            </Button>
            &nbsp;
            <Button
              variant="outlined"
              onClick={() => onPairScreenModel()}
              disabled={user.isMember ? (user?.previlliages?.screen?.canCreate ? false : true) : false}
              startIcon={<AddToQueueIcon />}>
              {t("components.tableBodies.addScreen")}
            </Button>
            &nbsp;
            <Button
              variant="outlined"
              onClick={() => setOpenCreateFolder(true)}
              disabled={user.isMember ? (user?.previlliages?.folder?.canCreate ? false : true) : false}
              startIcon={<CreateNewFolderOutlinedIcon />}>
              {t("components.tableBodies.createGroup")}
            </Button>
          </div>
        </Box>

        <Card sx={{ marginTop: "20px" }}>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              flexWrap: "wrap",
              margin: "20px",
            }}>
            {userFolders.map((f, index) => {
              return <TableFolders key={index} folder={f} handleNavigate={handleNavigate} onFolderDelete={onScreenFolderDelete} onFolderEdit={onFolderEdit} />;
            })}
          </Box>
          <Scrollbar>
            <Table sx={{ minWidth: 400, width: "fill" }}>
              <TableHead>
                <TableRow>
                  <TableCell>{t("components.tableBodies.screenName")}</TableCell>
                  <TableCell sx={{ maxWidth: "6rem" }} align="center">
                    {t("components.tableBodies.status")}
                  </TableCell>
                  <TableCell align="center">{t("components.tableBodies.linktype")}</TableCell>
                  <TableCell align="center">{t("components.tableBodies.pairingCode")}</TableCell>
                  <TableCell align="center">{t("components.tableBodies.orientation")}</TableCell>
                  <TableCell align="center">{t("components.tableBodies.user")}</TableCell>
                  <TableCell sx={{ maxWidth: "3rem" }} align="center">
                    {t("components.tableBodies.preview")}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      maxWidth: "3rem",
                    }}>
                    {t("components.tableBodies.playlistName")}
                  </TableCell>
                  <TableCell align="center">{t("components.tableBodies.locationName")}</TableCell>
                  <TableCell sx={{ maxWidth: "2rem" }} align="center">
                    {t("components.tableBodies.edit")}
                  </TableCell>
                  <TableCell sx={{ maxWidth: "2rem" }} align="center">
                    {t("components.tableBodies.offlinemode")}
                  </TableCell>
                  <TableCell sx={{ maxWidth: "1rem" }} />
                </TableRow>
              </TableHead>

              <TableBody>
                {dataRows?.map((row, index) => (
                  <TableRowOld rows={row} key={index} unpair={(id, mac, action) => handlePairing(id, mac, action)} />
                ))}
                {/* <TableBody>
                {dataRows?.map((row, index) => (
                  <TableRowOld
                    rows={row}
                    key={index}
                    unpair={(id, mac, action) => handlePairing(id, mac, action)}
                    handleChangeOfflineMode={() => handleChangeOfflineMode(row)}
                  />
                ))} */}
              </TableBody>
            </Table>
          </Scrollbar>
        </Card>
      </Box>

      {/* create group modal */}
      <Dialog open={openCreateFolder} onClose={() => setOpenCreateFolder(false)}>
        <FormControl component="form" onSubmit={(e) => e.preventDefault()}>
          <DialogTitle>{t("components.tableBodies.createGroup")}</DialogTitle>
          <DialogContent
            sx={{
              width: isMobile ? "300px" : "500px",
              height: "240px",
            }}>
            <Box style={{ width: "120px", height: "120px", margin: "20px auto" }}>
              <CreateNewFolderIcon color="primary" sx={{ fontSize: 120 }} />
            </Box>
            <TextField
              autoFocus
              required
              variant="standard"
              fullWidth
              margin="dense"
              id="folderName"
              name="folderName"
              type={"text"}
              label={t("components.tableBodies.typeFolderName")}
              value={folderName}
              onChange={(e) => setFolderName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenCreateFolder(false)}>{t("components.tableBodies.close")}</Button>
            <Button onClick={makeFolder} type="submit">
              {t("components.tableBodies.save")}
            </Button>
          </DialogActions>
        </FormControl>
      </Dialog>
    </>
  );
}

export default TableBodies;
