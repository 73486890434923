import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import toast from "react-hot-toast";
import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";

function SubscriptionPayment() {
    const stripe = useStripe();
    const elements = useElements();

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        if (!stripe || !elements) {
            return;
        }

        const result = await stripe.confirmPayment({
          elements,
          confirmParams: {
            return_url: `${process.env.REACT_APP_BASE_URL}/confirm-payment`,
          },
        });
    
        if (result.error) {
          toast.error("Payment details incomplete")
          console.log(result.error.message);
        } else {
          // Your customer will be redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer will be redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.
        }
    };

    return (
        <>
        <Card sx={{
          p:4
        }}>
            <CardHeader title="Subscription payment" />
            <form onSubmit={handleSubmit}>
                <PaymentElement />
                <button disabled={!stripe} type="submit" style={{
                  padding: '8px',
                  marginTop: '10px',
                  borderRadius: '5px',
                  border: 'none'
                }}>Submit</button>                
                {/* <button disabled={!stripe} type="reset" style={{
                  padding: '8px',
                  marginTop: '10px',
                  borderRadius: '5px',
                  border: 'none',
                  marginLeft: '10px'
                }}>Cancel</button> */}
            </form>
        </Card>
        </>
    );
}

export default SubscriptionPayment;
