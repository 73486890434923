import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { Table, TableBody, TableCell, TableRow, Checkbox, Typography, Button, Box, Card, Grid, List, ListItem, ListItemIcon, ListItemText, IconButton, Backdrop, CircularProgress } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { useTheme } from "@mui/material/styles";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { parsePrice } from "src/utils/parse-price";
import { api } from "src/api/endpoints";
import { AddressElement, CardElement, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import toast from "react-hot-toast";
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import FlagIcon from '@mui/icons-material/Flag';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PlaceIcon from '@mui/icons-material/Place';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import EditIcon from '@mui/icons-material/Edit';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AddIcon from '@mui/icons-material/Add';
import PaymentIcon from '@mui/icons-material/Payment';
import DeleteIcon from '@mui/icons-material/Delete';

const BillingAccount = ({ user, customerDetails, customerPaymentMethods, setCustomerPaymentMethods, setCustomerDetails }) => {
    const { t } = useTranslation();
    const elements = useElements()
    const stripe = useStripe()

    // const [customerPaymentMethods, setCustomerPaymentMethods] = useState(null)
    // const [customerDetails, setCustomerDetails] = useState(null)
    const [createCustomerActive, setCreateCustomerActive] = useState(false)
    const [customerEmail, setCustomerEmail] = useState(user.email)
    const [customerDetailsEditActive, setCustomerDetailsEditActive] = useState(false)
    const [addPaymentMethodActive, setAddPaymentMethodActive] = useState(false)
    const [loadingBackdrop, setLoadingBackdrop] = useState(false)

    // useEffect(() => {
    //     const getDataOnStartup = async () => {
    //         if(user.stripeCustomerId){
    //             const customerDetailsRes = await api.post('/payment/getCustomerDetails', {customerId: user.stripeCustomerId})
    //             const customerPaymentMethodsRes = await api.post('/payment/listUsersPaymentMethods', {customerId: user.stripeCustomerId})

    //             setCustomerPaymentMethods(customerPaymentMethodsRes.data.methods)
    //             setCustomerDetails(customerDetailsRes.data.customer)
    //         }
    //     }
    //     if(!customerDetails) {
    //         getDataOnStartup()
    //     }
    // }, [])

    const handleNewCustomerAdd = async () => {
        setLoadingBackdrop(true)
        if(customerEmail){
            const addressElement = elements.getElement('address')
            const {complete, value} = await addressElement.getValue()
            if(value.phone) {
                  if(complete){
                      try {
                          const {data, status} = await api.post('/payment/createCustomer', {
                              userId: user._id,
                              email: customerEmail,
                              name: value.name,
                              city: value.address.city,
                              country: value.address.country,
                              line1: value.address.line1,
                              line2: value.address.line2 ? value.address.line2 : '',
                              postalCode: value.address.postal_code,
                              state: value.address.state,
                              phone: value.phone
                          }) 
                        setTimeout(() => {
                            window.location.reload()
                        }, 500)
                      } catch (error) {
                          setLoadingBackdrop(false)
                          toast.error("Error when adding a billing account")
                      }
                  }
            }else{
                toast.error('Phone number is required')      
            }
        }else{
          toast.error('Email address is required')
        }
    }

    const handleCustomerDataEdit = async () => {
        setLoadingBackdrop(true)
        if(customerEmail){
            const addressElement = elements.getElement("address")
            const {complete, value} = await addressElement.getValue()
            if(value.phone) {
                  if(complete){
                      try {
                        setLoadingBackdrop(false)
                          const {data, status} = await api.post('/payment/editCustomer', {
                              customerId: user.stripeCustomerId,
                              email: customerEmail,
                              name: value.name,
                              city: value.address.city,
                              country: value.address.country,
                              line1: value.address.line1,
                              line2: value.address.line2 ? value.address.line2 : '',
                              postalCode: value.address.postal_code,
                              state: value.address.state,
                              phone: value.phone
                          }) 
                          setCustomerDetails(data.updatedCustomer)
                          setCustomerDetailsEditActive(false)
                          toast.success("Successfully edited settlement account")
                      } catch (error) {
                          setLoadingBackdrop(false)
                          toast.error("Error when editing a billing account")
                      }
                  }
            }else{
                toast.error('Phone number is required')      
            }
        }else{
          toast.error('Email address is required')
        }   
    }

    const handleAddPaymentMethod = async (event) => {
        event.preventDefault();
        if(user.stripeCustomerId){
            const cardElement = elements.getElement('card')

            if (!stripe || !elements || !cardElement) {
                return;
            }

            const result = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement
            })
        
            setAddPaymentMethodActive(false)

            if (result.error) {
                toast.error("Payment details incomplete")
            } else {
                try {
                    const attachPMtoUserRes = await api.post('/payment/attachPMtoCustomer', {pmId: result.paymentMethod.id, customerId: user.stripeCustomerId})
                    setCustomerPaymentMethods([{...result.paymentMethod}])
                }catch (err) {
                    toast.error("Error while attaching payment method to customer")
                }
            }
        }else{
            toast.error("No billing account data. Refresh the page and try again")
        }
    }

    const handleDetachPMfromCustomer = async (pmId) => {
        try{ 
            const detachRes = await api.post('/payment/detachPMfromCustomer', {pmId:pmId})
            setCustomerPaymentMethods([])
        } catch(error) {
            toast.error("Error while detaching payment method from your account")
        }
    }

    const handleEditPaymentMethod = async (event, oldPmId) => {
        event.preventDefault()
        if(user.stripeCustomerId){
            setLoadingBackdrop(true)
            const cardElement = elements.getElement('card')

            if (!stripe || !elements || !cardElement) {
                setLoadingBackdrop(false)
                return;
            }

            const result = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement
            })
        
            setAddPaymentMethodActive(false)

            if (result.error) {
                toast.error("Payment details incomplete")
                setLoadingBackdrop(false)
            } else {
                try {
                    const detachRes = await api.post('/payment/detachPMfromCustomer', {pmId: oldPmId})
                    const attachPMtoUserRes = await api.post('/payment/attachPMtoCustomer', {pmId: result.paymentMethod.id, customerId: user.stripeCustomerId})
                    setCustomerPaymentMethods([{...result.paymentMethod}])
                    setLoadingBackdrop(false)
                }catch (err) {
                    toast.error("Error while attaching payment method to customer")
                    setLoadingBackdrop(false)
                }
            }
        }else{
            toast.error("No billing account data. Refresh the page and try again")
        }
    }

    const handleEditPM = () => {
        const defaultPM = customerPaymentMethods[0]
        defaultPM.openEdit = true

        setCustomerPaymentMethods([defaultPM])
    }

    const handleCloseEditPM = () => {
        const defaultPM = customerPaymentMethods[0]
        defaultPM.openEdit = false

        setCustomerPaymentMethods([defaultPM])
    }

    return (
        <Box sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center'
        }}>
            <Backdrop
            sx={(theme) => ({zIndex: theme.zIndex.drawer + 1 })}
            open={loadingBackdrop}
            onClick={() => setLoadingBackdrop(false)}
            >
            <CircularProgress/>
            </Backdrop>
            <Card variant="outlined" sx={{
                width: '100%',
                maxWidth: '600px',
                overflow: 'auto'
            }}>
                {customerDetails ? (customerDetailsEditActive ? (
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        py: 1,
                        px: 2,
                        minWidth: '500px'
                    }}>
                        <Typography variant="h5" sx={{mb: 3}}>Enter billing data</Typography>
                        <Box sx={{mb: 2}}>
                        <TextField variant="outlined" label="E-mail address" fullWidth color="primary" value={customerEmail} onInput={(e) => setCustomerEmail(e.target.value)}/>
                        </Box>
                        <AddressElement options={{mode: 'billing', fields: {phone: 'always'}, validation: {phone: {required: 'always'}}, autocomplete: {mode: 'google_maps_api', apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY},
                        defaultValues: {
                            name: customerDetails.name,
                            phone: customerDetails.phone,
                            address: customerDetails.shipping.address
                        }
                    }}/>
                        <Box sx={{mt: 3, display: 'flex', gap: '10px'}}>
                            <Button onClick={handleCustomerDataEdit} variant='contained'>Edit</Button>
                            <Button variant='contained' color="error" onClick={() => setCustomerDetailsEditActive(false)}>Cancel</Button>
                        </Box>
                    </Box>
                ) : (
                    <Box sx={{px: 1, py:3, minWidth: '500px'}}>
                        <Box sx={{display: 'flex', justifyContent: "space-between", gap: '10px', alignItems: 'center', px: 2}}>
                            <Typography variant="h6">Billing account data</Typography>
                            <Button size="medium" endIcon={<EditIcon />} onClick={() => setCustomerDetailsEditActive(true)}>Edit</Button>
                        </Box>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <List>
                                    <ListItem>
                                        <ListItemIcon>
                                            <PersonIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={customerDetails.name}
                                            secondary="Name"
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <FlagIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={customerDetails.shipping.address.country}
                                            secondary="Country"
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <MarkunreadMailboxIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={customerDetails.shipping.address.postal_code}
                                            secondary="Postal code"
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <PlaceIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={customerDetails.shipping.address.line2 ? customerDetails.shipping.address.line2 : '-'}
                                            secondary="Address line 2"
                                        />
                                    </ListItem>
                                </List>
                            </Grid>
                            <Grid item xs={6}>
                                <List>
                                    <ListItem>
                                        <ListItemIcon>
                                            <EmailIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={customerDetails.email}
                                            secondary="Email address"
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <LocationCityIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={customerDetails.shipping.address.city}
                                            secondary="City"
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <PlaceIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={customerDetails.shipping.address.line1}
                                            secondary="Address line 1"
                                        />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <LocalPhoneIcon />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={customerDetails.phone}
                                            secondary="Phone"
                                        />
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                        <Box sx={{px: 2, display: 'flex', flexDirection: 'column', gap: '20px'}}>
                            <Typography variant="h6" sx={{mt:2}}>Payment methods</Typography>
                            {addPaymentMethodActive ? (
                                <form onSubmit={handleAddPaymentMethod}>
                                    <Box sx={{
                                        backgroundColor: '#252525',
                                        p: 1,
                                        borderRadius: '8px'
                                    }}>
                                        <CardElement options={{style: {
                                            base: {
                                                color: '#fff',
                                                backgroundColor: '#252525',
                                                lineHeight: '30px'
                                            }
                                        }}}/>
                                    </Box>
                                    <button disabled={!stripe} type="submit" style={{
                                        padding: '8px',
                                        marginTop: '20px',
                                        borderRadius: '5px',
                                        border: 'none'
                                    }}>Submit</button>                
                                    <button type="button" onClick={() => setAddPaymentMethodActive(false)} style={{
                                        padding: '8px',
                                        marginTop: '20px',
                                        borderRadius: '5px',
                                        border: 'none',
                                        marginLeft: '10px'
                                    }}>Cancel</button>
                                </form>
                            ) : (
                                <List>
                                    {customerPaymentMethods ? (customerPaymentMethods.length > 0 ? (
                                        customerPaymentMethods.map(pm => {
                                            if(pm.openEdit){
                                                return (
                                                    <form onSubmit={(event) => handleEditPaymentMethod(event, pm.id)}>
                                                        <Box sx={{
                                                            backgroundColor: '#252525',
                                                            p: 1,
                                                            borderRadius: '8px'
                                                        }}>
                                                            <CardElement options={{style: {
                                                                base: {
                                                                    color: '#fff',
                                                                    backgroundColor: '#252525',
                                                                    lineHeight: '30px'
                                                                }
                                                            }}}/>
                                                        </Box>
                                                        <button disabled={!stripe} type="submit" style={{
                                                            padding: '8px',
                                                            marginTop: '20px',
                                                            borderRadius: '5px',
                                                            border: 'none'
                                                        }}>Submit</button>                
                                                        <button type="button" onClick={handleCloseEditPM} style={{
                                                            padding: '8px',
                                                            marginTop: '20px',
                                                            borderRadius: '5px',
                                                            border: 'none',
                                                            marginLeft: '10px'
                                                        }}>Cancel</button>
                                                    </form>
                                                )
                                            }else{
                                                return (
                                                    <ListItem key={pm.id} secondaryAction={
                                                        <IconButton edge="end" aria-label="delete payment method" onClick={handleEditPM}>
                                                            <EditIcon color="primary" />
                                                        </IconButton>
                                                    }>
                                                        <ListItemIcon>
                                                            <PaymentIcon/>
                                                        </ListItemIcon>
                                                        <ListItemText secondary="Credit card" primary={`${pm.card?.brand.charAt(0).toUpperCase()}${pm.card?.brand.slice(1)} **** ${pm.card?.last4}`}/>
                                                    </ListItem>
                                                )
                                            }
                                        })
                                    ) : (
                                        <Box sx={{display: 'flex', justifyContent: 'center'}}>
                                            <Button variant="contained" color="success" endIcon={<AddIcon/>} onClick={() => setAddPaymentMethodActive(true)}>Add payment method</Button>
                                        </Box>
                                    )) : (
                                        <ListItem>
                                            <ListItemText primary="No payment method"/>  
                                        </ListItem>
                                    )}
                                </List>
                            )}
                        </Box>
                    </Box>
                )) : (
                    <Box sx={{display: 'flex', justifyContent: 'center', my: 3, width: '100%', minWidth: '500px'}}>
                        {createCustomerActive ? (
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                py: 1,
                                px: 2
                            }}>
                                <Typography variant="h5" sx={{mb: 3}}>Enter billing data</Typography>
                                <Box sx={{mb: 2}}>
                                <TextField variant="outlined" label="E-mail address" fullWidth color="primary" value={customerEmail} onInput={(e) => setCustomerEmail(e.target.value)}/>
                                </Box>
                                <AddressElement options={{mode: 'billing', fields: {phone: 'always'}, validation: {phone: {required: 'always'}}, autocomplete: {mode: 'google_maps_api', apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY}}}/>
                                <Box sx={{mt: 3, display: 'flex', gap: '10px'}}>
                                    <Button onClick={handleNewCustomerAdd} variant='contained'>Create</Button>
                                    <Button variant='contained' color="error" onClick={() => setCreateCustomerActive(false)}>Cancel</Button>
                                </Box>
                            </Box>
                        ) : (
                            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', p:2, gap: '20px'}}>
                                <Typography variant="h6" textAlign="center">Your account does not contain billing data. Create a new one</Typography>
                                <Button variant="contained" onClick={() => setCreateCustomerActive(true)}>
                                    Create billing account
                                </Button>
                            </Box>
                        )}
                    </Box>
                )}
            </Card>
        </Box>
    );
};

export default BillingAccount;
