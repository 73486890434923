import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import SvgIcon from "@mui/material/SvgIcon";

import BarChartSquare02Icon from "src/icons/untitled-ui/duocolor/bar-chart-square-02";
import HomeSmileIcon from "src/icons/untitled-ui/duocolor/home-smile";
import LogOut01Icon from "src/icons/untitled-ui/duocolor/log-out-01";
import Mail03Icon from "src/icons/untitled-ui/duocolor/mail-03";
import Upload04Icon from "src/icons/untitled-ui/duocolor/upload-04";

import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import TvIcon from "@mui/icons-material/Tv";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import RestoreIcon from "@mui/icons-material/Restore";
import DevicesIcon from "@mui/icons-material/Devices";
import TuneIcon from "@mui/icons-material/Tune";
import ExtensionIcon from "@mui/icons-material/Extension";

import { paths } from "src/paths";

export const useSections = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state) => state.User);

  const isFeatureEnabled = (featureName) => {
    const plan = user?.plan;
    if (!plan) {
      return false;
    }

    try {
      const feature = plan.planFeatures.find((f) => f.slug == featureName);
      if (!feature) return false;
      return feature.enabled;
    } catch (e) {
      console.log(e);
      return false;
    }
  };

  return useMemo(() => {
    const sections = [
      {
        items: [
          {
            title: t("layout.sidebar.dashboard"),
            path: paths.home.index,
            icon: (
              <SvgIcon fontSize="small">
                <HomeSmileIcon />
              </SvgIcon>
            ),
          },
          {
            title: t("layout.sidebar.apk"),
            disabled: user?.role != "admin",
            hidden: user?.role != "admin",
            path: paths.apk.index,
            icon: (
              <SvgIcon fontSize="small">
                <DevicesIcon />
              </SvgIcon>
            ),
          },
          {
            title: t("layout.sidebar.files"),
            path: paths.files.index,
            icon: (
              <SvgIcon fontSize="small">
                <Upload04Icon />
              </SvgIcon>
            ),
          },
          {
            title: t("layout.sidebar.apps"),
            path: `${paths.files.index}?apps`,
            icon: (
              <SvgIcon fontSize="small">
                <ExtensionIcon />
              </SvgIcon>
            ),
          },
          {
            title: t("layout.sidebar.screens"),
            path: paths.screens.index,
            icon: (
              <SvgIcon fontSize="small">
                <TvIcon />
              </SvgIcon>
            ),
          },
          {
            title: t("layout.sidebar.playlist"),
            path: paths.playlist.index,
            icon: (
              <SvgIcon fontSize="small">
                <SubscriptionsIcon />
              </SvgIcon>
            ),
          },
          {
            title: t("layout.sidebar.users"),
            disabled: user?.role != "admin",
            hidden: user?.role != "admin",
            path: paths.users.index,
            icon: (
              <SvgIcon fontSize="small">
                <PeopleAltIcon />
              </SvgIcon>
            ),
          },
          {
            title: t("layout.sidebar.reports"),
            path: paths.reports.index,
            hidden: user?.role != "admin",
            icon: (
              <SvgIcon fontSize="small">
                <BarChartSquare02Icon />
              </SvgIcon>
            ),
          },
          {
            title: t("layout.sidebar.restoreFiles"),
            path: paths.restore_files.index,
            disabled: !isFeatureEnabled("restore_files"),
            icon: (
              <SvgIcon fontSize="small">
                <RestoreIcon />
              </SvgIcon>
            ),
          },
          {
            title: t("layout.sidebar.featureManager"),
            disabled: user?.role != "admin",
            hidden: user?.role != "admin",
            path: paths.feature_manager.index,
            icon: (
              <SvgIcon fontSize="small">
                <TuneIcon />
              </SvgIcon>
            ),
          },
          {
            title: t("layout.sidebar.productsManager"),
            disabled: user?.role != "admin",
            hidden: user?.role != "admin",
            path: paths.products_manager.index,
            icon: (
              <SvgIcon fontSize="small">
                <TuneIcon />
              </SvgIcon>
            ),
          },
          {
            title: t("layout.sidebar.email"),
            path: paths.email_templates.index,
            hidden: user?.role != "admin",
            icon: (
              <SvgIcon fontSize="small">
                <Mail03Icon />
              </SvgIcon>
            ),
          },
          {
            title: t("layout.sidebar.logout"),
            path: paths.authentication.logout,
            icon: (
              <SvgIcon fontSize="small">
                <LogOut01Icon />
              </SvgIcon>
            ),
          },
        ],
      },
    ];

    const filteredSections = sections.map((section) => ({
      ...section,
      items: section.items.filter((item) => !item.hidden),
    }));

    return filteredSections;
  }, [t, user]);
};
