import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, List, ListItem, Card, CardHeader, MenuItem, Button, Typography, Link, SvgIcon, TextField } from "@mui/material";
import Layout from "../../components/layout/Layout";
import DonutChart from "../../components/chart/DonutChart";
import { useParams } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import "./screendetail.css";
import { useNavigate } from "react-router-dom";
import { APIProvider, Map, Marker, AdvancedMarker, useMapsLibrary, useMap } from "@vis.gl/react-google-maps";
import { Autocomplete } from "@react-google-maps/api";

import { paths } from "src/paths";
import { RouterLink } from "src/components/router-link";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Unstable_Grid2";
import Stack from "@mui/material/Stack";
import ArrowLeftIcon from "@untitled-ui/icons-react/build/esm/ArrowLeft";
import { useSettings } from "src/hooks/use-settings";
import ScreenDataUsageStatsWidget from "./ScreenDataUsageStats";
import { NetworkIcon } from "src/icons/untitled-ui/duocolor/network";
import { useDispatch } from "react-redux";
import { getScreenNoDataLogs, updateScreenLocation } from "src/redux/actions/Screen";
import toast from "react-hot-toast";

function ScreenDetail({ user, socketRef }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const settings = useSettings();
  const dispatch = useDispatch()
  const locationRef = useRef(null);
  const [location, setLocation] = useState("");
  const [locationDetails, setLocationDetails] = useState(null)

  const { id } = useParams();
  const selectedPlaceRef = useRef();
  const { screens, apk, screenNoDataLogs } = useSelector(
    (state) => ({
      screens: state.Screen.screens,
      apk: state.Apk.apk,
      screenNoDataLogs: state.Screen.screenNoDataLogs
    }),
    shallowEqual
  );
  const [screenInfo, setScreenInfo] = useState({});
  const [apkDetail, setApkDetail] = useState({});
  const [isApkUpdated, setIsApkUpdated] = useState(false);

  useEffect(() => {
    if(screenInfo.mac){
      console.log('zmiana')
      dispatch(getScreenNoDataLogs(screenInfo.mac))
    }
  }, [screenInfo])

  useEffect(() => {
    if (screens && screens.length > 0) {
      const s = screens.find((s) => s._id === id);
      if (s) {
        apk.forEach((a) => {
          if (a?.versionName > s?.device_details?.software) {
            setApkDetail({ url: `https://app.vcplayer.com${a.url}` });
            setIsApkUpdated(true);
          }
        });
        setScreenInfo({ ...s });
      } else {
        console.error("Screen not found");
      }
      // console.log("screen: ", s);
    }
  }, [id, screens, apk]);

  const sendApkUpdate = () => {
    socketRef.current.emit("sendapkupdate", { url: apkDetail.url, mac: screenInfo.mac });
  };

  const placeChanged = () => {
    const placeObject = selectedPlaceRef.current.getPlace();
    const lat = placeObject.geometry.location.lat();
    const lng = placeObject.geometry.location.lng();
    console.log("Latitude:", lat);
    console.log("Longitude:", lng);
  };

  const analyzeLocation = (locationObj) => {
    const country = locationObj.address_components.find(item => item.types.includes('country'))
    if(country){
      const region = locationObj.address_components.find(item => item.types.includes("administrative_area_level_1"))
      if(region){
        const city = locationObj.address_components.find(item => item.types.includes("locality"))
        if(city){
          const street = locationObj.address_components.find(item => item.types.includes("route"))
          if(street){
            return {
              fullLocation: true,
              mostDetailedLevel: 'street',
              lat: locationObj.geometry.location.lat(),
              lng: locationObj.geometry.location.lng(),
              formatted_address: locationObj.formatted_address,
              short_address: `${country.long_name} ${city.long_name}`,
              labelColor: 'success.main',
              labelMsg: t("views.redesignScreens.accessActive")
            }
          }else{
            return {
              fullLocation: false,
              mostDetailedLevel: 'city',
              lat: locationObj.geometry.location.lat(),
              lng: locationObj.geometry.location.lng(),
              formatted_address: locationObj.formatted_address,
              short_address: `${country.long_name} ${city.long_name}`,
              labelColor: 'warning.main',
              labelMsg: t("views.redesignScreens.accessLimited")
            }
          }
        }else{
          return {
            fullLocation: false,
            mostDetailedLevel: 'region',
            lat: locationObj.geometry.location.lat(),
            lng: locationObj.geometry.location.lng(),
            formatted_address: locationObj.formatted_address,
            short_address: country.long_name,
            labelColor: 'warning.main',
            labelMsg: t("views.redesignScreens.accessLimited")
          }
        }
      }else{
        return {
          fullLocation: false,
          mostDetailedLevel: 'country',
          lat: locationObj.geometry.location.lat(),
          lng: locationObj.geometry.location.lng(),
          formatted_address: locationObj.formatted_address,
          short_address: country.long_name,
          labelColor: 'warning.main',
          labelMsg: t("views.redesignScreens.accessLimited")
        }
      }
    }else{
      return {
        fullLocation: false,
        mostDetailedLevel: null,
        lat: locationObj.geometry.location.lat(),
        lng: locationObj.geometry.location.lng(),
        formatted_address: locationObj.formatted_address,
        short_address: locationObj.formatted_address,
        labelColor: 'warning.main',
        labelMsg: t("views.redesignScreens.accessLimited")
      }
    }
  }

  const onGetLatLonByLocatioChange = () => {
    const placeObject = locationRef.current.getPlace();
    setLocation(placeObject?.name);
    setLocationDetails(analyzeLocation(placeObject))
  };

  const handleLocationSubmit = () => {
    if(locationDetails){
      if(locationDetails.mostDetailedLevel){
        dispatch(updateScreenLocation(screenInfo._id, {locationDetails: locationDetails}))
        setScreenInfo({...screenInfo, locationDetails:locationDetails})
      }else{
        toast.error(t("views.redesignScreens.location"))
      }
    }else{
      toast.error(t("views.redesignScreens.location"))
    }
  }

  return (
    <Layout title={t("views.screenDetail.title")} user={user}>
      <Box component="main" sx={{ flexGrow: 1, py: 2 }}>
        <Container maxWidth={settings.stretch ? false : "xl"}>
          <div>
            <Link
              color="text.primary"
              component="button"
              onClick={() => navigate(-1)}
              sx={{
                alignItems: "center",
                display: "inline-flex",
                marginBottom: "20px",
                background: "none",
                border: "none",
                cursor: "pointer",
              }}
              underline="hover">
              <SvgIcon sx={{ mr: 1 }}>
                <ArrowLeftIcon />
              </SvgIcon>
              <Typography variant="subtitle2">{t("views.screenDetail.screens")}</Typography>
            </Link>
          </div>

          <Grid container spacing={{ xs: 3, lg: 4 }}>

            <Box sx={{ display: "flex", minWidth: "100%", flexDirection: { md: "row", xs: "column" } }}>
              {/* left column */}
              <Grid xs={12} md={8}>
                <Card sx={{ p: 2 }}>
                  {/* list */}
                  <List>
                    <ListItem disableGutters divider sx={{ justifyContent: "space-between", padding: 2 }}>
                      <Typography variant="subtitle2">{t("views.screenDetail.status")}</Typography>
                      <Typography variant="body2" color={screenInfo.onlineStatus ? "green" : "error"}>
                        {screenInfo.onlineStatus ? t("views.screenDetail.connected") : t("views.screenDetail.disconnected")}
                      </Typography>
                    </ListItem>

                    <ListItem disableGutters divider sx={{ justifyContent: "space-between", padding: 2 }}>
                      <Typography variant="subtitle2">{t('views.screenDetail.lastActivity')}</Typography>
                      <Typography color="text.secondary" variant="body2">
                        {screenInfo.lastActive ? new Date(screenInfo.last_updated).toLocaleString() : 'N/A' }
                      </Typography>
                    </ListItem>

                    <ListItem disableGutters divider sx={{ justifyContent: "space-between", padding: 2 }}>
                      <Typography variant="subtitle2">{t("views.screenDetail.code")}</Typography>
                      <Typography color="text.secondary" variant="body2">
                        {screenInfo?.screenCode}
                      </Typography>
                    </ListItem>

                    <ListItem disableGutters divider sx={{ justifyContent: "space-between", padding: 2 }}>
                      <Typography variant="subtitle2">{t('views.screenDetail.ssidName')}</Typography>
                      <Box sx={{textAlign: 'end'}}>
                        <Typography color="text.secondary" variant="body2">
                          {screenInfo?.device_details?.ssid ? (screenInfo?.device_details?.ssid == 'Not connected to a network' ? 'Unable to identify network SSID' : screenInfo?.device_details?.ssid) : 'N/A'}
                          {screenInfo?.device_details?.connectionType == 'WiFi' ? (
                            <NetworkIcon connectionType="WiFi" wifiSignal={screenInfo?.device_details?.wifiSignal} ethernetStatus={0}/>
                          ) : null}
                        </Typography>
                        {screenNoDataLogs ? (
                          <Typography sx={{color: 'warning.main'}} variant="caption">
                            {screenNoDataLogs.ssid ? new Date(screenNoDataLogs.ssid).toLocaleString() : null}
                          </Typography>
                        ) : null}
                      </Box>
                    </ListItem>

                    <ListItem disableGutters divider sx={{ justifyContent: "space-between", padding: 2 }}>
                      <Typography variant="subtitle2">{t("views.screenDetail.deviceName")}</Typography>
                      <Typography color="text.secondary" variant="body2">
                        {screenInfo?.deviceName}
                      </Typography>
                    </ListItem>

                    {/* <ListItem disableGutters divider sx={{ justifyContent: "space-between", padding: 2 }}>
                      <Typography variant="subtitle2">{t("views.screenDetail.manufacturing")}</Typography>
                      <Typography color="text.secondary" variant="body2">
                        {screenInfo?.device_details?.manufacturer}
                      </Typography>
                    </ListItem> */}

                    <ListItem disableGutters divider sx={{ justifyContent: "space-between", padding: 2 }}>
                      <Typography variant="subtitle2">{t("views.screenDetail.os")}</Typography>
                      <Box sx={{textAlign: 'end'}}>
                        <Typography color="text.secondary" variant="body2">
                          {screenInfo?.device_details?.os_version}
                        </Typography>
                        {screenNoDataLogs ? (
                          <Typography sx={{color: 'warning.main'}} variant="caption">
                            {screenNoDataLogs.os_version ? new Date(screenNoDataLogs.os_version).toLocaleString() : null}
                          </Typography>
                        ) : null}
                      </Box>
                    </ListItem>

                    <ListItem disableGutters divider sx={{ justifyContent: "space-between", padding: 2 }}>
                      <Typography variant="subtitle2">{t("views.screenDetail.location")}</Typography>
                      <Box>
                        {screenInfo?.locationDetails ? (
                          <Typography color="text.secondary" variant="body2">
                            {screenInfo?.locationDetails.formatted_address}
                          </Typography>
                        ) : (
                          <Typography color="text.secondary" variant="body2">
                            N/A
                          </Typography>
                        )}
                      </Box>
                    </ListItem>

                    <ListItem disableGutters divider sx={{ justifyContent: "space-between", padding: 2 }}>
                      <Typography variant="subtitle2">{t("views.screenDetail.macAddress")}</Typography>
                      <Typography color="text.secondary" variant="body2">
                        {screenInfo?.mac}
                      </Typography>
                    </ListItem>

                    <ListItem disableGutters divider sx={{ justifyContent: "space-between", padding: 2 }}>
                      <Typography variant="subtitle2">{t("views.screenDetail.ipAddress")}</Typography>
                      <Typography color="text.secondary" variant="body2">
                        {screenInfo?.device_details?.ip}
                      </Typography>
                    </ListItem>

                    <ListItem disableGutters divider sx={{ justifyContent: "space-between", padding: 2 }}>
                      <Typography variant="subtitle2">{t("views.screenDetail.software")}</Typography>
                      <Box sx={{textAlign: 'end'}}>
                        <Typography color="text.secondary" variant="body2">
                          {screenInfo?.device_details?.software}
                        </Typography>
                        {screenNoDataLogs ? (
                          <Typography sx={{color: 'warning.main'}} variant="caption">
                            {screenNoDataLogs.software ? new Date(screenNoDataLogs.software).toLocaleString() : null}
                          </Typography>
                        ) : null}
                      </Box>
                    </ListItem>

                    <ListItem disableGutters divider sx={{ justifyContent: "space-between", padding: 2 }}>
                      <Typography variant="subtitle2">Plugin version</Typography>
                      <Box sx={{textAlign: 'end'}}>
                        <Typography color="text.secondary" variant="body2">
                          {screenInfo?.device_details?.plugin_service ? screenInfo?.device_details?.plugin_service : 'N/A'}
                        </Typography>
                      </Box>
                    </ListItem>

                    <ListItem disableGutters divider sx={{ justifyContent: "space-between", padding: 2 }}>
                      <Typography variant="subtitle2">{t("views.screenDetail.rootLevel")}</Typography>
                      <Typography color="text.secondary" variant="body2">
                        {screenInfo?.device_details?.root_level}
                      </Typography>
                    </ListItem>

                    <ListItem disableGutters divider sx={{ justifyContent: "space-between", padding: 2 }}>
                      <Typography variant="subtitle2">{t("views.screenDetail.resolution")}</Typography>
                      <Box sx={{textAlign: 'end'}}>
                        <Typography color="text.secondary" variant="body2">
                          {`${screenInfo?.device_details?.width} x ${screenInfo?.device_details?.height}
                            ${+screenInfo?.device_details?.width > +screenInfo?.device_details?.height
                              ? t("views.screenDetail.landscape")
                              : t("views.screenDetail.portrait")
                            }
                            `}
                        </Typography>
                        {screenNoDataLogs ? (
                          <Typography sx={{color: 'warning.main'}} variant="caption">
                            {screenNoDataLogs.width ? new Date(screenNoDataLogs.width).toLocaleString() : null}
                          </Typography>
                        ) : null}
                      </Box>
                    </ListItem>
                  </List>
                </Card>
              </Grid>

              {/* right column */}
              <Grid xs={12} md={4}>
                <Card sx={{ p: 2 }}>
                  <Box sx={{display: 'flex', marginBottom: '20px'}}>
                    <Box sx={{ maxWidth: "200px" }}>
                      <div className="ram">
                        <DonutChart
                          views={t("views.screenDetail.ram")}
                          series={[+screenInfo?.ram?.total.split(" ")[0] - +screenInfo?.ram?.free.split(" ")[0], +screenInfo?.ram?.free.split(" ")[0]]}
                          labels={[
                            t("views.screenDetail.usage", { unit: screenInfo?.ram?.total.split(" ")[1] }),
                            t("views.screenDetail.free", { unit: screenInfo?.ram?.free.split(" ")[1] }),
                          ]}
                        />
                      </div>
                    </Box>

                    <Box sx={{ maxWidth: "200px" }}>
                      <div className="storage">
                        <DonutChart
                          views={t("views.screenDetail.storage")}
                          series={[+screenInfo?.storage?.total.split(" ")[0] - +screenInfo?.storage?.free.split(" ")[0], +screenInfo?.storage?.free.split(" ")[0]]}
                          labels={[
                            t("views.screenDetail.usage", { unit: screenInfo?.storage?.total.split(" ")[1] }),
                            t("views.screenDetail.free", { unit: screenInfo?.storage?.free.split(" ")[1] }),
                          ]}
                        />
                      </div>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      background: "white",
                      p: 2,
                      borderRadius: "16px",
                    }}>
                      {screenInfo?.locationDetails ? (
                        // <GoogleMap
                        //   zoom={10}
                        //   center={{ lat: +screenInfo?.locationDetails?.lat, lng: +screenInfo?.locationDetails?.lng }}
                        //   mapContainerStyle={{ height: "300px", width: "100%" }}
                        //   onGoogleApiLoaded={({map, maps}) => this.renderMarkers(map, maps)}
                        //   options={{
                        //     mapTypeControl: false,
                        //     zoomControl: false,
                        //     streetViewControl: false,
                        //     fullscreenControl: false,
                        //   }}>
                        // </GoogleMap>
                        <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY} libraries={["places", "geometry"]}>
                          <Map mapId={"bf51a910020fa25a"} disableDefaultUI={true} defaultZoom={10} style={{ height: "300px" }} center={{ lat: +screenInfo?.locationDetails?.lat, lng: +screenInfo?.locationDetails?.lng }}>
                            <Marker position={{ lat: +screenInfo?.locationDetails?.lat, lng: +screenInfo?.locationDetails?.lng }} title="User address location" />
                          </Map>
                        </APIProvider>
                      ) : (
                        <Typography variant="body1" color="#000">{t("views.screenDetail.noLocationData")}</Typography>
                      )}
                  </Box>
                </Card>
              </Grid>
            </Box>
            <Box xs={12} sx={{ display: "flex", minWidth: "100%", flexDirection: { md: "row", xs: "column" } }}>
              <Box sx={{p:2, display:'flex', width:'100%'}}>
                  <Card sx={{ p: 2, display: 'flex', flexDirection: 'column', width:'100%'}}>
                    <Typography variant="h4">{t('views.screenDetail.internetUsageStatsHeading')}</Typography>
                    {screenInfo?.locationDetails ? (
                      screenInfo?.locationDetails.fullLocation ? (
                        <ScreenDataUsageStatsWidget mac={screenInfo?.mac}/>
                      ) : (
                        <Box>
                          <Typography variant="body1" sx={{marginTop: '20px'}}>
                            {t('components.tableBodies.notAccurateLocation')}
                          </Typography>
                          <Box sx={{width: '100%', marginTop: '20px'}}>
                          <Typography variant="subtitle1">
                            {t("views.redesignScreens.enterLocation")}
                          </Typography>
                          <Autocomplete onLoad={(autocomplete) => (locationRef.current = autocomplete)} onPlaceChanged={onGetLatLonByLocatioChange}>
                            <TextField
                              type={"text"}
                              label={t("views.redesignScreens.location")}
                              variant={"standard"}
                              value={location}
                              onChange={(e) => setLocation(e.target.value)}
                              fullWidth
                              required
                            />
                          </Autocomplete>
                        </Box>
                        {locationDetails ? (
                          <Typography variant="body1" color={locationDetails.labelColor}>{locationDetails.labelMsg}</Typography>
                        ) : null}
                        <Button onClick={handleLocationSubmit} sx={{maxWidth: '300px', marginTop: '20px'}} variant="contained">Submit screen location</Button>
                        </Box>
                      )
                    ) : (
                      <Box sx={{display: 'flex', flexDirection: 'column'}}>
                        <Typography variant="body1" sx={{marginTop: '20px'}}>
                          {t("views.screenDetail.toAccessStatsGiveLocation")}
                        </Typography>
                        <Box sx={{width: '100%', marginTop: '20px'}}>
                          <Typography variant="subtitle1">
                            {t("views.redesignScreens.enterLocation")}
                          </Typography>
                          <Autocomplete onLoad={(autocomplete) => (locationRef.current = autocomplete)} onPlaceChanged={onGetLatLonByLocatioChange}>
                            <TextField
                              type={"text"}
                              label={t("views.redesignScreens.location")}
                              variant={"standard"}
                              value={location}
                              onChange={(e) => setLocation(e.target.value)}
                              fullWidth
                              required
                            />
                          </Autocomplete>
                        </Box>
                        {locationDetails ? (
                          <Typography variant="body1" color={locationDetails.labelColor}>{locationDetails.labelMsg}</Typography>
                        ) : null}
                        <Button onClick={handleLocationSubmit} sx={{maxWidth: '300px', marginTop: '20px'}} variant="contained">{t('views.redesignScreens.save')}</Button>
                      </Box>
                    )}
                  </Card>
              </Box>
            </Box>
          </Grid>
        </Container>
      </Box>
    </Layout>
  );
}

export default ScreenDetail;
