import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import { Table, TableBody, TableCell, TableRow, Checkbox, Typography, Button, Box, Card, Grid, List, ListItem, ListItemIcon, ListItemText, IconButton, Backdrop, CircularProgress, Menu, MenuItem, Divider } from "@mui/material";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { useTheme } from "@mui/material/styles";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { parsePrice } from "src/utils/parse-price";
import { api } from "src/api/endpoints";
import { AddressElement, CardElement, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import toast from "react-hot-toast";
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import FlagIcon from '@mui/icons-material/Flag';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import PlaceIcon from '@mui/icons-material/Place';
import MarkunreadMailboxIcon from '@mui/icons-material/MarkunreadMailbox';
import EditIcon from '@mui/icons-material/Edit';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import AddIcon from '@mui/icons-material/Add';
import PaymentIcon from '@mui/icons-material/Payment';
import DeleteIcon from '@mui/icons-material/Delete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ConfirmCancelSubscription from "../components/ConfirmCancelSubscription";
import { useNavigate } from "react-router-dom";

const BillingAccountAdminTab = ({ customerDetails, customerPaymentMethods, stripeCustomerData, currentUser }) => {
    const { t } = useTranslation();
    const navigate = useNavigate()

    const [ customerPlan, setCustomerPlan ] = useState(null)
    const [ customerAdditionalScreensSI, setCustomerAdditionalScreensSI ] = useState(null)
    const [additionalScreensAmount, setAdditionalScreensAmount] = useState('')
    const [openCancelSubscriptionDialog, setOpenCancelSubscriptionDialog] = useState(false)

    useEffect(() => {
        setCustomerPlan(stripeCustomerData.subscriptions.data[0].items.data.find(si => si.planDetails?.planType == 'plan'))
        setCustomerAdditionalScreensSI(stripeCustomerData.subscriptions.data[0].items.data.find(si => si.planDetails?.name == 'Additional screen'))
        setAdditionalScreensAmount(stripeCustomerData.subscriptions.data[0].items.data.find(si => si.planDetails?.name == 'Additional screen') ? stripeCustomerData.subscriptions.data[0].items.data.find(si => si.planDetails?.name == 'Additional screen').quantity : '0')
    }, [])

    const [menuEl, setMenuEl] = useState(null);
    const moreOptionsOpen = Boolean(menuEl);

    const handleMoreOptionsClick = (event) => {
      setMenuEl(event.currentTarget);
    };

    const handleMoreOptionsClose = () => {
      setMenuEl(null);
    };

    const cancelUserSubscription = async () => {
        handleMoreOptionsClose()
        if(customerPlan){
            try {
                await api.post('/payment/cancelUserPlanSubscription', {
                    subId: stripeCustomerData.subscriptions.data[0].id
                })
                toast.success("User's subscription successfully cancelled")
                window.location.replace('/users')
                setOpenCancelSubscriptionDialog(false)
            }catch (error) {
                toast.error(error.response.data)
                setOpenCancelSubscriptionDialog(false)
            }
        }else{
            toast.error("The user does not have a subscription")
        }
    }

    // const giveUser

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6} lg={4}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <Card variant="outlined" sx={{
                            width: '100%',
                            overflow: 'auto'
                        }}>
                                <Box sx={{px: 1, py:3, minWidth: '410px'}}>
                                    <Box sx={{display: 'flex', justifyContent: "space-between", gap: '10px', alignItems: 'center', px: 2}}>
                                        <Typography variant="h6">Billing account data</Typography>
                                    </Box>
                                    <Grid container spacing={1}>
                                        <Grid item xs={6}>
                                            <List>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <PersonIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={customerDetails.name}
                                                        secondary="Name"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <FlagIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={customerDetails.shipping.address.country}
                                                        secondary="Country"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <MarkunreadMailboxIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={customerDetails.shipping.address.postal_code}
                                                        secondary="Postal code"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <PlaceIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={customerDetails.shipping.address.line2 ? customerDetails.shipping.address.line2 : '-'}
                                                        secondary="Address line 2"
                                                    />
                                                </ListItem>
                                            </List>
                                        </Grid>
                                        <Grid item xs={6}>
                                            <List>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <EmailIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={customerDetails.email}
                                                        secondary="Email address"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <LocationCityIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={customerDetails.shipping.address.city}
                                                        secondary="City"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <PlaceIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={customerDetails.shipping.address.line1}
                                                        secondary="Address line 1"
                                                    />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <LocalPhoneIcon />
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={customerDetails.phone}
                                                        secondary="Phone"
                                                    />
                                                </ListItem>
                                            </List>
                                        </Grid>
                                    </Grid>
                                    <Box sx={{px: 2, display: 'flex', flexDirection: 'column', gap: '20px'}}>
                                        <Typography variant="h6" sx={{mt:2}}>Payment methods</Typography>
                                        <List>
                                            {customerPaymentMethods.map(pm => {
                                                return (
                                                    <ListItem key={pm.id}>
                                                        <ListItemIcon>
                                                            <PaymentIcon/>
                                                        </ListItemIcon>
                                                        <ListItemText secondary="Credit card" primary={`${pm.card?.brand.charAt(0).toUpperCase()}${pm.card?.brand.slice(1)} **** ${pm.card?.last4}`}/>
                                                    </ListItem>
                                                )
                                            })}
                                        </List>
                                    </Box>
                                </Box>
                        </Card>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={8}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <Card variant="outlined" sx={{
                            width: '100%',
                            overflow: 'auto'
                        }}>
                            <Box sx={{p: 3, display: 'flex', flexDirection: 'column', gap: '10px'}}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'flex-end'
                                }}>
                                    <Typography variant="h6">Plan</Typography>
                                    <Button endIcon={<ExpandMoreIcon />} onClick={handleMoreOptionsClick}>Options</Button>
                                    <Menu anchorEl={menuEl} open={moreOptionsOpen} onClose={handleMoreOptionsClose}>
                                        <MenuItem onClick={() => setOpenCancelSubscriptionDialog(true)}>Cancel subscription</MenuItem>
                                        <MenuItem onClick={handleMoreOptionsClose}>Contact with subscriber</MenuItem>
                                    </Menu>
                                </Box>
                                <Typography variant='h4'>{customerPlan ? customerPlan.planDetails.name : 'No data'}</Typography>
                                <Typography variant='h6'>{customerPlan ? parsePrice(customerPlan.price.unit_amount) : 'No data'} / {customerPlan ? customerPlan.price.recurring.interval : 'No data'}</Typography>
                                <Divider sx={{
                                    pb: 1
                                }}/>
                                <Typography variant="h6" mt={1}>Screens</Typography>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'baseline',
                                    gap: '10px'
                                }}>
                                    <Typography variant="h4">{customerAdditionalScreensSI ? `${customerAdditionalScreensSI.quantity}` : '0'}</Typography>
                                    <Typography variant="h6">x</Typography>
                                    <Typography variant="h6">{customerAdditionalScreensSI ? parsePrice(customerAdditionalScreensSI.price.unit_amount) : '$0'}</Typography>
                                    <Typography variant="h6">/</Typography>
                                    <Typography variant="h6">{customerAdditionalScreensSI ? customerAdditionalScreensSI.price.recurring.interval : '-'}</Typography>
                                </Box>
                                <Box sx={{
                                    display: 'flex',
                                    alignItems: 'baseline',
                                    gap: '10px'
                                }}>
                                    <Typography variant="h6">{currentUser ? `${currentUser.plan.planFeatures.find(pf => pf.slug == 'max_screens').value}` : 'No data'}</Typography>
                                    <Typography variant="h6" color="textSecondary">Free</Typography>
                                </Box>
                            </Box>
                        </Card>
                    </Box>
                </Grid>
            </Grid>
            <ConfirmCancelSubscription open={openCancelSubscriptionDialog} setOpen={setOpenCancelSubscriptionDialog} handleCancel={cancelUserSubscription} />
        </>
    );
};

export default BillingAccountAdminTab;
