import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import FeatureManagerListSearch from "./components/FeatureManagerListSearch";
import FeatureManagerListTable from "./components/FeatureManagerListTable";
import Layout from "src/components/layout/Layout";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import ClearIcon from "@mui/icons-material/Clear";
import LockOutlineIcon from '@mui/icons-material/LockOutlined';
import { useSettings } from "src/hooks/use-settings";
import { usePageView } from "src/hooks/use-page-view";
import SaveIcon from "@mui/icons-material/Save";
import { getPlans, savePlans, changePlanFeatureDraft, searchPlanFeatures } from "../../redux/actions/Plan";
import BreadCrumbs from "src/components/common/BreadCrumbs";
import { Switch, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Backdrop, CircularProgress } from "@mui/material";
import { api } from "src/api/endpoints";
import { listFeaturesLocks, setFeatureLock, setLoading } from "src/redux/actions/FeaturesLock";

const useCustomersSearch = () => {
  const [state, setState] = useState({
    filters: {
      query: undefined,
      isAdmin: undefined,
      isActive: undefined,
    },
    page: 0,
    rowsPerPage: 5,
    sortBy: "updatedAt",
    sortDir: "desc",
  });

  const handleFiltersChange = useCallback((filters) => {
    setState((prevState) => ({
      ...prevState,
      filters,
    }));
  }, []);

  const handleSortChange = useCallback((sort) => {
    setState((prevState) => ({
      ...prevState,
      sortBy: sort.sortBy,
      sortDir: sort.sortDir,
    }));
  }, []);

  const handlePageChange = useCallback((event, page) => {
    setState((prevState) => ({
      ...prevState,
      page,
    }));
  }, []);

  const handleRowsPerPageChange = useCallback((event) => {
    setState((prevState) => ({
      ...prevState,
      rowsPerPage: parseInt(event.target.value, 10),
    }));
  }, []);

  return {
    handleFiltersChange,
    handleSortChange,
    handlePageChange,
    handleRowsPerPageChange,
    state,
  };
};

const Page = ({ user }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [editing, setEditing] = useState(false);
  const [featresLockDialogOpen, setFeatresLockDialogOpen] = useState(false)

  const settings = useSettings();
  usePageView();

  const { plans, searchResults, plan } = useSelector((state) => state.Plan);

  const { featureLocksList, featuresLockLoading } = useSelector((state) => state.FeaturesLocks)

  console.log("SEARCH RESULTS: ", searchResults,plans);
  useEffect(() => {
    dispatch(getPlans());
    // dispatch(listFeaturesLocks())
  }, []);

  useEffect(() => {
    const stopper = (e) => {
      e.preventDefault();
      e.stopImmediatePropagation();
      e.target.removeEventListener("click", stopper);
    };

    const blocker = (e) => {
      if (!editing) return;

      const anchor = e.target.closest("a");

      if (!anchor?.contains(e.target)) return;

      if (!window.confirm(t("views.featureManagement.leaveUnsavedChanges"))) {
        e.target.addEventListener("click", stopper);
        return;
      }

      handlePlansCancel();
    };

    document.body.addEventListener("pointerup", blocker);

    return () => {
      document.body.removeEventListener("pointerup", blocker);
    };
  }, [editing, t]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (editing) {
        event.preventDefault();
        event.returnValue = ""; // This is necessary for some browsers
        return "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload, { capture: true });

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload, { capture: true });
    };
  }, [editing]);

  const handlePlanFeatureChange = useCallback(
    (featureId, planId, enabled, regex = null) => {
      setEditing(true);
      let value = enabled ? 1 : 0;
      dispatch(changePlanFeatureDraft({ featureId: featureId, planId: planId, enabled: enabled, value: value }));
    },
    [plans]
  );

  const handlePlanFeatureValueChange = useCallback(
    (featureId, planId, value) => {
      setEditing(true);
      let enabled = value !== "" ? true : false;
      dispatch(changePlanFeatureDraft({ featureId: featureId, planId: planId, enabled: enabled, value: value }));
    },
    [plans]
  );

  const handlePlansSave = useCallback(() => {
    setEditing(false);
    console.log("Saving plans: ", plans);
    dispatch(savePlans(plans));
    toast.success(t("views.featureManagement.changesSaved"));
  }, [plans, t]);

  const handlePlansCancel = useCallback(() => {
    setEditing(false);
    dispatch(getPlans());
  }, []);

  const handleSearchChange = useCallback(
    (value) => {
      dispatch(searchPlanFeatures(value));
    },
    [searchResults]
  );

  const handleFeatureLockSet = (flId, lockStatus) => {
    dispatch(setLoading(true))
    dispatch(setFeatureLock(flId, lockStatus))
  }

  return (
    <Layout title={t("views.featureManagement.title")} user={user}>
      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={featuresLockLoading}
        onClick={() => dispatch(setLoading(false))}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box component="main" sx={{ flexGrow: 1, py: 2 }}>
        <Container maxWidth={settings.stretch ? false : "xl"}>
          <Button variant="outlined" startIcon={<LockOutlineIcon />} onClick={() => setFeatresLockDialogOpen(true)} >Features Locks</Button>
          <Stack spacing={4} mt={3}>
            {/* <BreadCrumbs title={t("layout.sidebar.featureManager")} /> */}
            <FeatureManagerListSearch handleSearch={handleSearchChange} />
            <FeatureManagerListTable
              plans={searchResults.length > 0 ? searchResults : plans}
              handlePlanFeatureChange={handlePlanFeatureChange}
              handlePlanFeatureValueChange={handlePlanFeatureValueChange}
            />
          </Stack>
          <Stack direction="row" justifyContent="end" alignItems="end" spacing={4} sx={{ py: 4 }}>
            <div>
              {editing && (
                <Button
                  onClick={handlePlansCancel}
                  sx={{ marginRight: "15px" }}
                  startIcon={
                    <SvgIcon>
                      <ClearIcon />
                    </SvgIcon>
                  }
                  variant="contained">
                  {t("views.featureManagement.cancel")}
                </Button>
              )}
              <Button
                onClick={handlePlansSave}
                startIcon={
                  <SvgIcon>
                    <SaveIcon />
                  </SvgIcon>
                }
                variant="contained">
                {t("views.featureManagement.save")}
              </Button>
            </div>
          </Stack>
        </Container>
      </Box>
      <Dialog
        open={featresLockDialogOpen}
        onClose={() => setFeatresLockDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">
          Lock features
        </DialogTitle>
        <DialogContent>
          <Table sx={{
            width: '100%'
          }}>
            <TableHead>
              <TableRow>
                <TableCell align="center">Feature name</TableCell>
                <TableCell align="center">Lock status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {featureLocksList.length > 0 ? (
                featureLocksList.map(fl => {
                  return (
                    <TableRow key={fl._id}>
                      <TableCell align="center">
                        <Typography variant="subtitle1">{fl.featureName}</Typography>
                      </TableCell>
                      <TableCell align="center">
                        <Box sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}>
                          {fl.lock ? (
                            <Tooltip title="Feature not available for users" placement="top">
                              <LockOutlineIcon color="error"/>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Feature available for users" placement="top">
                              <LockOpenIcon color="success"/>
                            </Tooltip>
                          )}
                          <Switch checked={fl.lock} onChange={(e) => handleFeatureLockSet(fl._id, e.target.checked)} />
                        </Box>
                      </TableCell>
                    </TableRow>
                  )
                })
              ) : (
                <Typography></Typography>
              )}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setFeatresLockDialogOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </Layout>
  );
};

export default Page;
