import React, { useState, useEffect, useRef } from "react";
import { Typography, Box, Button } from "@mui/material";
import { useTranslation } from "react-i18next";
import { baseImg } from "../../../api/endpoints";
import { FaBars } from "react-icons/fa6";
import { AiFillSound, RiVolumeMuteFill } from "react-icons/ai";
import { FitScreen, RotationTypes } from "src/views/files/components/EditScreen/types";
import { formatWebsiteUrl } from "src/views/files/Widgets/DialogBoxWebSite";
import { useTransition } from "react";

import CardWeather from "../../files/Widgets/CardWeather";
import PlayerScaler from "./PlayerScaler";

import { resolveSrc } from "../../../api/endpoints";

const baseUrl = process.env.REACT_APP_BASE_URL;
const hostname = new URL(baseUrl).hostname;
export const WebsiteComponent = ({ featureUrl, commonProps, styleCustom = null, muted = false }) => {
  const { t } = useTranslation();
  // const [error, setError] = useState(null);
  // const iframeRef = useRef(null);

  // useEffect(() => {
  //   const checkIframeContent = () => {
  //     if (iframeRef.current) {
  //       try {
  //         // Próba dostępu do zawartości iframe
  //         const iframeDoc = iframeRef.current.contentDocument || iframeRef.current.contentWindow.document;

  //         // Jeśli zawartość jest pusta, prawdopodobnie mamy do czynienia z błędem CSP
  //         if (iframeDoc.body.innerHTML === "") {
  //           setError(true);
  //         }
  //         setError(null);
  //       } catch (e) {
  //         // Jeśli nie możemy uzyskać dostępu do zawartości, to również może być błąd CSP
  //         setError(true);
  //       }
  //     }
  //   };

  //   // Sprawdzamy zawartość iframe po krótkim opóźnieniu
  //   const timer = setTimeout(checkIframeContent, 200);

  //   return () => clearTimeout(timer);
  // }, [featureUrl]);

  // const ErrorComponent = ({ error, featureUrl, styleCustom, commonProps, t }) => {
  //   const style = {
  //     background: "#010b24",
  //     display: "flex",
  //     flexDirection: "column",
  //     justifyContent: "center",
  //     alignItems: "center",
  //     textAlign: "center",
  //     ...(styleCustom || commonProps.style),
  //   };

  //   return (
  //     <div style={style}>
  //       <p>{t("views.player.errorTitle")}</p>
  //       <p>URL: {formatWebsiteUrl(featureUrl)}</p>
  //       <p>{t("views.player.errorSubTitle")}</p>
  //       <a href={formatWebsiteUrl(featureUrl)} target="_blank" rel="noopener noreferrer">
  //         {t("views.player.errorButtonText")}
  //       </a>
  //     </div>
  //   );
  // };

  // if (error) {
  //   console.log("Error:", error);
  //   return <ErrorComponent error={error} featureUrl={featureUrl} styleCustom={styleCustom} commonProps={commonProps} t={t} />;
  // }

  // return styleCustom ? (
  //   <iframe
  //     ref={iframeRef}
  //     src={formatWebsiteUrl(featureUrl)}
  //     {...commonProps}
  //     sandbox="allow-scripts allow-same-origin"
  //     title="Website"
  //     onLoad={() => console.log("Website iframe loaded")}
  //     style={styleCustom}
  //     muted={muted}
  //   />
  // ) : (
  //   <iframe
  //     ref={iframeRef}
  //     src={formatWebsiteUrl(featureUrl)}
  //     {...commonProps}
  //     sandbox="allow-scripts allow-same-origin"
  //     title="Website"
  //     onLoad={() => console.log("Website iframe loaded")}
  //     style={{
  //       ...commonProps.style,
  //       border: "none",
  //     }}
  //     muted={muted}
  //   />
  // );
  return (
    <Box sx={{display: 'flex', flexDirection: 'column', alignItems:'center'}}>
      <h2>{t('views.player.contentPreview')}</h2>
      <a href={formatWebsiteUrl(featureUrl)} target="_blank"><Button variant="contained">{t('views.player.openLink')}</Button></a>
    </Box>
  )
};

const parseScrollingStripHtml = (html) => {
  console.log("Parsing HTML:", html);
  try {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    const styleContent = Array.from(doc.getElementsByTagName("style"))
      .map((style) => style.textContent)
      .join("\n");

    const mainDiv = doc.body.querySelector("div") || doc.body;
    const innerDiv = mainDiv.querySelector(".textPreview") || mainDiv.querySelector("div");

    const content = innerDiv ? innerDiv.textContent.trim() : mainDiv.textContent.trim();
    const divStyle = mainDiv.getAttribute("style") || "";
    const innerDivStyle = innerDiv ? innerDiv.getAttribute("style") : "";

    console.log("Parsed content:", { styleContent, content, divStyle, innerDivStyle });
    return { styleContent, content, divStyle, innerDivStyle };
  } catch (error) {
    console.error("Error parsing HTML:", error);
    return { styleContent: "", content: html, divStyle: "", innerDivStyle: "" };
  }
};

export const renderScrollingStrip = (htmlContent) => {
  console.log("Rendering scrolling strip with content:", htmlContent);
  try {
    const { styleContent, content, divStyle, innerDivStyle } = parseScrollingStripHtml(htmlContent);

    // Create a style object from the parsed divStyle string
    const outerDivStyle = divStyle.split(";").reduce((acc, style) => {
      const [key, value] = style.split(":").map((s) => s.trim());
      if (key && value) {
        acc[key.replace(/-./g, (x) => x[1].toUpperCase())] = value;
      }
      return acc;
    }, {});

    // Create a style object from the parsed innerDivStyle string
    const textPreviewStyle = innerDivStyle.split(";").reduce((acc, style) => {
      const [key, value] = style.split(":").map((s) => s.trim());
      if (key && value) {
        acc[key.replace(/-./g, (x) => x[1].toUpperCase())] = value;
      }
      return acc;
    }, {});

    return (
      <div style={{ height: "100%", width: "100%", overflow: "hidden" }}>
        <style>{styleContent}</style>
        <div
          style={{
            ...outerDivStyle,
            height: "100%",
            width: "100%",
            overflow: "hidden",
            position: "relative",
          }}>
          <div
            className="textPreview"
            style={{
              ...textPreviewStyle,
              position: "absolute",
              width: "100%",
              whiteSpace: "nowrap",
              animation: "marquee 10s linear infinite",
            }}>
            {content}
          </div>
        </div>
        <style>{`
          @keyframes marquee {
            0% { transform: translateX(100%); }
            100% { transform: translateX(-100%); }
          }
        `}</style>
      </div>
    );
  } catch (error) {
    console.error("Error in renderScrollingStrip:", error);
    return <div>Error rendering scrolling strip: {error.message}</div>;
  }
};
function Player({ open, onClose, player }) {
  const { t } = useTranslation();
  const [isMainElementLoaded, setIsMainElementLoaded] = useState(false);
  const defaultOrientation = player?.orientation?.toString() === "270" || player?.orientation?.toString() === "90" ? "270" : "landscape";
  const [orientation, setOrientation] = useState(defaultOrientation);
  const [displayDimensions, setDisplayDimensions] = useState({ width: "calc(85vh * 16 / 9)", height: "85vh" });
  //  console.log("Player:", player);
  useEffect(() => {
    updateDisplayBasedOnOrientation(orientation);
  }, [orientation]);

  useEffect(() => {
    if (player && player.length > 0) {
      setIsMainElementLoaded(true);
    }
  }, [player]);

  const updateDisplayBasedOnOrientation = (newOrientation) => {
    if (newOrientation === "270" || newOrientation === "90") {
      setDisplayDimensions({ width: "calc(85vh * 9 / 16)", height: "85vh" });
    } else {
      setDisplayDimensions({ width: "calc(85vh * 16 / 9)", height: "85vh" });
    }
  };

  const handleOrientationChange = (e) => {
    setOrientation(e.target.value);
  };

  const handleContentFit = (content) => {
    console.log("Content:", content);
    if (!content || !content.fit_screen) return "contain";
    switch (content.fit_screen) {
      case FitScreen.FIT:
        return "contain";
      case FitScreen.NONE:
        return "none";
      case FitScreen.ZOOM:
        return "cover";
      case FitScreen.STRETCH:
        return "fill";
      default:
        return "contain";
    }
  };

  const getContentStyle = (content, maxHeight) => ({
    width: "100%",
    height: orientation === "landscape" || maxHeight ? "100%" : undefined,
    objectFit: handleContentFit(content),
  });

  const renderContent = (x) => {
    console.log("Rendering content:", x);
    if (!x) {
      console.log("No content or widget to render");
      return null;
    }
    const commonProps = {
      style: getContentStyle(x),
    };
    if (x.object) {
      x = x.object;
    }

    const featureUrl =
      x.type === "Playlist"
        ? x.playlistContent?.type === "widget"
          ? x.playlistContent?.widget?.url
          : x.playlistContent?.url
        : x.type === "widget"
        ? x.widget?.url
        : x.url;
    const featureHtml = x.type === "Playlist" ? x.playlistContent?.widget?.html : x.widget?.html;
    const widgetCat = x.type === "Playlist" ? x.playlistContent?.widget?.widgetCat : x.widget?.widgetCat;
    const { widget } = x;
    console.log("Widget:", x);

    console.log("Common props:", commonProps);
    console.log("Feature URL:", featureUrl);
    console.log("Feature HTML:", featureHtml);
    try {
      switch (x.type) {
        case "image":
          return <img src={resolveSrc(featureUrl)} {...commonProps} alt="" />;
        case "video":
          return <video autoPlay loop src={resolveSrc(featureUrl)} {...commonProps} />;
        case "embed":
          return <embed src={featureUrl} style={getContentStyle(x, true)} controls />;
        case "widget":
          switch (widget.widgetCat) {
            case "youtube":
              return (
                <iframe
                  src={`${widget.url}?controls=0&modestbranding=1&autoplay=1`}
                  {...commonProps}
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  title="YouTube video"
                />
              );
            case "canva":
              return (
                <div
                  style={{
                    ...getContentStyle(x, true),
                    overflow: "auto",
                  }}
                  dangerouslySetInnerHTML={{ __html: widget.html?.replaceAll(">\\n", "<br/>").replaceAll("\\", "") }}
                />
              );
            case "postermywall":
              return <WebsiteComponent featureUrl={featureUrl} commonProps={commonProps}/>
            case "vimeo":
              return <iframe src={featureUrl} {...commonProps} title="Vimeo video" />;
            case "website":
              return <WebsiteComponent featureUrl={featureUrl} commonProps={commonProps} />;
            case "scrollingstrip":
              const htmlContent = featureHtml || featureUrl;
              return renderScrollingStrip(htmlContent);
            case "embed":
              return <div style={getContentStyle()} dangerouslySetInnerHTML={{ __html: featureHtml }}></div>;
            case "weather":
              return <div style={{ height: "100%", width: "100%", overflow: "auto" }}><CardWeather item={x.widget}/></div>
            case "yt_playlist":
              return (
                <iframe
                  title="YouTube Playlist"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  src={(() => {
                    try {
                      const url = new URL(featureUrl);
                      const listParam = url.searchParams.get("list");
                      if (listParam) {
                        return `https://www.youtube.com/embed?listType=playlist&list=${listParam}&autoplay=1&controls=0&modestbranding=1`;
                      } else {
                        const videoId = url.searchParams.get("v") || url.pathname.split("/").pop();
                        return `https://www.youtube.com/embed/${videoId}?autoplay=1&controls=0&modestbranding=1`;
                      }
                    } catch (error) {
                      console.error("Error processing YouTube URL:", error);
                      return featureUrl;
                    }
                  })()}
                  style={{ ...commonProps.style, border: "none" }}
                  allowFullScreen
                />
              );
            case "twitch":
              return (
                <div style={{ ...getContentStyle(x), display: "flex" }}>
                  <iframe
                    title="Twitch Live Stream"
                    src={(() => {
                      const url = featureUrl;
                      if (url.includes("/videos/")) {
                        return `https://player.twitch.tv/?video=${url.split("/videos/")[1]}&parent=${hostname}&autoplay=true`;
                      } else if (url.includes("/clip/")) {
                        return `https://clips.twitch.tv/embed?clip=${url.split("/clip/")[1]}&parent=${hostname}&autoplay=true`;
                      } else {
                        return `https://player.twitch.tv/?channel=${url.split("/").pop()}&parent=${hostname}&autoplay=true`;
                      }
                    })()}
                    height="100%"
                    width={widget.chat && !featureUrl.includes("/clip/") ? "70%" : "100%"}
                    allowFullScreen
                    frameBorder="0"
                  />
                  {widget.chat && !featureUrl.includes("/clip/") && (
                    <iframe
                      src={(() => {
                        const url = featureUrl;
                        let channelName;
                        if (url.includes("/videos/")) {
                          channelName = url.split("/")[3];
                        } else {
                          channelName = url.split("/").pop();
                        }
                        return `https://www.twitch.tv/embed/${channelName}/chat?parent=${hostname}&darkpopout=${widget.theme === "Dark Theme"}`;
                      })()}
                      height="100%"
                      width="30%"
                      frameBorder="0"
                      title="Twitch Chat"
                    />
                  )}
                </div>
              );
            default:
              console.warn(`Unsupported widget category: ${widgetCat}`);
              return null;
          }
        default:
          console.warn(`Unsupported feature type: ${x.type}`);
          return null;
      }
    } catch (error) {
      console.error("Error in renderContent:", error);
      return null;
    }
  };
  console.log("Player:", player);
  return (
    <Box
      sx={{
        minHeight: "100vh",
        maxWidth: "100vw",
        background: "#0e1320",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000000000000,
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
      }}>
      <Box
        sx={{
          height: "10vh",
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}>
        <Box sx={{ padding: "0 20px" }}>
          <Typography variant="h4">{t("views.player.title")}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            margin: "0 10px",
            position: "relative",
          }}>
          <select
            value={orientation}
            onChange={handleOrientationChange}
            style={{
              height: "40px",
              width: "200px",
              outline: "none",
              padding: "4px 10px",
              borderRadius: "5px",
              border: "1px solid black",
            }}>
            <option value="landscape">{t("views.player.landscapeResolution")}</option>
            <option value="270">{t("views.player.portraitResolution")}</option>
          </select>
          <Button variant={"contained"} sx={{ borderRadius: "5px", textTransform: "capitalize" }} onClick={onClose}>
            {t("views.player.close")}
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          height: displayDimensions.height,
          width: displayDimensions.width,
          position: "relative",
          overflow: "hidden",
          borderRadius: "10px",
        }}>
        {player && player.widget ? (
          player.widget.zones && player.widget.zones.length > 0 ? (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                position: "relative",
              }}>
              {player.widget.zones.map((zone, index) => (
                <Box
                  key={index}
                  sx={{
                    height: `${zone.height * 100}%`,
                    width: `${zone.width * 100}%`,
                    position: "absolute",
                    left: `${zone.x * 100}%`,
                    top: `${zone.y * 100}%`,
                    zIndex: 999999999999,
                    overflow: "hidden",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}>
                  {renderContent(zone)}
                </Box>
              ))}
            </Box>
          ) : (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              {renderContent(player)}
            </Box>
          )
        ) : (
          <Typography>No content to display</Typography>
        )}
      </Box>
    </Box>
  );
}

export default Player;
