import { useEffect, useCallback, useState } from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";
import { Backdrop, Button, CircularProgress, IconButton, Tab, Tabs, Typography } from "@mui/material";
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useSelector } from "react-redux";
import PlansFeaturesTable from "./components/MySubscriptionTabs/PlansFeaturesTable";
import { api } from "src/api/endpoints";
import toast from "react-hot-toast";
import { loadStripe } from "@stripe/stripe-js";
import SubscriptionManager from "./components/MySubscriptionTabs/SubscriptionManager";
import BillingAccount from "./components/MySubscriptionTabs/BillingAccount";
import { Elements } from "@stripe/react-stripe-js";
import ChoosePriceDialog from './components/ChoosePriceDialog'
import Checkout from "./components/MySubscriptionTabs/Checkout";
import { parsePrice } from "src/utils/parse-price";
import RedesignSubscriptionManager from "./components/MySubscriptionTabs/RedesignSubscriptionManager";
import ChangePlanCheckout from "./components/MySubscriptionTabs/ChangePlanCheckout";
import EditIcon from '@mui/icons-material/Edit';
import PlanBuyCheckout from "./components/MySubscriptionTabs/PlanBuyCheckout";

const stripe = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)

function MySubscriptions({ user, handleTabsChange, setStripeClientSecret }) {
  const { t } = useTranslation();
  const { plans, additionalFeatures } = useSelector((state) => state.Plan);

  const [billingType, setBillingType] = useState('month');
  const [choosedProductPrices, setChoosedProductPrices] = useState({modalOpen: false, data: null, plan: null, priceId: '', type: ''})
  const [currentMySubscriptionTab, setCurrentMySubscriptionTab] = useState('mySubscriptions')

  const [customerPaymentMethods, setCustomerPaymentMethods] = useState(null)
  const [customerDetails, setCustomerDetails] = useState(null)
  const [stripeCustomerData, setStripeCustomerData] = useState(null)

  const [additionalScreenEditData, setAdditionalScreenEditData] = useState(null)

  const [loadingBackdrop, setLoadingBackdrop] = useState(false)

  const handleBillingType = (event, newBillingType) => {
    setBillingType(newBillingType);
  };

  // const [ openCreateCustomerDialog, setOpenCreateCustomerDialog ] = useState(false)
  // const [ newCustomerId, setNewCustomerId ] = useState('')

  // const [ customerSubscriptionDetails, setCustomerSubscriptionDetails ] = useState(null)

  // const [ openCustomerDetailsDialog, setOpenCustomerDetailsDialog ] = useState(false)
  // const [ customerDetails, setCustomerDetails ] = useState(null)

    const [pricesList, setPricesList] = useState([])
  // const [subscriptionManagerOpen, setSubscriptionManagerOpen] = useState(false)

  // const [customerAndSubscriptions, setCustomerAndSubscriptions] = useState(null)
  // const [userPlanSubscription, setUserPlanSubscription] = useState(null)

  useEffect(() => {
    async function getDataOnStartup() {
        try {
          const pricesRes = await api.get('/payment/getPrices')

          setPricesList(pricesRes.data.prices)
  
          if(user?.stripeCustomerId){
            const customerDetailsRes = await api.post('/payment/getCustomerDetails', {customerId: user.stripeCustomerId})
            const customerPaymentMethodsRes = await api.post('/payment/listUsersPaymentMethods', {customerId: user.stripeCustomerId})
            const stripeCustomerDataRes = await api.post('/payment/getCustomerAndSubscriptions', {customerId: user.stripeCustomerId})

            setCustomerPaymentMethods(customerPaymentMethodsRes.data.methods)
            setCustomerDetails(customerDetailsRes.data.customer)
            setStripeCustomerData(stripeCustomerDataRes.data.customer)
          }

        } catch (error) {

        }
    }
    getDataOnStartup()
  }, [user])

  const handleBuyPlan = async (plan, type) => {
    if(plan){
      setLoadingBackdrop(true)
      try{
        const resp = await api.post('/payment/getPricesAttachedToProduct', {productId: plan.productId})
        setLoadingBackdrop(false)
        if(type == 'edit'){
          setChoosedProductPrices({modalOpen: true, data:resp.data.prices.data.filter(p => p.recurring.interval == stripeCustomerData.subscriptions.data[0].items.data.find(si => si.planDetails?.planType == 'plan').price.recurring.interval), plan, type})
        }else{
          setChoosedProductPrices({modalOpen: true, data:resp.data.prices.data, plan, type})
        }
      }catch (error){
        setLoadingBackdrop(false)
        console.log(error)
        toast.error("Error while downloading prices")
      }
    }else{
      toast.error("You can't subscribe this plan. Plan doesn't contain price")
    }
  }

  // const handleAdditionalScreensSubscribe = async (priceId, quantity) => { 
  //   if(quantity) {
  //     if(Number(quantity) > 0) {
  //       try {
  //         const resp = await api.post('/payment/buyAdditionalScreens', {
  //           customerId: user.stripeCustomerId,
  //           priceId: priceId,
  //           quantity: Number(quantity)
  //         })
  //         setStripeClientSecret(resp.data.clientSecret)
  //         setTimeout(() => {
  //           handleTabsChange('subscription-payment')
  //         }, 500)
  //       } catch (error) {
  //         toast.error('Additional screens purchase process error')
  //       }
  //     }else{
  //       toast.error("Invalid quantity")
  //     }
  //   }else{
  //     toast.error("Invalid quantity")
  //   }
  //  }

  const handleSubscribe = async (priceId, type) => {
    if(priceId){
      if(type == 'create') {
        setChoosedProductPrices({...choosedProductPrices, priceId: priceId, modalOpen: false})
        setCurrentMySubscriptionTab('planSubscribeCheckout')
      }else if(type == 'edit') {
        setChoosedProductPrices({...choosedProductPrices, priceId: priceId, modalOpen: false})
        setCurrentMySubscriptionTab('planChangeCheckout')
      }
    }else{
      toast.error('Subscription purchase process error')
    }
  }

  const handleConfirmSubscribe = async () => {
    setLoadingBackdrop(true)
    try {
      const resp = await api.post('/payment/createPlanSubscription', {
        customerId: user?.stripeCustomerId,
        priceId: choosedProductPrices.priceId
      })
      setTimeout(() => {
        window.location.replace(`/user-details/${user?._id}#orderconfirm`)
        window.location.reload()
      }, 500 )
    } catch (error) {
      setLoadingBackdrop(false)
      toast.error('Subscription purchase process error')
    }
  }

  const handleConfirmPlanChange = async () => {
    setLoadingBackdrop(true)
    try {
      const resp = await api.post('/payment/updatePlanSubscription', {
        customerId: user?.stripeCustomerId,
        priceId: choosedProductPrices.priceId
      })
      setTimeout(() => {
        window.location.replace(`/user-details/${user?._id}#orderconfirm`)
        window.location.reload()
      }, 500 )
    } catch (error) {
      setLoadingBackdrop(false)
      toast.error('Subscription purchase process error')
    }
  }

  const handleChoosePriceDialogClose = () => {
    setChoosedProductPrices({
      ...choosedProductPrices,
      modalOpen: false
    })
  }

  const handleScreenQuantityChange = (price, quantity, mode) => {
    setAdditionalScreenEditData({price, quantity, mode})
    if(mode == 'create') {
      setCurrentMySubscriptionTab('createAdditionalScreenCheckout')
    }else{
      setCurrentMySubscriptionTab('editAdditionalScreenCheckout')
    }
  }

  const handleUpdateSubscriptionAdditionalFeature = async () => {
    setLoadingBackdrop(true)
      try {
          const reqOb = {
              customerId: user?.stripeCustomerId,
              priceId: additionalScreenEditData.price.id,
              quantity: Number(additionalScreenEditData.quantity)
          }
          if(stripeCustomerData.subscriptions?.data[0]?.items?.data?.find(si => si.planDetails?.name == 'Additional screen')){
              reqOb.siId = stripeCustomerData.subscriptions?.data[0]?.items?.data?.find(si => si.planDetails?.name == 'Additional screen').id
          }
          const resp = await api.post('/payment/updateSubscriptionAdditionalFeature', reqOb)
          setTimeout(() => {
            window.location.replace(`/user-details/${user?._id}#orderconfirm`)
            window.location.reload()
          }, 500)
      } catch (error) {
          setLoadingBackdrop(false)
          toast.error(error.response.data.message)
      }
  }

  return (
    <>
      <Backdrop
        sx={(theme) => ({zIndex: theme.zIndex.drawer + 1 })}
        open={loadingBackdrop}
        onClick={() => setLoadingBackdrop(false)}
      >
        <CircularProgress/>
      </Backdrop>
      <Card sx={{
        overflow: 'visible'
      }}>
          <Box sx={{
            display: 'flex',
            gap: '20px',
            padding: '20px',
            flexDirection: 'column'
          }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', overflow: 'auto'}}>
              <Tabs value={currentMySubscriptionTab} onChange={(event, newValue) => setCurrentMySubscriptionTab(newValue)} sx={{minWidth: '650px'}}>
                <Tab label="Available features" value="featuresList" sx={{px:2}} />
                {user?.plan?.paid && stripeCustomerData ? stripeCustomerData.subscriptions.data.length > 0 ? (
                  <Tab label="My current subscriptions" value="mySubscriptions" sx={{px:2}} />
                ) : null : null}
                <Tab label="Billing account" wrapped value="billingAccount" sx={{px:2}} />
              </Tabs>
            </Box>
            <Box>
              {currentMySubscriptionTab === 'featuresList' && (
                <>
                <Box sx={{
                  maxWidth: 800,
                  mx: 'auto',
                }}>
                  {/* {!user.stripeCustomerId ? (
                      <Button variant="contained" color="success" onClick={() => setCurrentMySubscriptionTab('billingAccount')}>Create billing account</Button>
                  ) : null} */}
                </Box>
                  <Box sx={{
                      display: 'flex',
                      gap: '20px',
                      alignItems: 'center',
                      maxWidth: 800,
                      mx: 'auto',
                      flexWrap: 'wrap'
                  }}>  
                    <ToggleButtonGroup
                        value={billingType}
                        exclusive
                        onChange={handleBillingType}
                        aria-label="Billing type"
                        sx={{
                          my: 3
                        }}
                    >
                        <ToggleButton value="month" aria-label="month">
                            <Typography variant="button">Monthly</Typography>
                        </ToggleButton>
                        <ToggleButton value="year" aria-label="year">
                            <Typography variant="button">Annual</Typography>
                        </ToggleButton>
                    </ToggleButtonGroup>
                    <Box sx={{
                      display: 'flex',
                      gap: '10px',
                      p: 1,
                      alignItems: 'center'
                    }} variant="outlined">
                      <Typography variant="h6">Available screens:</Typography>
                      <Typography variant="h6" color="primary.main">{stripeCustomerData ? stripeCustomerData.subscriptions.data[0]?.items?.data?.find(si => si.planDetails?.name == 'Additional screen') ? 
                        stripeCustomerData.subscriptions.data[0].items.data.find(si => si.planDetails?.name == 'Additional screen').quantity + Number(user?.plan?.planFeatures?.find(pf => pf.slug == 'max_screens').value) : user?.plan?.planFeatures?.find(pf => pf.slug == 'max_screens').value : user?.plan?.planFeatures?.find(pf => pf.slug == 'max_screens').value}
                      </Typography>
                      {user?.plan?.paid && stripeCustomerData ? stripeCustomerData.subscriptions.data.length > 0 ? (
                      <IconButton onClick={() => setCurrentMySubscriptionTab('mySubscriptions')}>
                        <EditIcon fontSize="small" color="primary"/>
                      </IconButton>
                      ) : null : null}
                    </Box>
                    <Box sx={{
                      display: 'flex',
                      gap: '10px',
                      p: 1,
                      alignItems: 'center'
                    }} variant="outlined">
                      <Typography variant="h6">Current plan:</Typography>
                      <Typography variant="h6" color="success.main">{user?.plan?.name}</Typography>
                    </Box>
                  </Box>
                  <PlansFeaturesTable plans={plans} billingType={billingType} showBuyButtons={true} handleBuyPlan={handleBuyPlan} userPlan={user?.plan} prices={pricesList} customerPaymentMethods={customerPaymentMethods} />
                </>
              )}
              {currentMySubscriptionTab === 'mySubscriptions' ? (
                !user?.plan?.paid ? (
                  <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}>
                    <Typography variant="h4">You have no subscription</Typography>
                    <Button onClick={() => setCurrentMySubscriptionTab('featuresList')}>Buy one</Button>
                  </Box>
                ) : (
                  stripeCustomerData ? stripeCustomerData.subscriptions.data.length > 0 ? (
                    <RedesignSubscriptionManager customer={stripeCustomerData} setCurrentTab={setCurrentMySubscriptionTab} plans={plans} user={user} handleScreenQuantityChange={handleScreenQuantityChange} additionalFeatures={additionalFeatures}/>
                  ) : (
                    // <Box sx={{
                    //   mt: 3,
                    //   display: 'flex',
                    //   justifyContent: 'center'
                    // }}>
                    //   <CircularProgress/>
                    // </Box>
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}>
                      <CircularProgress/>
                    </Box>
                  ) : (
                    <Box sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center'
                    }}>
                      <CircularProgress/>
                    </Box>
                  )
                )
              ) : null}
              {currentMySubscriptionTab === 'billingAccount' && (
                <Elements stripe={stripe} options={{appearance: {
                  theme: 'night'
                }}} >
                  <BillingAccount user={user} setCustomerDetails={setCustomerDetails} setCustomerPaymentMethods={setCustomerPaymentMethods} customerDetails={customerDetails} customerPaymentMethods={customerPaymentMethods} />
                </Elements>
              )}
              {currentMySubscriptionTab === 'planSubscribeCheckout' && (
                !user?.stripeCustomerId ? (
                  <Elements stripe={stripe} options={{appearance: {
                    theme: 'night'
                  }}} >
                    <PlanBuyCheckout checkoutData={{
                      orderName: `${choosedProductPrices.plan.name} plan`,
                      interval: choosedProductPrices.data.find(p => p.id == choosedProductPrices.priceId).recurring.interval,
                      realizationTime: 'Immediately',
                      type: 'Subscription',
                      price: parsePrice(choosedProductPrices.data.find(p => p.id == choosedProductPrices.priceId).unit_amount),
                      currency: choosedProductPrices.data.find(p => p.id == choosedProductPrices.priceId).currency.toUpperCase(),
                      customerEmail: user?.email,
                      priceId: choosedProductPrices.priceId
                    }} handleCancel={() => setCurrentMySubscriptionTab('featuresList')} user={user}/>
                  </Elements>
                ) : (
                  <Checkout checkoutData={{
                    orderName: `${choosedProductPrices.plan.name} plan`,
                    interval: choosedProductPrices.data.find(p => p.id == choosedProductPrices.priceId).recurring.interval,
                    realizationTime: 'Immediately',
                    type: 'Subscription',
                    price: parsePrice(choosedProductPrices.data.find(p => p.id == choosedProductPrices.priceId).unit_amount),
                    currency: choosedProductPrices.data.find(p => p.id == choosedProductPrices.priceId).currency.toUpperCase(),
                    customerEmail: user.email,
                    priceId: choosedProductPrices.priceId,
                    cardDetails: `${customerPaymentMethods[0].card?.brand.charAt(0).toUpperCase()}${customerPaymentMethods[0].card?.brand.slice(1)} **** ${customerPaymentMethods[0].card?.last4}`,
                    customerName: customerDetails.name,
                    customerCountry: customerDetails.shipping.address.country,
                    customerCity: customerDetails.shipping.address.city,
                    customerPostalCode: customerDetails.shipping.address.postal_code,
                    customerPhone: customerDetails.phone,
                  }} handleCancel={() => setCurrentMySubscriptionTab('featuresList')} handleSubmit={handleConfirmSubscribe}/>
                )
              )}
              {currentMySubscriptionTab === 'planChangeCheckout' && (
                <ChangePlanCheckout checkoutData={{
                  orderName: `${choosedProductPrices.plan.name} plan`,
                  interval: choosedProductPrices.data.find(p => p.id == choosedProductPrices.priceId).recurring.interval,
                  realizationTime: 'Immediately',
                  type: 'Subscription',
                  price: parsePrice(choosedProductPrices.data.find(p => p.id == choosedProductPrices.priceId).unit_amount),
                  currency: choosedProductPrices.data.find(p => p.id == choosedProductPrices.priceId).currency.toUpperCase(),
                  cardDetails: `${customerPaymentMethods[0].card?.brand.charAt(0).toUpperCase()}${customerPaymentMethods[0].card?.brand.slice(1)} **** ${customerPaymentMethods[0].card?.last4}`,
                  customerName: customerDetails.name,
                  customerCountry: customerDetails.shipping.address.country,
                  customerCity: customerDetails.shipping.address.city,
                  customerPostalCode: customerDetails.shipping.address.postal_code,
                  customerPhone: customerDetails.phone,
                  customerEmail: user?.email,
                }} checkoutPreviousData={{
                  orderName: `${user?.plan?.name} plan`,
                  interval: stripeCustomerData.subscriptions?.data[0]?.items?.data?.find(si => si.planDetails?.planType == 'plan') ? stripeCustomerData.subscriptions.data[0].items.data.find(si => si.planDetails?.planType == 'plan').price.recurring.interval : '',
                  price: stripeCustomerData.subscriptions?.data[0]?.items?.data?.find(si => si.planDetails?.planType == 'plan') ? parsePrice(stripeCustomerData.subscriptions.data[0].items.data.find(si => si.planDetails?.planType == 'plan').price.unit_amount) : '',
                  currency: stripeCustomerData.subscriptions?.data[0]?.items?.data?.find(si => si.planDetails?.planType == 'plan') ? stripeCustomerData.subscriptions.data[0].items.data.find(si => si.planDetails?.planType == 'plan').price.currency : ''
                }} handleSubmit={handleConfirmPlanChange} handleCancel={() => setCurrentMySubscriptionTab('featuresList')} />
              )}
              {currentMySubscriptionTab === 'createAdditionalScreenCheckout' && (
                <Checkout checkoutData={{
                  orderName: `${additionalScreenEditData.quantity} additional screens`,
                  interval: additionalScreenEditData.price.recurring.interval,
                  realizationTime: 'Immediately',
                  type: 'Additional feature',
                  price: parsePrice(additionalScreenEditData.price.unit_amount*additionalScreenEditData.quantity),
                  currency: additionalScreenEditData.price.currency.toUpperCase(),
                  cardDetails: `${customerPaymentMethods[0].card?.brand.charAt(0).toUpperCase()}${customerPaymentMethods[0].card?.brand.slice(1)} **** ${customerPaymentMethods[0].card?.last4}`,
                  customerName: customerDetails.name,
                  customerCountry: customerDetails.shipping.address.country,
                  customerCity: customerDetails.shipping.address.city,
                  customerPostalCode: customerDetails.shipping.address.postal_code,
                  customerPhone: customerDetails.phone,
                  customerEmail: user?.email,
                }} handleCancel={() => setCurrentMySubscriptionTab('mySubscriptions')} handleSubmit={handleUpdateSubscriptionAdditionalFeature}/>
              )}
              {currentMySubscriptionTab === 'editAdditionalScreenCheckout' && (
                <ChangePlanCheckout checkoutData={{
                  orderName: `${additionalScreenEditData.quantity} additional screens`,
                  interval: additionalScreenEditData.price.recurring.interval,
                  realizationTime: 'Immediately',
                  type: 'Additional feature',
                  price: parsePrice(additionalScreenEditData.price.unit_amount*additionalScreenEditData.quantity),
                  currency: additionalScreenEditData.price.currency.toUpperCase(),
                  cardDetails: `${customerPaymentMethods[0].card?.brand.charAt(0).toUpperCase()}${customerPaymentMethods[0].card?.brand.slice(1)} **** ${customerPaymentMethods[0].card?.last4}`,
                  customerName: customerDetails.name,
                  customerCountry: customerDetails.shipping.address.country,
                  customerCity: customerDetails.shipping.address.city,
                  customerPostalCode: customerDetails.shipping.address.postal_code,
                  customerPhone: customerDetails.phone,
                  customerEmail: user?.email,
                }} checkoutPreviousData={{
                  orderName: `${stripeCustomerData.subscriptions?.data[0]?.items?.data?.find(si => si.planDetails?.name == 'Additional screen').quantity} Additional screens`,
                  interval: stripeCustomerData.subscriptions?.data[0]?.items?.data?.find(si => si.planDetails?.name == 'Additional screen').price.recurring.interval,
                  price: parsePrice(stripeCustomerData.subscriptions?.data[0]?.items?.data?.find(si => si.planDetails?.name == 'Additional screen').price.unit_amount*stripeCustomerData.subscriptions?.data[0]?.items?.data?.find(si => si.planDetails?.name == 'Additional screen').quantity),
                  currency: stripeCustomerData.subscriptions?.data[0]?.items?.data?.find(si => si.planDetails?.name == 'Additional screen').price.currency.toUpperCase()
                }} handleCancel={() => setCurrentMySubscriptionTab('mySubscriptions')} handleSubmit={handleUpdateSubscriptionAdditionalFeature}/>
              )}
            </Box>
            {/* { user.stripeCustomerId || newCustomerId ? (
              <Box sx={{display: 'flex', gap: '20px', flexWrap: 'wrap'}}>
                <Button onClick={() => setOpenCustomerDetailsDialog(true)} variant="outlined">My billing account details</Button>
                {userPlanSubscription ? (
                  <Button variant="outlined" onClick={() => setSubscriptionManagerOpen(true)}>Manage my subscription</Button>
                ) : null}
              </Box>
            ) : null }
            <Box
            sx={{
                display: 'flex',
                gap: '10px'
            }}>
                <Typography variant="subtitle2">Current plan:</Typography>
                <Typography variant="subtitle2" color="success.main">{user.plan?.name}</Typography>
            </Box>
            <Box sx={{
                display: 'flex',
                gap: '10px'
            }}>
              { user.stripeCustomerId || newCustomerId ? (
                <>
                  <Typography variant="subtitle2">Subscription status:</Typography>
                  <Typography variant="subtitle2" color={userPlanSubscription ? (userPlanSubscription?.sub.status == 'active' ? 'success.main' : 'warning.main') : 'warning.main'}>
                    {userPlanSubscription ? userPlanSubscription?.sub.status : 'No subscription'}</Typography>
                </>
              ) : (
                <Button variant="contained" onClick={() => setOpenCreateCustomerDialog(true)}>Switch to paid plan</Button>
              ) }
            </Box>
            <Box sx={{
                display: 'flex',
                gap: '20px',
            }}>  
              <ToggleButtonGroup
                  value={billingType}
                  exclusive
                  onChange={handleBillingType}
                  aria-label="Billing type"
              >
                  <ToggleButton value="month" aria-label="month">
                      <Typography variant="button">Monthly</Typography>
                  </ToggleButton>
                  <ToggleButton value="year" aria-label="year">
                      <Typography variant="button">Annual</Typography>
                  </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <PlansFeaturesTable plans={plans} billingType={billingType} showBuyButtons={Boolean(user.stripeCustomerId || newCustomerId)} handleBuyPlan={handleBuyPlan} userPlan={user.plan} prices={pricesList} />
          </Box>
      </Card>
      <Elements stripe={stripe} options={{appearance: {
        theme: 'night'
      }}} >
        <CreateCustomerDialog openCreateCustomerDialog={openCreateCustomerDialog} handleCreateCustomerDialogClose={() => setOpenCreateCustomerDialog(false)} setNewCustomerId={setNewCustomerId} userId={user._id} userEmail={user.email}/>
      </Elements>
      <CustomerDetailsDialog customerDetails={customerDetails} openCustomerDetailsDialog={openCustomerDetailsDialog} setOpenCustomerDetailsDialog={setOpenCustomerDetailsDialog} />*/}
      <ChoosePriceDialog openChoosePriceDialog={choosedProductPrices.modalOpen} handleChoosePriceDialogClose={handleChoosePriceDialogClose} pricesData={choosedProductPrices.data} handleSubscribe={handleSubscribe} choosePriceMode={choosedProductPrices.type} /> 
        </Box>
      </Card>
    </>
  );
}

export default MySubscriptions;
