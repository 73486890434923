import {
    CHANGE_PLAN_FEATURE,
    GET_PLANS_SUCCESS,
    GET_PLAN_SUCCESS,
    CHANGE_PLAN_FEATURE_DRAFT,
    SAVE_PLANS_FEATURES,
    SEARCH_PLANS,
    GET_ADDITIONAL_FEATURES_SUCCESS
} from "../constant";

const initial = {
    plans: [],
    searchResults:[],
    plan: {},
    additionalFeatures: []
};

export const PlanReducer = (state = initial, action) => {
    switch (action.type) {
        case GET_PLANS_SUCCESS:
            return {
                ...state,
                plans: [...action.payload]
            }
        case GET_PLAN_SUCCESS:
            return {
                ...state,
                plan: { ...action.payload }
            }
        case GET_ADDITIONAL_FEATURES_SUCCESS:
            return {
                ...state,
                additionalFeatures: [...action.payload]
            }   
        case CHANGE_PLAN_FEATURE_DRAFT:
            const { planId, featureId, ...settings } = action.payload;

            return {
                ...state,
                plans: state.plans.map(p => p._id == planId ? {
                    ...p,
                    planFeatures: p.planFeatures.map(f => f._id == featureId ? {
                        ...f,
                        ...settings
                    } : f)
                } : p)
            }
        case SAVE_PLANS_FEATURES:
            return state;
        case SEARCH_PLANS:
            const query = action.payload;
            console.log(query);
            let searchResults = [];

            if(query && query !== ''){
                searchResults = state.plans.map((p) => ({
                    ...p,
                    planFeatures: p.planFeatures.filter((feature) => (
                        feature.name.toLowerCase().includes(query))
                    )
                }));
            }
           
            return {
                ...state,
                searchResults: searchResults
            };
        default:
            return state;
    }
};