import { api } from "../../api/endpoints";
import {
    CREATE_PRODUCT,
    CREATE_PRICE,
    PRODUCTS_FAIL,
    EDIT_PRODUCT,
    EDIT_PRICE,
    GET_PRODUCTS,
    GET_PRODUCTS_PRICES,
    ATTACH_PRODUCT_SUCCESS
} from "../constant";


export const createProduct = (obj) => {
    return async (dispatch) => {
        try{
            const response = await api.post(`/products/addProduct`, obj)
            dispatch({
                type: CREATE_PRODUCT,
                payload: response.data.product,
            });
        }catch (error){
            dispatch({
                type: PRODUCTS_FAIL
            }); 
        }
    };
};

export const createPrice = (obj) => {
    return async (dispatch) => {
        try{
            const response = await api.post(`/products/addPrice`, obj);
            dispatch({
                type: CREATE_PRICE,
                payload: response.data.price,
            });
        }catch(error){
            dispatch({
                type: PRODUCTS_FAIL
            }); 
        }
    };
};

export const editProduct = (obj) => {
    return async (dispatch) => {
        try{
            const response = await api.post(`/products/editProduct`, obj);
            dispatch({
                type: EDIT_PRODUCT,
                payload: response.data.product,
            });
        }catch (error){
            dispatch({
                type: PRODUCTS_FAIL
            }); 
        }
    };
};

export const editPrice = (obj) => {
    return async (dispatch) => {
        try{
            const response = await api.post(`/products/editPrice`, obj);
            dispatch({
                type: EDIT_PRICE,
                payload: response.data.price,
            });
        }catch(error){
            dispatch({
                type: PRODUCTS_FAIL,
                payload: error.response.data
            });  
        }
    };
};

export const getProducts = (obj) => {
    return async (dispatch) => {
        try{
            const response = await api.post(`/products/getProducts`, obj); 
            dispatch({
                type: GET_PRODUCTS,
                payload: response.data.products
            });
        }catch(error){
            dispatch({
                type: PRODUCTS_FAIL
            });
        }
    };
};

export const getPricesAttachedToProduct = (obj) => {
    return async (dispatch) => {
        try{
            const response = await api.post(`/products/getPricesAttachedToProduct`, obj);
            dispatch({
                type: GET_PRODUCTS_PRICES,
                payload: response.data.prices,
            });
        }catch(error){
            dispatch({
                type: PRODUCTS_FAIL
            }); 
        }
    };
}

export const attachProductToPlan = (obj) => {
    return async (dispatch) => {
        try {
            await api.post(`/plan/attachProductToPlan`, obj)
            dispatch({
                type: ATTACH_PRODUCT_SUCCESS
            })
        }catch (error) {
            dispatch({
                type: PRODUCTS_FAIL
            }); 
        }
    }
}