import { Button, Box, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Stack from "@mui/material/Stack";
import ImageIcon from "@mui/icons-material/Image";
import DataObjectIcon from "@mui/icons-material/DataObject";
import { baseImg, resolveSrc } from "../../../api/endpoints";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import SlowMotionVideoIcon from "@mui/icons-material/SlowMotionVideo";
import { useTranslation } from "react-i18next";
import { shallowEqual, useSelector } from "react-redux";
import RedesignBodyHeader from "./RedesignBodyHeader";
import Folders from "./Folders";

function SelectFilesModal({ open, setOpenSelectFiles, files, onSaveFiles, playlist, singleSelect = false }) {
  const { t } = useTranslation();

  // Local state for folder navigation
  const [modalCurrentFolder, setModalCurrentFolder] = useState("root");
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [filesToSkip, setFilesToSkip] = useState([]);

  const { allFolders } = useSelector(
    (state) => ({
      allFolders: state.FileFolder.userFolders.filter((f) => f.folderFor === "content"),
    }),
    shallowEqual
  );

  // Update handleFolderChange
  const handleFolderChange = (folderId) => {
    setModalCurrentFolder(folderId);

    if (folderId === "root") {
      setModalFolderPath([]);
      return;
    }

    const targetFolder = allFolders.find((f) => f._id === folderId);
    if (targetFolder) {
      const newPath = targetFolder.path || [];
      setModalFolderPath(newPath);
    }
  };

  // Update the current folders filter
  const currentFolders = allFolders.filter((f) => f.parent === modalCurrentFolder);

  // Track folder navigation path
  const [modalFolderPath, setModalFolderPath] = useState([]);

  // Get current folder data
  const currentFolderData = allFolders.find((f) => f._id === modalCurrentFolder);

  // Reset modal state when opened
  useEffect(() => {
    if (open) {
      setModalCurrentFolder("root");
      setModalFolderPath([]);
      setSelectedFiles([]);
    }
  }, [open]);

  // Handle files to skip (from playlist)
  useEffect(() => {
    let filesToExclude = [];
    if (playlist && playlist.content) {
      playlist.content.forEach((item) => {
        if (item.content) {
          filesToExclude.push(item.content._id);
        }
      });
      setFilesToSkip(filesToExclude);
    }
  }, [playlist]);

  const handleNavigateToFolder = (folderId) => {
    handleFolderChange(folderId);
  };

  const handleSelectFile = (id) => {
    if (singleSelect) {
      setSelectedFiles([id]);
    } else {
      setSelectedFiles((prevSelectedFiles) => (prevSelectedFiles.includes(id) ? prevSelectedFiles.filter((fileId) => fileId !== id) : [...prevSelectedFiles, id]));
    }
  };

  const handleUnselectFile = (id) => {
    setSelectedFiles((prevSelectedFiles) => prevSelectedFiles.filter((item) => item !== id));
  };

  const handleClose = () => {
    setOpenSelectFiles(false);
    setModalCurrentFolder("root");
    setSelectedFiles([]);
  };

  const handleSaveFiles = () => {
    onSaveFiles(selectedFiles);
    setOpenSelectFiles(false);
    setModalCurrentFolder("root");
    setSelectedFiles([]);
  };

  const currentFiles = files.filter((file) => !filesToSkip.includes(file.id) && file.folderId === modalCurrentFolder);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xl" fullWidth sx={{ zIndex: "10000000000" }}>
      <DialogTitle sx={{ textAlign: "center" }}>{t("views.selectFilesModal.selectFiles")}</DialogTitle>

      {/* <RedesignBodyHeader /> */}

      {/* Breadcrumb Navigation */}
      <Box sx={{ display: "flex", alignItems: "center", px: 3, py: 2 }}>
        <Button onClick={() => handleNavigateToFolder("root")} sx={{ minWidth: "auto" }}>
          {t("components.tableBodies.home")}
        </Button>

        {modalCurrentFolder !== "root" && (
          <>
            <Box
              sx={{
                backgroundColor: "neutral.500",
                borderRadius: "50%",
                height: 4,
                width: 4,
                mx: 1,
              }}
            />
            {modalFolderPath.map((pathItem, index) => (
              <React.Fragment key={pathItem.parent}>
                <Button onClick={() => handleNavigateToFolder(pathItem.parent)} sx={{ minWidth: "auto" }}>
                  {pathItem.name}
                </Button>
                <Box
                  sx={{
                    backgroundColor: "neutral.500",
                    borderRadius: "50%",
                    height: 4,
                    width: 4,
                    mx: 1,
                  }}
                />
              </React.Fragment>
            ))}
            <Typography variant="body2">{currentFolderData?.name || ""}</Typography>
          </>
        )}
      </Box>

      {/* List of folders */}
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          flexWrap: "wrap",
          margin: "20px 0 10px 25px",
        }}>
        {currentFolders.map((folder) => (
          <Folders key={folder._id} folder={folder} isInModal={true} onModalFolderClick={(folderId) => handleFolderChange(folderId)} />
        ))}
      </Box>

      <DialogContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}>
          <Box sx={{ margin: "20px 0" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox"></TableCell>
                  <TableCell align="center" sx={{ maxWidth: "5rem" }}>
                    {t("views.selectFilesModal.preview")}
                  </TableCell>
                  <TableCell align="center" sx={{ maxWidth: "5rem" }}>
                    {t("views.selectFilesModal.type")}
                  </TableCell>
                  <TableCell>{t("views.selectFilesModal.fileName")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentFiles.map((file) => (
                  <TableRow hover key={file.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedFiles.includes(file.id)}
                        onChange={(event) => {
                          if (event.target.checked) {
                            handleSelectFile(file.id);
                          } else {
                            handleUnselectFile(file.id);
                          }
                        }}
                      />
                    </TableCell>
                    <TableCell align="center" sx={{ maxWidth: "5rem" }}>
                      <Box sx={{ height: "60px", width: "108px" }}>
                        {file.type === "image" && (
                          <img
                            src={resolveSrc(file.url)}
                            style={{
                              maxWidth: "100%",
                              height: "auto",
                              maxHeight: "60px",
                              margin: "auto",
                              display: "block",
                            }}
                          />
                        )}
                        {(file.type === "widget" || file.type === "split_screen") && (
                          <>
                            {file.widget?.widgetCat === "twitch" ? (
                              <img
                                src="/images/twitch_app.png"
                                style={{
                                  maxWidth: "100%",
                                  height: "auto",
                                  maxHeight: "60px",
                                  margin: "auto",
                                  display: "block",
                                }}
                              />
                            ) : (
                              <img
                                src={file.widget?.widgetIcon}
                                style={{
                                  maxWidth: "100%",
                                  height: "auto",
                                  maxHeight: "60px",
                                  margin: "auto",
                                  display: "block",
                                }}
                              />
                            )}
                          </>
                        )}
                        {file.type === "video" && (
                          <video
                            src={resolveSrc(file.url)}
                            controls
                            style={{
                              width: "100%",
                              height: "auto",
                              maxHeight: "60px",
                            }}
                          />
                        )}
                        {file.embed === "embed" && (
                          <embed
                            src={file.url}
                            style={{
                              maxWidth: "100%",
                              height: "auto",
                              maxHeight: "60px",
                            }}
                          />
                        )}
                      </Box>
                    </TableCell>
                    <TableCell align="center" sx={{ maxWidth: "6rem" }}>
                      {file.type === "image" && <ImageIcon />}
                      {file.type === "widget" && <AppRegistrationIcon />}
                      {file.type === "video" && <SlowMotionVideoIcon />}
                      {file.embed === "embed" && <DataObjectIcon />}
                      {!(file.type === "image" || file.type === "widget" || file.type === "video" || file.embed === "embed") && <DataObjectIcon />}
                    </TableCell>
                    <TableCell>
                      <Stack alignItems="center" direction="row" spacing={1}>
                        <Typography color="text.secondary" variant="body2">
                          {file.name}
                        </Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          variant="outlined"
          sx={{
            height: "38px",
            width: "193px",
            textTransform: "capitalize",
            borderRadius: "5px",
          }}
          onClick={handleClose}>
          {t("views.selectFilesModal.close")}
        </Button>
        <Button
          variant="contained"
          sx={{
            height: "38px",
            width: "193px",
            textTransform: "capitalize",
            borderRadius: "5px",
          }}
          onClick={handleSaveFiles}>
          {t("views.selectFilesModal.add")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default SelectFilesModal;
