import { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrowLeftIcon from "@untitled-ui/icons-react/build/esm/ArrowLeft";
import ChevronDownIcon from "@untitled-ui/icons-react/build/esm/ChevronDown";
import Edit02Icon from "@untitled-ui/icons-react/build/esm/Edit02";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Unstable_Grid2";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { usePageView } from "src/hooks/use-page-view";

import Layout from "src/components/layout/Layout";
import { useSettings } from "src/hooks/use-settings";
import { api } from "src/api/endpoints";
import BillingAccountAdminTab from "./tabs/BillingAccount";
import { RouterLink } from "src/components/router-link";
import { paths } from "src/paths";
import InvoicesAdminTab from "./tabs/Invoices";
import { Backdrop, CircularProgress } from "@mui/material";

const UserSubscription = ({ isEditActive }) => {
  const { t } = useTranslation();

  const adminTabs = [
    { label: `Billing account and subscription`, value: "billing-account-subscriptions" },
    { label: `Invoices`, value: "invoices" },
    // { label: `Transactions`, value: "transactions" },
  ]; 

  const dispatch = useDispatch();

  const { id } = useParams();

  const settings = useSettings();

  const [currentTab, setCurrentTab] = useState("billing-account-subscriptions");
  const [userBillingAccount, setUserBillingAccount] = useState(null)
  const [userPaymentMethods, setUserPaymentMethods] = useState(null)
  const [currentUser, setCurrentUser] = useState(null)
  const [stripeCustomerData, setStripeCustomerData] = useState(null)
  const [customerInovices, setCustomerInvoices] = useState(null)

  useEffect(() => {
    const getDataOnStartup = async () => {
        try {
            const userGetRes = await api.get(`/user/${id}`)
            if(userGetRes.data.user?.stripeCustomerId){
                const getCustomerDetailsRes = await api.post(`/payment/getCustomerDetails`, {customerId: userGetRes.data.user?.stripeCustomerId})
                const getCustomerPaymentMethodsRes = await api.post('/payment/listUsersPaymentMethods', {customerId: userGetRes.data.user?.stripeCustomerId})
                const stripeCustomerDataRes = await api.post('/payment/getCustomerAndSubscriptions', {customerId: userGetRes.data.user?.stripeCustomerId})
                const retrieveCustomerInvoicesRes = await api.post('/payment/retrieveCustomerInvoices', {customerId: userGetRes.data.user?.stripeCustomerId})
                setCustomerInvoices(retrieveCustomerInvoicesRes.data.invoices.data)
                setStripeCustomerData(stripeCustomerDataRes.data.customer)
                setUserPaymentMethods(getCustomerPaymentMethodsRes.data.methods)
                setUserBillingAccount(getCustomerDetailsRes.data.customer)
            }
            
            setCurrentUser(userGetRes.data.user)
        } catch (error) {}
    } 
    getDataOnStartup()
  }, []);

  usePageView();

  const handleTabsChange = useCallback((event, value) => {
    setCurrentTab(value);
  }, []);

  return (
    <Layout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 2
        }}>
        <Container maxWidth={settings.stretch ? false : "xl"}>
        <Link
        color="text.primary"
        component={RouterLink}
        to={paths.users.index}
        sx={{
            alignItems: "center",
            display: "inline-flex",
        }}
        underline="hover">
        <SvgIcon sx={{ mr: 1 }}>
            <ArrowLeftIcon />
        </SvgIcon>
        <Typography variant="subtitle2">{t("views.userDetails.users")}</Typography>
        </Link>
          {currentUser ? (
            <>
                <Box>
                      <Tabs
                          indicatorColor="primary"
                          onChange={handleTabsChange}
                          scrollButtons="auto"
                          sx={{ mt: 1 }}
                          textColor="primary"
                          value={currentTab}
                          variant="scrollable">
                          {adminTabs.map((tab) => (
                              <Tab key={tab.value} label={tab.label} value={tab.value} />
                          ))}
                      </Tabs>
                </Box>
                <Divider />
                <Box sx={{mt: 2}}>
                    <>
                      {currentTab === "billing-account-subscriptions" ? (userPaymentMethods && userBillingAccount ? (<BillingAccountAdminTab currentUser={currentUser} customerDetails={userBillingAccount} customerPaymentMethods={userPaymentMethods} stripeCustomerData={stripeCustomerData} />) : (
                        <Typography variant="h5">User don't have billing account</Typography>
                      )) : null }
                      {currentTab === "invoices" ? (customerInovices ? (<InvoicesAdminTab invoicesList={customerInovices} user={currentUser}/>) : (
                        <Typography variant="h5">User don't have any invoices</Typography>
                      )) : null }
                    </>
                </Box>
            </>
          ) : (
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '400px'
            }}>
              <CircularProgress/>
            </Box>
          )}
        </Container>
      </Box>
    </Layout>
  );
};

export default UserSubscription;
