import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

const TwitchLivePreview = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const hostname = new URL(baseUrl).hostname; // Poprawiona linia

  console.log(hostname);
  const [searchParams] = useSearchParams();
  const channel = searchParams.get('channel');
  const showChatParam = searchParams.get('chat');
  const themeParam = searchParams.get('theme');
  
  const [showChat, setShowChat] = useState(showChatParam === 'true');
  const [embedUrl, setEmbedUrl] = useState('');
  const [isClip, setIsClip] = useState(false);
  
  const isDarkTheme = themeParam === 'dark';

  useEffect(() => {
    if (channel) {
      if (channel.startsWith('video=')) {
        setEmbedUrl(`https://player.twitch.tv/?video=${channel.substring(6)}&parent=${hostname}&autoplay=true`);
      } else if (channel.startsWith('clip=')) {
        setEmbedUrl(`https://clips.twitch.tv/embed?clip=${channel.substring(5)}&parent=${hostname}&autoplay=true`);
        setIsClip(true);
        setShowChat(false); // Disable chat for clips
      } else {
        setEmbedUrl(`https://player.twitch.tv/?channel=${channel}&parent=${hostname}&autoplay=true`);
      }
    }
  }, [channel, hostname]);

  return (
    <div style={{ 
      height: '100vh', 
      width: '100vw', 
      display: 'flex',
      backgroundColor: isDarkTheme ? '#121212' : '#ffffff',
      color: isDarkTheme ? '#ffffff' : '#000000', 
    }}>
      <Grid container style={{ height: '100%', flex: 1 }}>
        <Grid
          item
          xs={showChat && !isClip ? 8 : 12}
          style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}
        >
          <iframe
            src={embedUrl}
            height="100%"
            width="100%"
            allowFullScreen
            frameBorder="0"
            title="Twitch Content"
          ></iframe>
        </Grid>
        {showChat && !isClip && (
          <Grid
            item
            xs={4}
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', overflow: 'hidden' }}
          >
            <iframe
                src={`https://www.twitch.tv/embed/${channel}/chat?parent=${hostname}${isDarkTheme ? "&darkpopout" : ""}`}
                height="100%"
                width="100%"
                frameBorder="0"
                title="Twitch Chat"
            ></iframe>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default TwitchLivePreview;
