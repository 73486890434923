import { api } from "../../api/endpoints";
import {
  GET_DAY_SCREEN_DATA_USAGE_STATS,
  GET_DAY_SCREEN_DATA_USAGE_STATS_FAIL,
  GET_PERIOD_SCREEN_DATA_USAGE_STATS_FAIL,
  GET_PERIOD_SCREEN_DATA_USAGE_STATS_SUCCESS
} from "../constant";

export const getScreenDataUsageStaticsPerDay = (obj) => {
  return async (dispatch) => {
    const { data, status } = await api.post(`/screenStats/getScreenDataUsageStaticsPerDay`, obj);
    if (status === 200) {
      dispatch({
        type: GET_DAY_SCREEN_DATA_USAGE_STATS,
        payload: data.dayScreenStat,
      });
    }else{
      dispatch({
        type: GET_DAY_SCREEN_DATA_USAGE_STATS_FAIL
      })
    }
  };
};

export const throwGetPeriodScreenStatsError = (error) => {
  return async (dispatch) => {
    dispatch({
      type: GET_PERIOD_SCREEN_DATA_USAGE_STATS_FAIL,
      payload: error
    })
  }
}

export const getPeriodScreenStats = (obj) => {
  return async (dispatch) => {
    const { data, status } = await api.post(`/screenStats/getScreenDataUsageStaticsPeriod`, obj);
    if (status === 200) {
      dispatch({
        type: GET_PERIOD_SCREEN_DATA_USAGE_STATS_SUCCESS,
        payload: data.periodScreenStats,
      });
    }else{
      dispatch({
        type: GET_PERIOD_SCREEN_DATA_USAGE_STATS_FAIL,
        payload: "Błąd przy pobieraniu danych"
      })
    }
  };
}
