import React from "react";
import { Box, Menu, MenuItem, Card, Stack, Typography, Tooltip } from "@mui/material";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// icons
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SvgIcon from "@mui/material/SvgIcon";
import IconButton from "@mui/material/IconButton";
import DotsVerticalIcon from "@untitled-ui/icons-react/build/esm/DotsVertical";

function TableFolders({ folder, handleNavigate, onFolderDelete, onFolderEdit }) {
  const { user } = useSelector((state) => state.User);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    onFolderDelete(folder?._id);
    handleClose();
  };

  const handleEdit = () => {
    onFolderEdit(folder?._id, folder?.name);
    handleClose();
  };

  return (
    <Card
      onDoubleClick={() => handleNavigate(`/screenfolders/${folder._id}`, folder._id)}
      sx={{
        backgroundColor: "transparent",
        boxShadow: 0,
        width: "160px",
        transition: (theme) =>
          theme.transitions.create(["background-color, box-shadow"], {
            easing: theme.transitions.easing.easeInOut,
            duration: 200,
          }),
        "&:hover": {
          backgroundColor: "background.paper",
          boxShadow: 16,
        },
      }}
      variant="outlined">
      <Menu
        id="content-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}>
        {user.isMember ? (
          user.previlliages?.folder?.canEdit ? (
            <MenuItem onClick={() => handleEdit()}>
              <SvgIcon sx={{ marginRight: "8px" }} fontSize="small">
                <EditIcon />
              </SvgIcon>
              {t("components.tableFolders.edit")}
            </MenuItem>
          ) : (
            <></>
          )
        ) : (
          <MenuItem onClick={() => handleEdit()}>
            <SvgIcon sx={{ marginRight: "8px" }} fontSize="small">
              <EditIcon />
            </SvgIcon>
            {t("components.tableFolders.edit")}
          </MenuItem>
        )}
        {user.isMember ? (
          user.previlliages.folder?.canDelete ? (
            <MenuItem onClick={() => handleDelete()} sx={{ color: "error.main" }}>
              <SvgIcon sx={{ marginRight: "8px" }} fontSize="small">
                <DeleteIcon color="error" />
              </SvgIcon>
              {t("components.tableFolders.delete")}
            </MenuItem>
          ) : (
            <></>
          )
        ) : (
          <MenuItem onClick={() => handleDelete()} sx={{ color: "error.main" }}>
            <SvgIcon sx={{ marginRight: "8px" }} fontSize="small">
              <DeleteIcon color="error" />
            </SvgIcon>
            {t("components.tableFolders.delete")}
          </MenuItem>
        )}
      </Menu>

      {/*  
        <Menu
          anchorOrigin={{
            horizontal: 'right',
            vertical: 'bottom',
          }}
          id="content-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          PaperProps={{
            elevation: 4,
            style: {
              left: anchorEl?.offsetLeft,
              top: anchorEl?.offsetTop + anchorEl?.offsetHeight,
              position: "absolute",
              display: open ? "block" : "none",
            },
          }}
        >
          {
            user.isMember ?
              user.previlliages.folder?.canDelete ?
                <MenuItem onClick={() => handleDelete()}>
                  <Tooltip title={'Delete'} placement={'top-start'}>
                    <SvgIcon fontSize="small" sx={{
                      margin: "0 10px",
                      cursor: "pointer",
                    }}>
                      <DeleteIcon color="primary" />
                    </SvgIcon>
                  </Tooltip>
                </MenuItem>
                :
                <></>
              :
              <MenuItem onClick={() => handleDelete()}>
                <Tooltip title={'Delete'} placement={'top-start'}>
                  <SvgIcon fontSize="small" sx={{
                    margin: "0 10px",
                    cursor: "pointer",
                  }}>
                    <DeleteIcon color="primary" />
                  </SvgIcon>
                </Tooltip>
              </MenuItem>
          }
          {
            user.isMember ?
              user.previlliages?.folder?.canEdit ?
                <MenuItem onClick={() => handleEdit()}>
                  <Tooltip title={'Edit'} placement={'top'}>
                    <SvgIcon fontSize="small" sx={{
                      margin: "0 10px",
                      cursor: "pointer",
                    }}>
                      <EditIcon color="primary" />
                    </SvgIcon>
                  </Tooltip>
                </MenuItem>
                :
                <></>
              :
              <MenuItem onClick={() => handleEdit()}>
                <Tooltip title={'Edit'} placement={'top'}>
                  <SvgIcon fontSize="small" sx={{
                    margin: "0 10px",
                    cursor: "pointer",
                  }}>
                    <EditIcon color="primary" />
                  </SvgIcon>
                </Tooltip>
              </MenuItem>
          }
        </Menu>
        */}

      <Box sx={{ p: 2, position: "relative" }}>
        <Stack alignItems="center" direction="row" justifyContent="flex-end" sx={{ pt: 2, px: 2, position: "absolute", right: 0, paddingTop: 0 }}>
          <IconButton onClick={handleClick}>
            <SvgIcon fontSize="small">
              <DotsVerticalIcon />
            </SvgIcon>
          </IconButton>
        </Stack>
        <Box sx={{ display: "flex", mb: 1 }}>
          <Box sx={{ display: "inline-flex", cursor: "pointer" }}>
            <img src="/assets/icons/icon-folder.svg" />
          </Box>
        </Box>
        <Tooltip title={folder.name.length > 15 ? folder.name : ''} placement="bottom">
          <Typography sx={{ cursor: "pointer" }} variant="subtitle2">
            {folder.name.length > 15 ? `${folder.name.slice(0,15)}...` : folder.name}
          </Typography>
        </Tooltip>
      </Box>
    </Card>
  );
}

export default TableFolders;
