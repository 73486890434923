import toast from "react-hot-toast";
import {
    LIST_FEATURES_LOCKS_SUCCESS,
    SET_FEATURES_LOCKS_SUCCESS,
    SET_FEATURES_LOCKS_FAIL,
    SET_FEATURES_LOCKS_LOADING
} from "../constant";

const initial = {
    featureLocksList: [],
    featuresLockLoading: false,
    paymentsFeature: null
};

export const FeaturesLockReducer = (state = initial, action) => {
    switch (action.type) {
        case LIST_FEATURES_LOCKS_SUCCESS:
            return {
                ...state,
                featureLocksList: [...action.payload],
                paymentsFeature: action.payload.find(fl => fl.featureSlug == 'payments')
            }  
         case SET_FEATURES_LOCKS_SUCCESS:
            return {
                ...state,
                featureLocksList: state.featureLocksList.map(fl => {
                    if (fl._id == action.payload._id){
                        return action.payload
                    }
                    return fl
                }),
                featuresLockLoading: false,
                [`${action.payload.featureSlug}Feature`]: action.payload
            }
        case
         SET_FEATURES_LOCKS_FAIL:
            toast.error("Error while blocking feature")
            return {
                ...state,
                featuresLockLoading: false
            }
        case SET_FEATURES_LOCKS_LOADING:
            return {
                ...state,
                featuresLockLoading: action.payload   
            }
        default:
            return state;
    }
};