import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Card, CardActions, CardContent, Typography } from '@mui/material';
import { parsePrice } from 'src/utils/parse-price';

export default function ChoosePriceDialog({openChoosePriceDialog, handleChoosePriceDialogClose, pricesData, handleSubscribe, choosePriceMode}) {

  const getBillingType = (type) => {
    switch (type){
        case 'month':
            return 'Monthly'
        case 'year': 
            return 'Annually'
        case 'week':
            return 'Weekly'
        case 'day':
            return 'Daily'
    }
  }

  return (
      <Dialog
        open={openChoosePriceDialog}
        onClose={handleChoosePriceDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" textAlign="center">
          Choose price
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" textAlign="center">
            Choose the billing method that suits you best
          </DialogContentText>
          <Box sx={{display: 'flex', gap: '20px', mt: 3, justifyContent: 'center'}}>
            {pricesData ? pricesData.map(price => { return (
                    <Card sx={{minWidth: '250px'}} key={price.id} variant="outlined">
                        <CardContent sx={{textAlign: 'center'}}>
                            <Typography variant='h5'>{parsePrice(price.unit_amount)} {price.currency.toUpperCase()}</Typography>
                            <Typography sx={{ color: 'text.secondary', fontSize: 12, mt: 4}}>Billing type</Typography>
                            <Typography>{getBillingType(price.recurring.interval)}</Typography>
                        </CardContent>
                        <CardActions>
                            <Button variant='contained' fullWidth onClick={(event) => {
                              event.target.disabled = true;
                              handleSubscribe(price.id, choosePriceMode);
                            }}>Choose</Button>
                        </CardActions>
                    </Card>
                )
                }) : null}
          </Box>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleChoosePriceDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
  );
}